import { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Anchor } from '@zendeskgarden/react-buttons';

import { useStore } from '../../../../utils';
import { CheckAccessStore, OrganizationStore, RolesStore, ScoutsStore } from '../../stores';
import { ReactComponent as SearchSvg } from '../../../../assets/images/agroid/colored/error.svg';
import { NoData, ListModal, IModalHeaderAction } from '../';

import { UserToRoleRow } from './UserToRoleRow';
import { UserToRoleContainer } from './styled';

export interface IUserToRoleModalProps {
  onClose: () => void;
  orgId: string;
  roleId: string;
  isScouting?: boolean;
}

export const UserToRoleModal: FC<IUserToRoleModalProps> = observer(
  ({ onClose, orgId, roleId, isScouting }) => {
    const { roleSettings, assignUsers, assignScouts } = useStore(RolesStore);
    const { getOrganizationUsers, getOrganizationFilteredUsers } = useStore(OrganizationStore);
    const { isOrganizationOwner } = useStore(CheckAccessStore);
    const { getUsersScouts } = useStore(ScoutsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [message, setMessage] = useState('');
    const [userList, setUserList] = useState([]);
    const [checkedList, setChecked] = useState(
      userList
        .filter(user => (isScouting ? user.id === roleId : user.roleInfo.id === roleId))
        .map(item => item.id)
    );

    const modalActions: IModalHeaderAction[] = [
      {
        caption: roleSettings.name,
        component: Anchor,
        componentProps: {
          isBasic: true,
          style: { textDecoration: 'none', cursor: 'default' },
        },
      },
    ];

    useEffect(() => {
      setIsLoading(true);
      (async () => {
        const list = isScouting
          ? await getUsersScouts()
          : await getOrganizationFilteredUsers({
              organizationID: orgId,
              fullname: userName,
              notRoleId: roleId,
            });
        setUserList(list as any);
      })();
      if (userName) {
        setMessage(`Пользователь с именем «${userName}» не найден`);
      } else {
        setMessage('Не заданы условия поиска');
      }
      setIsLoading(false);
    }, [orgId, userName]);

    const handleFilterChange = (value: string) => {
      setUserName(value);
    };

    const toggleUserCheck = userId => {
      let newCheckedList = [...checkedList];
      const checkedIndex = checkedList.findIndex(i => i === userId);
      if (checkedIndex > -1) {
        newCheckedList = newCheckedList.filter(i => i !== userId);
      } else {
        newCheckedList.push(userId);
      }
      setChecked(newCheckedList);
    };

    const handleClose = () => {
      onClose();
    };

    const handleClear = () => {
      setUserName('');
    };

    const handleSave = async () => {
      try {
        if (isScouting) {
          await assignScouts(roleId, checkedList);
        } else {
          await assignUsers(roleId, checkedList);
        }
        await getOrganizationUsers();
        handleClose();
      } catch (e) {
        throw e;
      }
    };

    const noOwner = useCallback(
      noOwnerList => {
        if (isScouting) {
          return noOwnerList;
        }

        if (isOrganizationOwner) return true;
        return noOwnerList.filter(user => !user.roleInfo.isOwner);
      },
      [userList, isOrganizationOwner, isScouting]
    );

    return (
      <ListModal
        header="Добавить"
        headerActions={modalActions}
        showHeaderSearch
        headerSearchValue={userName}
        onHeaderSearchChange={handleFilterChange}
        onHeaderSearchClear={handleClear}
        onClose={handleClose}
        buttons={{
          columns: 2,
          cancel: {
            handler: handleClose,
            isBasic: true,
            isStretched: true,
          },
          submit: {
            handler: handleSave,
            isPrimary: true,
            isStretched: true,
          },
        }}
        height="70rem"
        dataTestId="user-role"
      >
        {isLoading ? (
          <>
            <Skeleton height="12px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
          </>
        ) : noOwner(userList).length ? (
          <UserToRoleContainer>
            {noOwner(userList).map(user => (
              <UserToRoleRow
                key={user.id}
                {...user}
                onClick={() => toggleUserCheck(user.id)}
                checkedList={checkedList}
                isScouting={isScouting}
              />
            ))}
          </UserToRoleContainer>
        ) : (
          <NoData height="20rem" message={message} icon={SearchSvg} />
        )}
      </ListModal>
    );
  }
);
