import { TypeApiRoute } from '../models/type.api.request';
import { TypeRoleSettings } from '../models/role.model';

type TypeRequest = {
  id?: string;
};

type TypeResponse = TypeRoleSettings;

export const getRole: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/access/role/${request.id}`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
