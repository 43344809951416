import React, { useEffect } from 'react';
import { Button, DatePicker, Input, Select, Checkbox } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';

import { Heading } from '../../../../style';
import { Step } from '../Step';
import { SignUpStepEnum, SingUpStore } from '../../stores/SingUpStore';
import { AuthStore } from '../../../../stores/auth.store';
import { useStore } from '../../../../../../utils';
import { AuthorizationRoutes } from '../../../../routes';
import { BackButton } from '../../../../components/BackButton';
import { InviteStore } from '../../../invite/stores/invite.store';
import { SignInViaEmailStore } from '../../../sign-in/stores/sign-in.via-email.store';

import {
  Wrapper,
  TopLine,
  HeadingWrapper,
  FieldWrapper,
  TwoFieldsLine,
  TwoFieldWrapper,
  CheckBoxWrapper,
  ButtonWrapper,
} from './style';

export const SecondStepSignUp = withRouter(
  observer(() => {
    const {
      gender,
      setGender,
      firstName,
      setFirstName,
      secondName,
      setSecondName,
      lastName,
      setLastName,
      birthDate,
      email,
      setEmail,
      setBirthDate,
      isSecondStepEnabled,
      setRightAccepted,
      isRightsAccepted,
      submitSecondStep,
      setIsInvitedRegistration,
      isInvitedRegistration,
      setSignUpStep,
      reset,
      resetSession,
      resetPhone,
    } = useStore(SingUpStore);
    const { erqRedirectData, redirectWithAuth } = useStore(AuthStore);
    const history = useHistory();
    const inviteStore = useStore(InviteStore);
    const { transferEmail } = useStore(SignInViaEmailStore);

    const onSubmit = (e: any) => {
      e.preventDefault();
      if (isSecondStepEnabled) {
        submitSecondStep(transferEmail)
          .then(() => {
            erqRedirectData().then(() => {
              redirectWithAuth();
            });
          })
          .catch(() => {
            console.error('error');
          });
      }
    };

    const CheckBoxLabelLink = () => (
      <Link
        to={AuthorizationRoutes.Agreement}
        onClick={event => event.stopPropagation()}
        style={{ marginLeft: '3px' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        пользовательским соглашением
      </Link>
    );

    const CheckboxLabel = () => (
      <p>
        Согласен с <CheckBoxLabelLink />
      </p>
    );

    const backToFirstStep = () => {
      const queryParams = new URLSearchParams(location.search);
      resetSession();

      if (queryParams.has('step')) {
        queryParams.delete('step');
        history.replace({
          search: queryParams.toString(),
        });
      }

      setSignUpStep(SignUpStepEnum.FIRST);
      reset();
      resetPhone();
    };

    const genderOptions = [
      { value: 'MALE', label: 'Мужской' },
      { value: 'FEMALE', label: 'Женский' },
    ];

    useEffect(() => {
      setIsInvitedRegistration(Boolean(localStorage.getItem('externalKey')));

      if (Boolean(localStorage.getItem('externalKey')) && inviteStore.inviteEmail) {
        setEmail(inviteStore.inviteEmail);
      }

      if (transferEmail) {
        setEmail(transferEmail);
      }
    }, []);

    return (
      <Wrapper>
        <BackButton onClick={() => backToFirstStep()} />
        <TopLine />

        <form onSubmit={onSubmit}>
          <HeadingWrapper>
            <Heading>Регистрация</Heading>
            <Step current={2} total={2} />
          </HeadingWrapper>

          <FieldWrapper>
            <Input
              label="Фамилия"
              placeholder="Иванов"
              error={lastName.error}
              value={lastName.value}
              focus={false}
              onChange={setLastName}
              dataTestId="signUp-lastName-input"
              className="signUp-lastName-input"
            />
          </FieldWrapper>
          <TwoFieldsLine>
            <TwoFieldWrapper>
              <Input
                label="Имя"
                placeholder="Иван"
                error={firstName.error}
                value={firstName.value}
                focus={false}
                onChange={setFirstName}
                dataTestId="signUp-name-input"
                className="signUp-name-input"
              />
            </TwoFieldWrapper>
            <TwoFieldWrapper>
              <Input
                label="Отчество"
                placeholder="Иванович"
                value={secondName.value}
                error={secondName.error}
                onChange={setSecondName}
                focus={false}
                dataTestId="signUp-secondName-input"
                className="signUp-secondName-input"
              />
            </TwoFieldWrapper>
          </TwoFieldsLine>
          <TwoFieldsLine>
            <TwoFieldWrapper data-test-id="signUp-datepicker">
              <DatePicker
                defaultValue="01.01.1995"
                onChange={setBirthDate}
                error={birthDate.error}
                placeholder="10.10.1990"
                label="Дата рождения"
                /* @ts-ignore */
                placement={'top'}
              />
            </TwoFieldWrapper>
            <TwoFieldWrapper data-test-id="signUp-gender-select">
              <Select
                options={genderOptions}
                label="Пол"
                placeholder="Выберите пол"
                error={gender.error}
                onChange={setGender}
              />
            </TwoFieldWrapper>
          </TwoFieldsLine>
          {!isInvitedRegistration && !transferEmail && (
            <FieldWrapper>
              <Input
                label="E-mail"
                placeholder="info@farmers.ru"
                error={email.error}
                focus={false}
                value={email.value}
                onChange={setEmail}
                dataTestId="email-input"
                className="email-input"
              />
            </FieldWrapper>
          )}
          <CheckBoxWrapper className="signUp_checkbox">
            <Checkbox
              onChange={setRightAccepted}
              value={isRightsAccepted}
              label={<CheckboxLabel />}
              dataTestId="signUp-checkbox"
            />
          </CheckBoxWrapper>
          <ButtonWrapper>
            <Button
              type="submit"
              color="primary"
              disabled={!isSecondStepEnabled}
              dataTestId="signUp-next-button"
              className="signUp-next-button"
            >
              Продолжить
            </Button>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  })
);
