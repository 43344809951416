import { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import { useStore } from '../../../../utils';
import { RolesStore } from '../../stores';

import { bridge as schema } from './schema';

export interface UsersFilterProps extends HTMLAttributes<HTMLDivElement> {
  onChange?: any;
  statusOptions: Array<{ label: string; value: any }>;
}

export const UsersFilter: FC<UsersFilterProps> = observer(({ onChange, statusOptions = [] }) => {
  const { roles } = useStore(RolesStore);

  const rolesOptions = [{ value: '', label: 'Все роли' }].concat(
    roles.map(role => ({
      value: role.id,
      label: role.name,
    }))
  );

  return (
    <AutoForm schema={schema} onChangeModel={onChange}>
      <Grid>
        <Row>
          <Col sm={isMobile ? 12 : 6} xs={12} textAlign="start">
            <AutoField name="fullname" />
            <ErrorField name="fullname" />
          </Col>
          {!isMobile && (
            <>
              <Col sm={3}>
                <AutoField name="status" options={statusOptions} />
                <ErrorField name="status" />
              </Col>
              <Col sm={3}>
                <AutoField name="roleId" options={rolesOptions} />
                <ErrorField name="roleId" />
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </AutoForm>
  );
});
