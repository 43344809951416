import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

import { LongTextField, InputField, SelectField } from '../uniforms';

const schema = new SimpleSchema({
  email: {
    type: String,
    label: 'E-mail',
    regEx: SimpleSchema.RegEx.Email,
    required: true,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите email',
    },
  },
  roleId: {
    type: String,
    label: 'Роль',
    required: true,
    uniforms: {
      component: SelectField,
      options: [],
    },
  },
  comment: {
    type: String,
    optional: true,
    label: 'Сообщение',
    max: 200,
    uniforms: {
      component: LongTextField,
    },
  },
});

export const bridge = new SimpleSchema2Bridge(schema);
