import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Header, Body } from '@zendeskgarden/react-modals';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { ModelTransformMode } from 'uniforms';

import { SubmitField } from '../uniforms';
import { OrganizationStore, RolesStore } from '../../stores';
import { useStore } from '../../../../utils';

import { bridge as schema } from './schema';
import { AlreadyMemberError, InputErrorColorizer } from './style';

export interface IUserModalProps {
  onClose: (res?: any) => void;
  orgId: string;
}

type ModelProps = {
  fio: string;
  email: string;
  role: string;
  comment: string;
};

export const NewUserModal: FC<IUserModalProps> = observer(({ orgId, onClose }) => {
  const { inviteByEmail } = useStore(OrganizationStore);
  const { roles } = useStore(RolesStore);
  const [options, setOptions] = useState([]);

  const [isAlreadyMemberError, setIsAlreadyMemberError] = useState(false);
  const [lastEmailValue, setLastEmailValue] = useState('');

  useEffect(() => {
    setOptions(
      roles
        .filter(r => r.name !== 'Владелец')
        .map(r => ({
          value: r.id,
          label: r.name,
        }))
    );
  }, [roles]);

  const handleSubmit = async (doc: any) => {
    doc.organizationId = orgId;
    try {
      await inviteByEmail(doc);
      onClose(true);
    } catch (e) {
      if ((e as any).response?.data?.error?.title === 'ALREADY_MEMBER') {
        setIsAlreadyMemberError(true);
      }
      console.error('error inviteByEmail', e);
    }
  };

  const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
    if (mode === 'form' && model.email !== lastEmailValue) {
      setIsAlreadyMemberError(false);
      setLastEmailValue(model.email);
    }
    return model;
  };

  return (
    <Modal onClose={onClose}>
      <Header>Добавление сотрудника</Header>
      <Body>
        <AutoForm schema={schema} onSubmit={handleSubmit} modelTransform={handleModelTransform}>
          <Grid>
            <Row>
              <Col>
                {isAlreadyMemberError && <InputErrorColorizer />}
                <AutoField name="email" />
                <ErrorField name="email" />
                {isAlreadyMemberError && (
                  <AlreadyMemberError>Cотрудник с указанным email уже добавлен</AlreadyMemberError>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <AutoField name="roleId" options={options} />
                <ErrorField name="roleId" />
              </Col>
            </Row>
            <Row>
              <Col>
                <AutoField name="comment" />
                <ErrorField name="comment" />
              </Col>
            </Row>
          </Grid>
          <Grid columns={2}>
            <Row justifyContent="center">
              <Col textAlign="center" xs={1}>
                <Button
                  isStretched
                  isBasic
                  onClick={onClose}
                  size="large"
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                >
                  Отмена
                </Button>
              </Col>

              <Col textAlign="center" xs={1}>
                <SubmitField
                  isStretched
                  isPrimary
                  size="large"
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                >
                  Пригласить
                </SubmitField>
              </Col>
            </Row>
          </Grid>
        </AutoForm>
      </Body>
    </Modal>
  );
});
