import { TypeApplication } from '../models/applications.model';
import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

export type ApplicationsTypeRequest = {
  organizationId: string;
  // 'x-user-role'?: string;
  roleId?: string;
  notRoleId?: string;
  status?: string;
  fullname?: string;
  type?: string;
};

type TypeResponse = {
  content: Array<TypeApplication>;
} & TypeResponsePageable;

export const getOrganizationApplications: TypeApiRoute & {
  request: ApplicationsTypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/applications/list`,
  method: 'GET',
  headers: {},
  request: {} as ApplicationsTypeRequest,
  response: {} as TypeResponse,
};
