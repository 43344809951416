import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

const schema = new SimpleSchema({
  name: {
    type: String,
    label: 'Название роли',
    max: 20,
    regEx: /^[a-zа-я0-9\s]+$/i,
    uniforms: {
      placeholder: 'Укажите название',
    },
  },
});

export const baseSchema = schema;
export const bridge = new SimpleSchema2Bridge(schema);
