import { FC, useState } from 'react';
import { Modal, Header } from '@zendeskgarden/react-modals';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';
import { ModelTransformMode } from 'uniforms';

import { InputField, SubmitField } from '../uniforms';
import { ErrorsFieldWrapper } from '../NewOrgModal/styled';
import { useStore } from '../../../../utils';
import { RolesStore } from '../../stores';

import { BodyStyled } from './styled';
import { bridge as schema } from './schema';

export interface IRoleModalProps {
  orgId: string;
  onClose: () => void;
}

type ModelProps = {
  name: string;
};

const getErrorMessage = ({ title }) => {
  if (title === 'DUPLICATED_VALUE') {
    return 'Роль с таким названием уже существует';
  } else {
    return 'Ошибка создания роли';
  }
};

export const NewRoleModal: FC<IRoleModalProps> = observer(({ orgId, onClose }) => {
  const { createRole, setOrganization, fetchRoles } = useStore(RolesStore);
  const [commonError, setCommonError] = useState<boolean | string>(false);

  const handleSubmit = async (doc: any) => {
    doc.organizationId = orgId;
    doc.order = 0;
    try {
      setOrganization(orgId);
      await createRole(doc);
      await fetchRoles();
      onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e.response.data.error);
      setCommonError(errorMessage);
    }
  };

  const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
    return model;
  };

  const handleValidate = () => {
    setCommonError('');
  };

  return (
    <Modal onClose={onClose}>
      <Header>Создание роли</Header>
      <BodyStyled>
        <AutoForm
          schema={schema}
          onSubmit={handleSubmit}
          modelTransform={handleModelTransform}
          validate="onChange"
          onValidate={handleValidate}
        >
          <Grid>
            <Row>
              <Col>
                <AutoField name="name" component={InputField} />
                <ErrorField name="name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <ErrorsFieldWrapper>
                  {Boolean(commonError) && (
                    <ul>
                      <li>{commonError}</li>
                    </ul>
                  )}
                </ErrorsFieldWrapper>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col textAlign="center" xs={6}>
                <Button isStretched isBasic onClick={onClose} size="large">
                  Отменить
                </Button>
              </Col>

              <Col textAlign="center" xs={6}>
                <SubmitField isStretched isPrimary size="large">
                  Создать
                </SubmitField>
              </Col>
            </Row>
          </Grid>
        </AutoForm>
      </BodyStyled>
    </Modal>
  );
});
