import styled from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';
import { Well } from '@zendeskgarden/react-notifications';

import HeroBackground from '../../../../assets/images/agroid/illustration.png';

export const WellStyled = styled(Well)`
  ${p => mediaQuery('down', 'sm', p.theme)} {
    margin-bottom: 8px;
  }

  ${p => mediaQuery('up', 'md', p.theme)} {
    margin-bottom: 16px;
  }

  ${p => mediaQuery('up', 'lg', p.theme)} {
    margin-bottom: 20px;
  }
`;

export const HeroUserAvatarWrapper = styled.div`
  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    margin-left: 8px;
  }

  ${p => mediaQuery('up', 'md', p.theme)} {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    margin-left: 12px;
  }
`;

export const HeroUserWrapper = styled.div`
  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: calc(100% - 80px);
    padding-left: 8px;
    margin-bottom: 16px;
  }

  ${p => mediaQuery('up', 'md', p.theme)} {
    width: calc(100% - 104px);
    padding-left: 12px;
  }
`;

export const HeroUserAvatar = styled.img`
  width: 80px;
  height: auto;
  border-radius: ${({ theme: { borderRadii: r } }) => r.card};

  ${p => mediaQuery('down', 'sm', p.theme)} {
    width: 48px;
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};

    span {
      top: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    ${p => mediaQuery('down', 'sm', p.theme)} {
      color: ${({ theme: { colors: c } }) => c.brandColor};
    }
  }

  svg {
    path {
      fill: currentColor;
    }
  }

  ${p => mediaQuery('down', 'sm', p.theme)} {
    position: absolute;
    top: 0;
    right: 0;

    span {
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
      path {
        fill: currentColor;
      }
    }
  }
`;

export const DetailsWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 24px;
  .name {
    font-size: 14px;
    font-weight: 600;
    word-break: break-all;
    margin-bottom: 4px;
    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 0;
    }
  }
  .dateOfBirth {
    color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
    margin-bottom: 24px;

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 8px;
    }
  }
  .contacts {
    margin-top: auto;
    line-height: 20px;
  }
  .phone {
    margin-bottom: 8px;

    ${p => mediaQuery('down', 'sm', p.theme)} {
      margin-bottom: 4px;
    }
    
    &.hasError {
      font-size: 14px;
      line-height: 30px;
      color: ${({ theme: { colors: c } }) => c.accentPink};

      svg {
        margin-left: 4px;
        vertical-align: middle;

        path {
          fill: ${({ theme: { colors: c } }) => c.accentPink};
        }
      }
    }
  }
  .email {
    white-space: nowrap;
    &.hasError {
      font-size: 14px;
      line-height: 30px;
      color: ${({ theme: { colors: c } }) => c.accentPink};
      svg {
        margin-left: 4px;
        vertical-align: middle;
        path {
          fill: ${({ theme: { colors: c } }) => c.accentPink};
        }
      }
    }
  }
`;

export const WelcomeBlock = styled.div`
  background-image: url(${HeroBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  border-radius: ${({ theme: { borderRadii: r } }) => `${r.card} ${r.card} 0 0`};

  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
  /* letter-spacing: -1px; */
  text-align: left;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  padding-left: 72px;
  padding-right: 72px;
  align-items: center;
  color: ${({ theme: { colors: c } }) => c.generalWhite};
  min-height: 168px;
  margin: ${({ theme: { space: s } }) => `-${s.md} -${s.md} 20px -${s.md}`};

  word-break: break-all;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    min-height: 96px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
  }
`;

export const GreetingText = styled.div`
  max-height: 56px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 768px) {
    max-height: 104px;
  }
`;
