import { EModalType, ModalContainer, useModal } from "@farmlink/farmik-ui";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ApplicationStatus } from "../../../../api/models/applications.model";
import { useStore } from "../../../../utils/IoC";
import { InviteStore } from "../../../auth/pages/invite/stores/invite.store";
import { AuthorizationRoutes } from "../../../auth/routes";
import { ProfileStore } from "../../../auth/stores/profile.store";
import { EModalSize } from "../../modules/common/containers/configs/modals/Modal/styles";
import { AgroidRoutes } from "../../routes";
import { MyApplicationStore } from "../../stores";

import { ReactComponent as BackgroundSvg } from './assets/background.svg';
import { InviteNotAuthStatusSent } from "./modals/InviteNotAuthStatusSent/InviteNotAuthStatusSent";
import { BackgroundBlock } from "./style";

export enum UserInviteModalType {
   INVITE_NOT_AUTH_STATUS_SENT  = 'inviteNotAuthStatusSent',
   INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC  = 'inviteNotAuthStatusAccDecCanc',
   INVITE_AUTH_STATUS_SENT  = 'inviteAuthStatusSent',
   INVITE_AUTH_STATUS_ACC_DEC_CANC  = 'iviteNotAuthStatusAccDecCanc',
   LOGGED_BY_ANOTHER_USER = 'loggedByAnotherUser',
};

export const UserInvitePage: FC<{}> = observer(() => {
    const location = useLocation();
    const { fetchProfile, user } = useStore(ProfileStore);
    const { acceptUserInvite } = useStore(MyApplicationStore);
    const history = useHistory();

    const { fetchOrganizationInfo,  userId, status, organizationName, logoUrl, invitationId, isLoading} = useStore(InviteStore);

    const { registerModalList, openModalByModalId } = useModal();

    const queryParamStr = '?externalInvite=';

    const isLogged = localStorage.getItem('accessToken');

    useEffect(() => {
        if (location.search.includes(queryParamStr)) {
            const token = location.search.substr(queryParamStr.length);
            fetchOrganizationInfo(token);
            if (isLogged) {
                fetchProfile();
            }
        }
    }, []);

    useEffect(() => {
        if (!isLoading && status) {
           if (isLogged && user && user.id !== userId) {
                registerModalList(
                    [
                        {
                            name: UserInviteModalType.LOGGED_BY_ANOTHER_USER,
                            title: 'Авторизуйтесь под e-mail, на которое было направлено приглашение',
                            type: EModalType.Warning,
                            id: UserInviteModalType.LOGGED_BY_ANOTHER_USER,
                            styledProps: {
                                $size: EModalSize.Medium,
                            },
                            successButton: {
                                title: 'Сменить аккаунт',
                                color: 'primary',
                                handler: () => history.push(AuthorizationRoutes.logout),
                            },
                            denyButton: {
                                title: 'Остаться',
                                color: 'default',
                                handler: () => history.push(AgroidRoutes.Index),
                              },
                        },
                    ], 
                    UserInviteModalType.LOGGED_BY_ANOTHER_USER,
                );
                openModalByModalId(UserInviteModalType.LOGGED_BY_ANOTHER_USER);      
           } else if (!isLogged && status === ApplicationStatus.SENT) {
               registerModalList([
                {
                    name: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
                    title: '',
                    type: EModalType.Custom,
                    id: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
                    children: InviteNotAuthStatusSent,
                    dataTestId: UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT,
                    styledProps: {
                        $modalPaddings: '40px 60px',
                        $size: EModalSize.Medium,
                    },
                }
               ], UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT);
               openModalByModalId(UserInviteModalType.INVITE_NOT_AUTH_STATUS_SENT, {
                orgName: organizationName,
                orgLogo: logoUrl,
               });
           } else if (!isLogged && status !== ApplicationStatus.SENT) {
             registerModalList(
                    [
                        {
                            name: UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC,
                            title: 'Кажется, приглашение больше не активно. Возможно, отправитель его отменил',
                            type: EModalType.Warning,
                            id: UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC,
                            styledProps: {
                                $size: EModalSize.Medium,
                            },
                            successButton: {
                                title: 'На главную',
                                color: 'primary',
                                handler: () => history.push(AgroidRoutes.Index),
                            },
                        },
                    ], 
                    UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC,
                );
               openModalByModalId(UserInviteModalType.INVITE_NOT_AUTH_STATUS_ACC_DEC_CANC);
           } else if (isLogged && status === ApplicationStatus.SENT) {
             registerModalList(
                [
                    {
                        name: UserInviteModalType.INVITE_AUTH_STATUS_SENT,
                        title: `Вас приглашают присоединиться к организации ${organizationName}`,
                        type: EModalType.Warning,
                        id: UserInviteModalType.INVITE_AUTH_STATUS_SENT,
                        styledProps: {
                            $size: EModalSize.Medium,
                        },
                        successButton: {
                            title: 'Принять',
                            color: 'primary',
                            handler: async() => {
                                await acceptUserInvite(invitationId);
                                history.push(AgroidRoutes.Invites);
                            }
                        },
                        denyButton: {
                            title: 'Отклонить',
                            color: 'default',
                            handler: () => history.push(AgroidRoutes.Index),
                          },
                    },
                ], 
                UserInviteModalType.INVITE_AUTH_STATUS_SENT,
            );   
            openModalByModalId(UserInviteModalType.INVITE_AUTH_STATUS_SENT);
           } else if (isLogged && status !== ApplicationStatus.SENT) {
             registerModalList(
                [
                    {
                        name: UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC,
                        title: 'Кажется, приглашение больше не активно. Возможно, отправитель его отменил',
                        type: EModalType.Warning,
                        id: UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC,
                        styledProps: {
                            $size: EModalSize.Medium,
                        },
                        successButton: {
                            title: 'На главную',
                            color: 'primary',
                            handler: () => history.push(AgroidRoutes.Index),
                        },
                    },
                ], 
                UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC,
            );   
            openModalByModalId(UserInviteModalType.INVITE_AUTH_STATUS_ACC_DEC_CANC);
           }
        }
    }, [userId, status, isLoading, user]);

    return (
        <BackgroundBlock>
            <ModalContainer />
        </BackgroundBlock>
    );
  });