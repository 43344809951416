import { FC, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { isEmpty, throttle } from 'lodash';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { useStore, getLink } from '../../../../../../utils';
import { ContractorsStore } from '../../../../stores';
import { EmptyType } from '../../../../../../api/models/contractor.model';
import SearchIcon from '../../../../../../assets/images/agroid/colored/search.svg';
import NoDataIcon from '../../../../../../assets/images/agroid/colored/noDataIcon.svg';
import EAgroidRoute from '../../../../routes/AgroidRoute.enum';
import { ListModal } from '../../../../components';

import { ContractorRow } from './ContractorRow';
import {
  ContractorLink,
  NoDataPlaceholder,
  NoDataPlaceholderContent,
  StyledNoDataIcon,
  NoDataTitle,
} from './styled';

interface Props {
  onClose: () => void;
}

export const ContractorAddModal: FC<Props> = observer(({ onClose }) => {
  const {
    fetchContractors,
    setContractorName,
    clearContractors,
    contractors,
    contractorName,
    emptyType,
    isLoading,
  } = useStore(ContractorsStore);
  const ref = useRef(null);
  const [checkedOrgId, setCheckedOrgId] = useState<string>('');

  const history = useHistory();

  const throttledFilterRequest = useRef(throttle(() => fetchContractors(), 1000));

  useEffect(() => {
    return () => {
      clearContractors();
    };
  }, []);

  useEffect(() => {
    throttledFilterRequest.current();
  }, [contractorName]);

  const toggleContractorCheck = organizationId => {
    setCheckedOrgId(organizationId);
  };

  const handleChange = (value: string) => {
    setContractorName(value);
  };

  const handleClear = () => {
    setContractorName('');
  };

  const handleContinue = () => {
    onClose();
    history.push(
      `${getLink(EAgroidRoute.ScoutingContractorRequisites, {
        [checkedOrgId]: ':contractorId',
      })}?isScout=true`
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </>
      );
    }

    if (!isEmpty(contractors)) {
      return contractors.map(contractor => (
        <ContractorRow
          key={contractor.organizationId}
          {...contractor}
          onClick={() => toggleContractorCheck(contractor.organizationId)}
          checkedId={checkedOrgId}
        />
      ));
    }

    return (
      <NoDataPlaceholder>
        <NoDataPlaceholderContent>
          <StyledNoDataIcon src={emptyType === EmptyType.LIST ? NoDataIcon : SearchIcon} />
          <NoDataTitle>
            {emptyType === EmptyType.LIST
              ? 'Здесь будут отображаться ваши контрагенты'
              : `Контрагент ${contractorName} не найден. Попробуйте изменить условия поиска.`}
          </NoDataTitle>
        </NoDataPlaceholderContent>
      </NoDataPlaceholder>
    );
  };

  return (
    <ListModal
      header="Контрагенты"
      showHeaderSearch
      headerSearchValue={contractorName}
      onHeaderSearchChange={handleChange}
      onHeaderSearchClear={handleClear}
      onClose={onClose}
      footerElement={() => (
        <ContractorLink to={EAgroidRoute.ScoutingContractorCreate}>
          Нет в списке? Можно добавить
        </ContractorLink>
      )}
      buttons={{
        columns: 2,
        cancel: {
          title: 'Отменить',
          handler: onClose,
          isBasic: true,
          isStretched: true,
        },
        submit: {
          title: 'Продолжить',
          handler: handleContinue,
          isPrimary: true,
          isStretched: true,
          disabled: isEmpty(checkedOrgId),
        },
      }}
      dataTestId="contractors"
      height="70rem"
      ref={ref}
    >
      {renderContent()}
    </ListModal>
  );
});
