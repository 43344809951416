import { Colors } from "@farmlink/farmik-ui/dist/configs/Colors";
import styled from "styled-components";

import background from './assets/background.png';

export const BackgroundBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${background});
    background-size: cover;
`;