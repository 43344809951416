import React, { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useStore } from '../../../../utils';
import { ProfileStore } from '../../../auth/stores/profile.store';
import { ProtectedRoute } from '../../modules/common/components/routing/ProtectedRoute';
import { AgroidRoutes } from '../../routes';
import { EAccessRuleScoutingName } from '../../types';

import { ContractorsPage, ContractorRouter, ContractorCreatePage } from './pages';

export const ScoutingRouter: FC<any> = () => {
  const { fetchProfile, user } = useStore(ProfileStore);

  useEffect(() => {
    if (!user) {
      fetchProfile();
    }
  }, []);

  return (
    <Switch>
      <Route path={AgroidRoutes.ContractorCreate} component={ContractorCreatePage} />
      <Route path={AgroidRoutes.Contractor} component={ContractorRouter} />
      <ProtectedRoute
        path={AgroidRoutes.Contractors}
        component={ContractorsPage}
        redirect={AgroidRoutes.Index}
        accessRule={[EAccessRuleScoutingName.workWithContractors]}
      />
      <Redirect to={AgroidRoutes.Contractors} />
    </Switch>
  );
};
