import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';
// import { HiddenField } from 'uniforms-unstyled';

import { SelectField, InputField, BooleanField } from '../uniforms';

export const AddressScheme = new SimpleSchema({
  addressfull: {
    type: String,
    optional: true,
    label: false,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите адрес',
    },
  },

  // city: {
  //   type: String,
  //   optional: true,
  //   label: 'Город',
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // flat: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // index: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // house: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // block: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // region: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // street: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // cityType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // flatType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // houseType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // blockType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // kladrCode: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // streetType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // cityDistrict: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // countryISOCode: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
  // cityDistrictType: {
  //   type: String,
  //   optional: true,
  //   uniforms: {
  //     component: HiddenField,
  //   },
  // },
});

export const OrgTypeScheme = new SimpleSchema({
  orgTypeId: {
    type: Number,
    label: false,
    defaultValue: 4,
    uniforms: {
      component: SelectField,
      options: [],
    },
  },
  OrgType: {
    type: String,
    label: false,
    defaultValue: 'ИП',
    uniforms: {
      component: InputField,
      placeholder: 'Название',
    },
  },
});

const OrgSchema = new SimpleSchema({
  orgTypeId: {
    type: Number,
    required: true,
    label: 'Тип организации',
    uniforms: {
      component: SelectField,
      options: [],
    },
  },
  name: {
    type: String,
    label: 'Название организации',
    required: true,
    regEx: /^[a-zа-я0-9\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите название',
    },
  },
  INN: {
    type: String,
    optional: true,
    min: 10,
    max: 12,
    label: 'ИНН',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите данные',
      regexonchange: /(^$)|(^\d+$)/i,
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 10;
        if (orgTypeId === 4) {
          length = 12;
        }
        if (this.value.toString().length !== length) {
          return orgTypeId === 4 ? 'lengthINN_IP' : 'lengthINN';
        }
      }
    },
  },
  KPP: {
    type: String,
    optional: true,
    min: 9,
    max: 9,
    label: 'КПП',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите данные',
      regexonchange: /(^$)|(^\d+$)/i,
    },
  },
  OGRN: {
    type: String,
    optional: true,
    min: 13,
    max: 13,
    label: 'ОГРН',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите данные',
      regexonchange: /(^$)|(^\d+$)/i,
    },
  },
  OKVED: {
    type: String,
    optional: true,
    min: 2,
    max: 8,
    label: 'ОКВЭД',
    regEx: /^[0-9.]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите данные',
      regexonchange: /(^$)|(^[0-9.]+$)/i,
      // type: 'number',
      // mask: '99.99.99',
    },
  },
  addressJur: {
    type: AddressScheme,
    optional: true,
    label: 'Юридический адрес',
    uniforms: {
      placeholder: 'Укажите данные',
    },
  },
  addressFact: {
    type: AddressScheme,
    optional: true,
    label: 'Фактический адрес',
    uniforms: {
      placeholder: 'Укажите данные',
    },
  },
  sameAggresses: {
    type: Boolean,
    optional: true,
    label: 'Фактический и юридический адреса совпадают',
    defaultValue: true,
    uniforms: {
      component: BooleanField,
    },
  },
});

export const bridge = new SimpleSchema2Bridge(OrgSchema);

export { OrgSchema };
