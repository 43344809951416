import styled from 'styled-components';
import { Field } from '@zendeskgarden/react-forms';
import { Modal, Body } from '@zendeskgarden/react-modals';

export const ModalStyled = styled(Modal)<{ height?: string }>`
  backdrop-filter: blur(10px);
  left: auto;
  padding: 0;

  height: ${({ height }) => (height ? `${height}` : 'auto')};

  @media (max-width: 767px) {
    height: calc(100vh - 24px);
  }
}
`;

export const ModalBodyStyled = styled(Body)<{ scroll?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  margin: 0;

  ${({ scroll }) =>
    scroll
      ? `
          padding-right: 44px;
        `
      : `
          padding-right: 60px;
      `}
`;

export const ModalContent = styled.div`
  padding: 16px 0;
  flex-grow: 1;
`;

export const ModalActions = styled.div<{ scroll?: boolean }>`
  padding: 16px 60px 40px 60px;

  ${({ scroll, theme }) =>
    scroll
      ? `
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          border-top: 1px solid ${theme.colors.borderGray};
        `
      : ''}
`;

export const ModalSearchStyle = styled(Field)`
  margin-bottom: 0;
`;

export const ModalFooterStyled = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;
