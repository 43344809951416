import styled from 'styled-components';

const Input = styled.input`
  display: none;
`;

const StyledListingPageContractsContainer = {
  Input,
};

export default StyledListingPageContractsContainer;
