import styled from 'styled-components';
import { mediaQuery } from '@zendeskgarden/react-theming';

export const RoleUserBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  
  @media (min-width: 1024px) {
    flex-direction: row;
    height: calc(100vh - 116px);
    align-items: stretch;
    overflow: hidden:
  }
`;

export const Block = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 0;
`;

export const RoleBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  order: 2;

  @media (min-width: 1024px) {
    order: 1;
    width: 452px;
    min-width: 452px;
    margin-right: 20px;
  }

  @media (min-width: 1366px) {
    width: 628px;
    min-width: 628px;
  }

  & .role-block-well-header {
    width: 100%;
    box-sizing: content-box;
  }
`;

export const UsersBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  order: 1;

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding: 12px 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 12px;
  }

  @media (min-width: 1024px) {
    flex-grow: 1;
    order: 2;
    max-width: calc(100% - 452px - 20px);
    width: calc(100% - 452px - 20px);
    margin-bottom: 0;
  }

  @media (min-width: 1366px) {
    max-width: calc(100% - 628px - 20px);
    width: calc(100% - 628px - 20px);
  }
`;

export const DeleteButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 20px;
  border-top: 0.5px solid #e9e6f0;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${p => mediaQuery('up', 'sm', p.theme)} {
    align-items: center;
    flex-direction: row;
  }
`;

export const DeleteButtonWrapper = styled.div`
  order: 2;
  width: 100%;

  & > button {
    justify-content: center;
    height: 40px;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    order: 1;
    width: 160px;
    margin-right: 20px;
  }
`;

export const DeleteRoleDisclaimer = styled.div`
  order: 1;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;

  ${p => mediaQuery('down', 'sm', p.theme)} {
    margin-bottom: 16px;
  }

  @media (min-width: 768px) {
    order: 2;
    width: calc(100% - 160px - 16px);
  }
`;
