import { FC, HTMLAttributes, useState, useEffect } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@zendeskgarden/react-buttons';
import { Grid, Col, Row } from '@zendeskgarden/react-grid';

import Cancel from '../../../../assets/images/cancel.svg';

import { ModalStyled, ModalBodyStyled, ModalTitleStyled, IconScg } from './styled';

export interface PromptModalProps extends HTMLAttributes<HTMLDivElement> {
  message: string;
  changed: boolean;
  callUpdateRole: any;
}

export const PromptModal: FC<PromptModalProps> = ({
  changed,
  callUpdateRole,
  message = 'some text',
}) => {
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);

  const [visible, setVisible] = useState(false);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setShouldUnload(false);
  };

  const showModal = nextLocation => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = nextLocation => {
    if (!confirmedNavigation && changed) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    handleClose();
    callUpdateRole();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = event => {
      if (changed && !shouldUnload) {
        event.returnValue = message;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [changed, message, shouldUnload]);

  return (
    <>
      <Prompt when={changed} message={handleBlockedRoute} />
      {Boolean(visible) && (
        <ModalStyled onClose={handleClose} style={{ width: '440px' }}>
          <ModalBodyStyled>
            <IconScg src={Cancel} alt="" />
            <ModalTitleStyled data-test-id="modal-dialog-title">{message}</ModalTitleStyled>

            <Grid columns={2}>
              <Row justifyContent="center">
                <Col textAlign="center" xs={1}>
                  <Button isStretched isBasic size="large" onClick={handleClose}>
                    Не сохранять
                  </Button>
                </Col>
                <Col textAlign="center" xs={1}>
                  <Button isStretched isPrimary size="large" onClick={handleConfirmNavigationClick}>
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </Grid>
          </ModalBodyStyled>
        </ModalStyled>
      )}
    </>
  );
};
