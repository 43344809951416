export enum Colors {
  white = '#FFFFFF',
  gray = '#FAF9FD',
  grayBorder = '#E9E6F0',
  black = '#151f32',
  green = '#3FB65F',
  darkGray = '#9AA0AD',
  lightGray = '#FAF9FD',
  lightnessGray = '#E9E6F0',
  red = '#F29993',
  redHover = '#e76259',
  greenHover = '#259A42',
  yellow = '#FFE924',
  backgroundSecondaryGray = '#F2F1F4',
}
