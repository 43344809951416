import { TypeUserScout } from '../models/contractor.model';
import { TypeApiRoute } from '../models/type.api.request';
import { TypeResponsePageable } from '../models/type.response.pageable';

type TypeRequest = {
  fullName?: string;
  size?: number;
  page?: number;
};

type TypeResponse = { content: TypeUserScout[] } & TypeResponsePageable;

export const getUsersScouts: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/users/scouts`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
