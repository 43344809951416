import { FC } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useModal } from '@farmlink/farmik-ui';

import pdfLogo from '../../../assets/images/logos/pdfLogo40x40.svg';
import docLogo from '../../../assets/images/logos/docLogo40x40.svg';
import docxLogo from '../../../assets/images/logos/docxLogo48x48.svg';
import fileLogo from '../../../assets/images/logos/fileLogo40x40.svg';
import { EContractFileContentType } from '../../../../../../../api/models/as-contracts/contract/contract.model';

import Styled from './ContractCard.styles';
import { TContractCardProps } from './ContractCard.types';

const ContractCard: FC<TContractCardProps> = ({
  name,
  contractFileInfo: { fileContentType, fileSize, downloadUrl, fileName },
  createdAt,
  handleRemoveItem,
  id,
}) => {
  const { openModalByModalId } = useModal();

  const getImgFileLogo = (): string => {
    switch (fileContentType) {
      case EContractFileContentType.Doc:
        return docLogo;
      case EContractFileContentType.Docx:
        return docxLogo;
      case EContractFileContentType.Pdf:
        return pdfLogo;
      default:
        return fileLogo;
    }
  };

  const handleDownloadClick = async (): Promise<void> => {
    try {
      const { data } = await axios.get(downloadUrl, { responseType: 'blob' });

      if (!data) {
        return;
      }

      const blob = new Blob([data], {
        type: fileContentType,
      });

      saveAs(blob, fileName);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteFileClick = () => {
    handleRemoveItem(id);
  };

  return (
    <Styled.Wrapper>
      <Styled.InnerWrapper $flex="0 1 40px">
        <Styled.Logo src={getImgFileLogo()} alt={fileContentType || ''} />
      </Styled.InnerWrapper>
      <Styled.InnerWrapper $flex="1 1 50%" $isElementsMarginBottom $isColumn>
        <Styled.Title>{name}</Styled.Title>
        <Styled.Description>Размер: {`${(fileSize / 1000000).toFixed(2)} Мб`}</Styled.Description>
        <Styled.Description>Дата: {moment(createdAt).format('DD.MM.YYYY')}</Styled.Description>
        <Styled.ActionButtonsWrapper>
          <Styled.DownloadButton onClick={handleDownloadClick}>Скачать</Styled.DownloadButton>
          {handleRemoveItem && (
            <Styled.DeleteButton
              onClick={() => {
                openModalByModalId('removeItemConfirmModal', {
                  removeItemHandler: handleDeleteFileClick,
                });
              }}
            >
              Удалить
            </Styled.DeleteButton>
          )}
        </Styled.ActionButtonsWrapper>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

export default ContractCard;
