export enum AgroidRoutes {
  ExternalInvite = '/invite',
  Competencies = '/agroid/competencies',
  Index = '/agroid',
  Interests = '/agroid/interests',
  Invites = '/agroid/organizations/invites',
  Organization = '/agroid/organizations/:orgId',
  OrganizationEmployes = '/agroid/organizations/:orgId/employes',
  OrganizationInvites = '/agroid/organizations/:orgId/invites',
  OrganizationRequisites = '/agroid/organizations/:orgId/requisites',
  OrganizationRole = '/agroid/organizations/:orgId/roles/:roleId',
  OrganizationRoles = '/agroid/organizations/:orgId/roles',
  Organizations = '/agroid/organizations',
  Scouting = '/agroid/scouting',
  Contractors = '/agroid/scouting/contractors',
  ContractorCreate = '/agroid/scouting/contractors/create',
  Contractor = '/agroid/scouting/contractors/:contractorId',
  ContractorEmployes = '/agroid/scouting/contractors/:contractorId/employes',
  ContractorInvites = '/agroid/scouting/contractors/:contractorId/invites',
  ContractorRequisites = '/agroid/scouting/contractors/:contractorId/requisites',
  ContractorRole = '/agroid/scouting/contractors/:contractorId/roles/:roleId',
  ContractorRoles = '/agroid/scouting/contractors/:contractorId/roles',
  Profile = '/agroid/profile',
  Support = '/agroid/support',
  Users = '/agroid/users',
  EditCompetitionsData = '/agroid/edit-expertises',
  EditInterestsData = '/agroid/edit-interests',
}
