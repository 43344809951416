import { FC, HTMLAttributes, SVGProps, useEffect, useMemo, useState } from 'react';
import { NavLink, Link, useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HeaderItemIcon, HeaderItemText, HeaderItemWrapper } from '@zendeskgarden/react-chrome';
import { isMobile, isTablet } from 'react-device-detect';

import { ReactComponent as SupportIcon } from '../../../../assets/images/agroid/question.svg';
import { ReactComponent as OrgSvg } from '../../../../assets/images/agroid/organization.svg';
import { ReactComponent as HomeSvg } from '../../../../assets/images/agroid/home.svg';
import { AgroidRoutes } from '../../routes';
import { useWindowSize } from '../../hooks';
import { MaxWidthLayout } from '../MaxWidthLayout/MaxWidthLayout';
import { TPageLink } from '../../configs/types/pages/elements';
import { useStore } from '../../../../utils';
import { CheckAccessStore } from '../../stores';
import { CheckAccessController } from '../../controllers/checkAccess.controller';

import { UserMenu } from './UserMenu';
import {
  HeaderContainerStyled,
  HeaderStyled,
  StyledHeaderItem,
  LogoHeaderItem,
  LogoSvgStyled,
} from './styled';

export interface HeaderMenuProps extends HTMLAttributes<HTMLDivElement> {
  linkList: TPageLink[];
  param?: string;
}

export type ItemProps = {
  name: string;
  link: AgroidRoutes | string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  accent?: string;
  slug: string;
  isHidden?: boolean;
};

export const menuItems: Record<string, ItemProps> = {
  index: {
    name: 'Главная',
    link: AgroidRoutes.Index,
    icon: () => <HomeSvg />,
    slug: 'main',
  },
  orgs: {
    name: 'Организации',
    link: AgroidRoutes.Organizations,
    icon: OrgSvg,
    slug: 'organizations',
  },
};

export interface MenuItemProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  link: string;
  history?: any;
  icon?: FC<SVGProps<SVGSVGElement>>;
  accent?: string;
  slug: string;
  isHidden?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ history, name, link, icon: Icon, slug, isHidden }) => {
  const match = useRouteMatch(link);

  if (isHidden) return null;

  const isActive = match
    ? (link === AgroidRoutes.Index && match.isExact) || link !== AgroidRoutes.Index
    : false;

  return (
    <HeaderItemWrapper title={link} data-test-id={`header-menu-item-${slug}`}>
      <StyledHeaderItem
        onClick={() => history.push(link)}
        isActive={isActive}
        data-test-id={`header-menu-item-${slug}-button`}
      >
        {Icon ? (
          <HeaderItemIcon className={slug}>
            <Icon />
          </HeaderItemIcon>
        ) : null}
        <HeaderItemText data-test-id={`header-menu-item-${slug}-text`}>{name}</HeaderItemText>
      </StyledHeaderItem>
    </HeaderItemWrapper>
  );
};

export const HeaderMenu: FC<HeaderMenuProps> = observer(({ linkList, param }) => {
  const [isClipped, setIsClipped] = useState(false);
  const [isRulesLoaded, setIsRulesLoaded] = useState(false);
  const [windowWidth] = useWindowSize();
  const history = useHistory();
  const { getUserHasGlobalAccessRule, globalAccessRules } = useStore(CheckAccessStore);
  const { getAllActiveGlobalActionsForCurrentUser } = useStore(CheckAccessController);

  useEffect(() => {
    getAllActiveGlobalActionsForCurrentUser().then(() => {
      setIsRulesLoaded(true);
    });
  }, []);

  useEffect(() => {
    setIsClipped(isMobile && !isTablet);
  }, [windowWidth]);

  const moduleLinkList = useMemo(() => {
    return linkList.reduce<Record<string, ItemProps>>((data, link) => {
      data[link.uniqueKey] = {
        name: link.label,
        link: link.path,
        slug: link.uniqueKey,
        icon: link?.labelLogo,
        isHidden: !getUserHasGlobalAccessRule(link.accessRule),
      };

      return data;
    }, menuItems);
  }, [linkList, isRulesLoaded]);

  return (
    <HeaderContainerStyled data-test-id="header-container">
      <MaxWidthLayout className="header" data-test-id="header-layout">
        <HeaderStyled title={param} isStandalone data-test-id="header">
          <HeaderItemWrapper data-test-id="header-logo-wrapper">
            <NavLink exact to={menuItems.index.link} data-test-id="header-logo-link">
              <LogoHeaderItem hasLogo>
                <LogoSvgStyled title="v1.3.2" />
              </LogoHeaderItem>
            </NavLink>
          </HeaderItemWrapper>
          {!isClipped &&
            Object.entries(moduleLinkList).map(([key, item]) => (
              <MenuItem key={key} history={history} {...item} />
            ))}
          <HeaderItemWrapper style={{ marginLeft: 'auto' }}></HeaderItemWrapper>

          <HeaderItemWrapper data-test-id="header-menu-item-support">
            <Link
              to={{ pathname: 'https://digitalagro.atlassian.net/servicedesk/customer/portal/18' }}
              target="_blank"
              data-test-id="header-menu-item-support-link"
            >
              <SupportIcon style={{ margin: '0 24px', position: 'relative', top: '6px' }} />
            </Link>
          </HeaderItemWrapper>

          <HeaderItemWrapper data-test-id="header-menu-item-user">
            <UserMenu />
          </HeaderItemWrapper>
        </HeaderStyled>
      </MaxWidthLayout>
    </HeaderContainerStyled>
  );
});
