import React, { FC, useMemo, SVGProps, useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import NoDataIcon from '../../../../../../../assets/images/agroid/colored/noDataIcon.svg';
import { CheckAccessStore, RolesStore, ScoutsStore } from '../../../../../stores';
import { useStore } from '../../../../../../../utils';
import { WellRolesStyled } from '../../../../OrgsPage/styled';
import { RoleCard, WellHeader } from '../../../../../components';
import { CardsList } from '../../../../../components/CardsList/CardsList';
import { NoDataPlaceholder, NoDataTitle, StyledNoDataIcon } from '../../styled';
import { NewRoleModal } from '../../../../../components/NewRoleModal/NewRoleModal';
import { DelRoleModal } from '../../../../../components/DelRoleModal/DelRoleModal';
import { ProfileStore } from '../../../../../../auth/stores/profile.store';
import { TypeRoleSettings } from '../../../../../../../api/models/role.model';
import { AccessRuleUserActionName, EAccessRuleScoutingName } from '../../../../../types';

export interface IAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
}

export const ContractorRoles: FC = observer(() => {
  const [visible, setVisible] = useState(false);
  const [visibleDelRole, setVisibleDelRole] = useState<string | boolean>(false);
  const { contractorId: orgId } = useParams<{ contractorId: string }>();
  const { isLoading, roles, setOrganization, fetchRoles } = useStore(RolesStore);
  const {
    isOrganizationOwner,
    getUserHasAccessRulesArray,
    accessRules,
    globalAccessRules,
  } = useStore(CheckAccessStore);

  useEffect(() => {
    setOrganization(orgId);
    fetchRoles();
  }, [orgId]);

  const roleIsReadonly = useCallback(
    (role: TypeRoleSettings) => {
      if (isOrganizationOwner) return false;

      if (
        getUserHasAccessRulesArray(
          [EAccessRuleScoutingName.manageScouting, AccessRuleUserActionName.editRole],
          true
        )
      ) {
        return false;
      }

      return true;
    },
    [accessRules, globalAccessRules]
  );

  const actions = useMemo(
    () => [
      {
        caption: isMobile ? false : 'Создать роль',
        component: isMobile ? IconButton : Button,
        icon: isMobile && PlusIcon,
        dataTestIdAction: 'organization-roles-role-create-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
      },
    ],
    []
  );

  const handleRoleDel = (id: string) => {
    setVisibleDelRole(id);
  };

  const getAnotherRoleName = () => {
    return (
      roles.find(({ id, isDefault, isOwner }) => id !== visibleDelRole && isDefault && !isOwner) ||
      {}
    ).name;
  };

  return (
    <>
      <WellRolesStyled>
        <WellHeader
          title="Роли контрагента"
          actions={actions}
          isAlone
          dataTestId="organization-roles"
        />
      </WellRolesStyled>
      {isLoading ? (
        <Well>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </Well>
      ) : roles.length ? (
        <CardsList
          className={'role-list'}
          list={roles.map(role => ({
            ...role,
            onDelClick: role.isScoutForUser ? undefined : () => handleRoleDel(role.id),
            isReadonly: roleIsReadonly(role),
            isOrganizationOwner,
          }))}
          card={RoleCard}
          dataTestId="roles"
        />
      ) : (
        <NoDataPlaceholder className="with-alone">
          <StyledNoDataIcon src={NoDataIcon} />
          <NoDataTitle>Нет ролей</NoDataTitle>
        </NoDataPlaceholder>
      )}
      {visible && <NewRoleModal orgId={orgId} onClose={() => setVisible(false)} />}
      {visibleDelRole && (
        <DelRoleModal
          roleId={visibleDelRole as string}
          anotherRoleName={getAnotherRoleName()}
          onClose={() => setVisibleDelRole(false)}
        />
      )}
    </>
  );
});
