import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Anchor } from '@zendeskgarden/react-buttons';

import { formatPhoneFromStr, num2str, useStore } from '../../../../../../../../utils';
import { ProfileStore } from '../../../../../../../auth/stores/profile.store';
import {
  NoData,
  OrgLogo,
  ServicesList,
  TypeUserRoleEnumString,
  WellHeader,
} from '../../../../../../components';
import {
  DetailsWrapper,
  OrgLogoWrapper,
  UserRole,
  WellBodyStyled,
  WellStyled,
  DetailsColumn,
} from '../../../../../../pages/OrgsPage/styled';
import { OrganizationStore } from '../../../../../../stores';
import { ReactComponent as NoLogo } from '../../../../../../../../assets/images/agroid/logo-template.svg';
import { ReactComponent as EditSvg } from '../../../../../../../../assets/images/agroid/edit.svg';
import { IAction } from '../../../../../../pages/OrgsPage/pages/OrgProfile';
import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';

import { TProfilePageContainerProps } from './ProfilePageContainer.types';

const ProfilePageContainer: FC<TProfilePageContainerProps> = observer(() => {
  const { user, fetchProfile } = useStore(ProfileStore);

  const {
    organization,
    setFilter,
    fetchOrganization,
    getOrganizationUsers,
    usersList,
    isLoading,
  } = useStore(OrganizationStore);

  const params = useParams<{ [key: string]: string }>();

  const history = useHistory();

  const [role, setRole] = useState<boolean>(false);

  useEffect(() => {
    fetchProfile();
    fetchOrganization(params?.contractorId);
    setFilter({
      organizationID: params?.contractorId,
    });
    getOrganizationUsers();
  }, []);

  useEffect(() => {
    if (user) {
      const userInList = usersList.find(({ userId }) => userId === user.id);
      if (userInList) {
        // todo Когда старые роли исчезнуть оставить userInList.roleInfo?.name !!!!!!!
        setRole(userInList.roleInfo?.name || TypeUserRoleEnumString[userInList.role]);
      }
    }
  }, [user, usersList]);

  const orgActions: IAction[] = [
    {
      icon: EditSvg,
      caption: isMobileOnly ? '' : 'Редактировать',
      component: Anchor,
      dataTestIdAction: 'organization-profile-edit-action',
      componentProps: {
        isBasic: true,
      },
      onClick: () => {
        history.push(
          generatePath(EAgroidRoute.ScoutingContractorRequisites, {
            contractorId: params?.contractorId,
          })
        );
      },
    },
  ];

  console.log('organization shit ', organization);

  return (
    <>
      <WellStyled className="profile">
        {isLoading ? (
          <>
            <Skeleton height="24px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
          </>
        ) : organization ? (
          <>
            <WellHeader
              title={'Данные организации'}
              actions={orgActions}
              dataTestId="organization-profile"
            />
            <WellBodyStyled>
              <Grid>
                <Row>
                  <OrgLogoWrapper>
                    {organization.logo ? <OrgLogo {...organization.logo} /> : <NoLogo />}
                  </OrgLogoWrapper>
                  <Col>
                    <Grid>
                      <Row>
                        <Col md={3}>
                          <DetailsWrapper>
                            <div className="name">
                              {`${get(organization, 'type.OrgType', '')} ${organization.name}`}
                            </div>
                            <div className="employeersCount">
                              {`${usersList.length} `}
                              {num2str(usersList.length, [
                                'сотрудник',
                                'сотрудника',
                                'сотрудников',
                              ])}
                            </div>
                            <div className="contacts">
                              {organization?.phone && (
                                <div className="phone">
                                  {formatPhoneFromStr(organization?.phone)}
                                </div>
                              )}
                              {organization?.email && (
                                <div className="email">{organization.email}</div>
                              )}
                            </div>
                          </DetailsWrapper>
                        </Col>
                        <Col md={3}>
                          <DetailsWrapper>
                            {organization.contact && (
                              <DetailsColumn>
                                <div className="label">Представитель</div>
                                {organization.contact}
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                        <Col md={3}>
                          <DetailsWrapper>
                            {organization.representativePosition && (
                              <DetailsColumn>
                                <div className="label">Должность</div>
                                {organization.representativePosition}
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                        <Col md={2}>
                          <DetailsWrapper>
                            {organization.manager && organization.manager?.fullName && (
                              <DetailsColumn>
                                <div className="label">Ответственный</div>
                                {organization.manager?.fullName}
                              </DetailsColumn>
                            )}
                          </DetailsWrapper>
                        </Col>
                      </Row>
                    </Grid>
                  </Col>
                </Row>
              </Grid>
            </WellBodyStyled>
          </>
        ) : (
          <NoData message="Данные организации недоступны" />
        )}
      </WellStyled>
      <ServicesList />
    </>
  );
});

ProfilePageContainer.displayName = 'ProfilePageContainer';

export default ProfilePageContainer;
