import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  BlockConfirmModal,
  NewUserModal,
  NewUserModallSuccess,
  UnBlockConfirmModal,
  UserDetailsModal,
} from '../../../../../components';

import { TEmployeesModalContainerProps } from './EmployeesModalContainer.types';

type TParams = {
  orgId: string;
  contractorId: string;
};

const EmployeesModalContainer: FC<TEmployeesModalContainerProps> = ({
  isClickedOnActionButton,
  handleUpdateData,
  handleHidingCustomComponent,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleUserId, setVisibleUserDetails] = useState<boolean | string>(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleBlockConfirm, setVisibleBlockConfirm] = useState<boolean | string>(false);
  const [visibleUnBlockConfirm, setVisibleUnBlockConfirm] = useState<boolean | string>(false);

  const { orgId, contractorId } = useParams<TParams>();

  useEffect(() => {
    if (isClickedOnActionButton) {
      setVisible(true);
    }
  }, [isClickedOnActionButton]);

  useEffect(() => {
    if (sessionStorage.getItem('selectedUserId')) {
      setVisibleUserDetails(sessionStorage.getItem('selectedUserId'));
    }
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('selectedUserId');
    };
  }, []);

  return (
    <>
      {visible && (
        <NewUserModal
          orgId={orgId || contractorId || ''}
          onClose={result => {
            setVisible(false);
            handleHidingCustomComponent();

            if (result === true) {
              setVisibleSuccess(true);
            }
          }}
        />
      )}

      {visibleUserId && (
        <UserDetailsModal
          title="Сотрудник"
          userId={visibleUserId as string}
          orgId={orgId}
          onClose={result => {
            if (result === 'blockConfirm') {
              setVisibleBlockConfirm(visibleUserId);
            }

            if (result === 'unblockConfirm') {
              setVisibleUnBlockConfirm(visibleUserId);
            }

            setVisibleUserDetails(false);
            handleHidingCustomComponent();
          }}
        />
      )}

      {visibleSuccess && (
        <NewUserModallSuccess
          onClose={() => {
            setVisibleSuccess(false);

            handleHidingCustomComponent();
            handleUpdateData();
          }}
        />
      )}

      {visibleBlockConfirm && (
        <BlockConfirmModal
          userId={visibleBlockConfirm as string}
          orgId={orgId}
          onClose={() => {
            setVisibleBlockConfirm(false);

            handleHidingCustomComponent();
            handleUpdateData();
          }}
        />
      )}

      {visibleUnBlockConfirm && (
        <UnBlockConfirmModal
          userId={visibleUnBlockConfirm as string}
          onClose={() => {
            setVisibleUnBlockConfirm(false);

            handleHidingCustomComponent();
            handleUpdateData();
          }}
        />
      )}
    </>
  );
};

export default EmployeesModalContainer;
