import { observer } from 'mobx-react';
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useStore } from '../../../../../../utils';
import { AgroidRoutes } from '../../../../routes';
import { CheckAccessStore } from '../../../../stores';
import { TActionNameUnion } from '../../../../types';

type TProtectedRouteProps = {
  accessRule: TActionNameUnion[];
  redirect?: string;
} & RouteProps;

export const ProtectedRoute = observer(
  ({ accessRule, redirect, ...props }: TProtectedRouteProps) => {
    const { getUserHasAccessRulesArray } = useStore(CheckAccessStore);

    if (getUserHasAccessRulesArray(accessRule, true)) return <Route {...props} />;
    return <Redirect to={redirect || AgroidRoutes.Index} />;
  }
);
