import { forwardRef, memo, ReactNode, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { isEmpty, isFunction } from 'lodash';

import { ModalHeader, ModalSearch } from './components';
import {
  ModalStyled,
  ModalBodyStyled,
  ModalActions,
  ModalContent,
  ModalFooterStyled,
} from './styled';

interface IButton {
  show?: boolean;
  title?: string;
  handler?: () => void;
  disabled?: boolean;
  size?: 'large' | 'small' | 'medium';
  isBasic?: boolean;
  isPrimary?: boolean;
  isStretched?: boolean;
}

interface IButtons {
  columns?: number;
  cancel?: IButton;
  submit?: IButton;
}

interface Props {
  show?: boolean;
  header: string;
  headerActions?: any[];
  showHeaderSearch?: boolean;
  headerSearchValue?: string;
  onHeaderSearchChange?: (value: string) => void;
  onHeaderSearchClear?: () => void;
  buttons?: IButtons;
  onClose: () => void;
  footerElement?: () => ReactNode;
  height?: string;
  children?: ReactNode;
  dataTestId?: string;
}

const ListModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      show = true,
      header,
      headerActions = [],
      showHeaderSearch = false,
      headerSearchValue = '',
      onHeaderSearchChange,
      onHeaderSearchClear,
      buttons,
      onClose,
      footerElement,
      height,
      children,
      dataTestId,
    },
    ref
  ) => {
    const [scroll, setScroll] = useState<boolean>(false);
    const modalBodyRef = useRef(null);

    useEffect(() => {
      const modalBodyClientHeight = modalBodyRef.current ? modalBodyRef.current.clientHeight : 0;
      const modalBodyScrollHeight = modalBodyRef.current ? modalBodyRef.current.scrollHeight : 0;

      if (modalBodyScrollHeight > modalBodyClientHeight) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });

    return (
      <>
        {show && (
          <ModalStyled
            onClose={onClose}
            ref={ref}
            data-test-id={dataTestId && `${dataTestId}-modal`}
            height={height}
          >
            <ModalHeader
              title={header}
              actions={headerActions}
              scroll={scroll}
              dataTestId={dataTestId && `${dataTestId}-modal-header`}
            >
              {showHeaderSearch && (
                <ModalSearch
                  onChange={onHeaderSearchChange}
                  onClear={onHeaderSearchClear}
                  value={headerSearchValue}
                  dataTestId={dataTestId && `${dataTestId}-modal-header-search`}
                />
              )}
            </ModalHeader>

            <ModalBodyStyled
              scroll={scroll}
              ref={modalBodyRef}
              data-test-id={dataTestId && `${dataTestId}-modal-body`}
            >
              <ModalContent
                id="block"
                data-test-id={dataTestId && `${dataTestId}-modal-body-content`}
              >
                {!isEmpty(children) && children}
              </ModalContent>
            </ModalBodyStyled>

            <ModalActions
              scroll={scroll}
              data-test-id={dataTestId && `${dataTestId}-modal-actions`}
            >
              {buttons && (
                <Grid columns={buttons.columns || 1}>
                  <Row justifyContent="center">
                    {buttons.cancel && (
                      <Col
                        textAlign="center"
                        xs={1}
                        style={{ padding: buttons.submit ? '0 12px' : '0 12%' }}
                      >
                        <Button
                          isStretched={Boolean(buttons.cancel.isStretched)}
                          isBasic={Boolean(buttons.cancel.isBasic)}
                          isPrimary={Boolean(buttons.cancel.isPrimary)}
                          onClick={buttons.cancel.handler}
                          size={buttons.cancel.size ? buttons.cancel.size : 'large'}
                          disabled={Boolean(buttons.cancel.disabled)}
                          data-test-id={dataTestId && `${dataTestId}-modal-action-cancel`}
                        >
                          {buttons.cancel.title || 'Закрыть'}
                        </Button>
                      </Col>
                    )}

                    {buttons.submit && (
                      <Col
                        textAlign="center"
                        xs={1}
                        style={{ padding: buttons.cancel ? '0 12px' : '0 12%' }}
                      >
                        <Button
                          isStretched={Boolean(buttons.submit.isStretched)}
                          isBasic={Boolean(buttons.submit.isBasic)}
                          isPrimary={Boolean(buttons.submit.isPrimary)}
                          onClick={buttons.submit.handler}
                          size={buttons.submit.size ? buttons.submit.size : 'large'}
                          disabled={Boolean(buttons.submit.disabled)}
                          data-test-id={dataTestId && `${dataTestId}-modal-action-submit`}
                        >
                          {buttons.submit.title || 'Сохранить'}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Grid>
              )}

              {isFunction(footerElement) && (
                <ModalFooterStyled data-test-id={dataTestId && `${dataTestId}-modal-footer`}>
                  {footerElement()}
                </ModalFooterStyled>
              )}
            </ModalActions>
          </ModalStyled>
        )}
      </>
    );
  }
);

export default memo(observer(ListModal));
