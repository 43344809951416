import { FC, useState, useEffect } from 'react';
import { Modal, Body } from '@zendeskgarden/react-modals';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Label } from '@zendeskgarden/react-forms';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Dropdown, Item, Menu, Trigger } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';

import { TypeUserStatusLabels } from '../../../../api/models/user.model';
import { ModalHeader, NoData, IModalHeaderAction } from '../';
import { useStore, formatDate, formatPhone } from '../../../../utils';
import { EmployeeStore } from '../../stores/employee.store';
import { RolesStore } from '../../stores/roles.store';
import { AccessRuleVisibility, AccessRuleUserActionName } from '../../types';

import { StyledRoleButton, StyledStatus, StyledUserName, StyledUserNameField } from './styled';

type TSelectedItem = {
  value: any;
  label: string;
};
export interface IUserDetailsModalProps {
  userId: string;
  orgId: string;
  title: string;
  onClose: (result?: any) => void;
}

const NOITEM = { label: 'Нет роли', value: '' };

export const UserDetailsModal: FC<IUserDetailsModalProps> = observer(
  ({ userId, onClose, title }) => {
    const { isLoading, employee: user, setEmployeeId, fetchEmployee } = useStore(EmployeeStore);
    const { roles, assignUsers } = useStore(RolesStore);
    const [rotated, setRotated] = useState<boolean | undefined>();
    const [selectedItem, setSelectedItem] = useState<TSelectedItem>(NOITEM);

    useEffect(() => {
      setEmployeeId(userId);
      fetchEmployee();
    }, [userId]);

    useEffect(() => {
      let userRole = NOITEM;
      if (user && user.roleInfo) {
        userRole = {
          value: user.roleInfo.id,
          label: user.roleInfo.name,
        };
      }
      setSelectedItem(userRole);
    }, [user]);

    const handleSelectRole = item => {
      const role = roles.find(r => r.id === item);
      if (role) {
        setSelectedItem({
          value: role.id,
          label: role.name,
        });
        assignUsers(role.id, [user.id]);
      } else {
        setSelectedItem(NOITEM);
      }
    };

    const handleBlockUser = () => {
      onClose('blockConfirm');
    };

    const handleUnBlockUser = () => {
      onClose('unblockConfirm');
    };

    const modalActionsActive: IModalHeaderAction[] = [
      {
        caption: 'Заблокировать ✕',
        component: Anchor,
        componentProps: {
          onClick: handleBlockUser,
          isDanger: true,
        },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.deleteEmployee,
      },
    ];

    const modalActionsFired: IModalHeaderAction[] = [
      {
        caption: 'Разблокировать ✕',
        component: Anchor,
        componentProps: {
          onClick: handleUnBlockUser,
          isDanger: true,
        },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.deleteEmployee,
      },
    ];

    let modalActions = [];
    if (!isLoading) {
      if (user && user.status === 'ACTIVE') {
        modalActions = modalActionsActive;
      } else {
        modalActions = modalActionsFired;
      }
    }

    return (
      <Modal onClose={onClose}>
        <ModalHeader
          title={title}
          actions={selectedItem.label !== 'Владелец' ? modalActions : []}
        />
        <Body style={{ paddingBottom: 0 }}>
          {isLoading ? (
            <>
              <Skeleton height="24px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </>
          ) : user ? (
            <>
              {' '}
              <StyledUserName data-id={userId} isBold>
                {user.userName}
              </StyledUserName>
              <Grid>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Статус</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col>
                    <StyledStatus status={user.status}>
                      {Boolean(user.status) && TypeUserStatusLabels[user.status]}
                    </StyledStatus>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Роль</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col>
                    {selectedItem.label === 'Владелец' ? (
                      <>Владелец</>
                    ) : (
                      <Dropdown
                        selectedItem={selectedItem.value}
                        onSelect={handleSelectRole}
                        onStateChange={options => 'isOpen' in options && setRotated(options.isOpen)}
                      >
                        <Trigger>
                          <StyledRoleButton>
                            {selectedItem.label}
                            <Button.EndIcon isRotated={rotated}>
                              <ChevronIcon />
                            </Button.EndIcon>
                          </StyledRoleButton>
                        </Trigger>
                        <Menu isCompact>
                          {roles
                            .filter(r => r.name !== 'Владелец')
                            .map(({ id, name }) => (
                              <Item value={id} key={id}>
                                {name}
                              </Item>
                            ))}
                        </Menu>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Телефон</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col>{formatPhone(get(user, 'phone'))}</Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>E-mail</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Anchor href={`mailto:${user.email}`} style={{ color: 'inherit' }}>
                      {user.email}
                    </Anchor>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <StyledUserNameField>
                      <Label>Дата вступления</Label>
                    </StyledUserNameField>
                  </Col>
                  <Col>
                    <>{formatDate(user.organizationJoinDate, 'dd.MM.yyyy')}</>
                  </Col>
                </Row>
              </Grid>
              <Grid style={{ paddingTop: '20px' }}>
                <Row>
                  <Col size={1}></Col>
                  <Col>
                    <Button isPrimary size="large" isStretched onClick={onClose}>
                      Закрыть
                    </Button>
                  </Col>
                  <Col size={1}></Col>
                </Row>
              </Grid>
            </>
          ) : (
            <NoData message="Данные пользователя не найдены" />
          )}
        </Body>
      </Modal>
    );
  }
);
