import { Anchor } from '@zendeskgarden/react-buttons';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AppDetailsModal,
  CancelConfirmModal,
  IModalHeaderAction,
  NewUserModal,
  NewUserModallSuccess,
} from '../../../../../components';

import { TInvitesModalContainerProps } from './InvitesModalContainer.types';

type TParams = {
  orgId: string;
  contractorId: string;
};

const InvitesModalContainer: FC<TInvitesModalContainerProps> = ({
  isClickedOnActionButton,
  handleUpdateData,
  handleHidingCustomComponent,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleUserId, setVisibleUserDetails] = useState<boolean | string>(false);
  const [visibleCancelConfirm, setVisibleCancelConfirm] = useState<boolean | string>(false);

  const { orgId, contractorId } = useParams<TParams>();

  const handleReapplyUser = event => {
    event.preventDefault();
    setVisibleUserDetails(false);
    setVisibleCancelConfirm(visibleUserId);
  };

  const modalActions: IModalHeaderAction[] = [
    {
      caption: 'Отозвать ✕',
      component: Anchor,
      dataTestIdAction: 'applications-user-reapply-action',
      componentProps: {
        onClick: handleReapplyUser,
        isDanger: true,
      },
    },
  ];

  useEffect(() => {
    if (isClickedOnActionButton) {
      setVisible(true);
    }
  }, [isClickedOnActionButton]);

  useEffect(() => {
    if (sessionStorage.getItem('selectedInvitationId')) {
      setVisibleUserDetails(sessionStorage.getItem('selectedInvitationId'));
    }
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('selectedInvitationId');
    };
  }, []);

  return (
    <>
      {visible && (
        <NewUserModal
          orgId={orgId || contractorId || ''}
          onClose={result => {
            setVisible(false);

            handleHidingCustomComponent();

            if (result === true) {
              setVisibleSuccess(true);
            }
          }}
        />
      )}

      {visibleSuccess && (
        <NewUserModallSuccess
          onClose={() => {
            setVisibleSuccess(false);

            handleHidingCustomComponent();
            handleUpdateData();
          }}
        />
      )}

      {visibleUserId && (
        <AppDetailsModal
          title="Приглашение"
          actions={modalActions}
          invitationId={visibleUserId as string}
          onClose={result => {
            if (result === 'cancelConfirm') {
              setVisibleCancelConfirm(visibleUserId);
            }

            setVisibleUserDetails(false);
            handleHidingCustomComponent();
          }}
        />
      )}

      {visibleCancelConfirm && (
        <CancelConfirmModal
          invitationId={visibleCancelConfirm as string}
          onClose={() => {
            setVisibleCancelConfirm(false);

            handleHidingCustomComponent();
            handleUpdateData();
          }}
        />
      )}
    </>
  );
};

export default InvitesModalContainer;
