import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';
import { ReactComponent as StartIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

import { SelectField, MediaInputField } from '../uniforms';

const schema = new SimpleSchema({
  fullname: {
    type: String,
    label: 'Поиск',
    uniforms: {
      component: MediaInputField,
      start: StartIcon,
      label: false,
      placeholder: 'Поиск',
    },
  },
  status: {
    type: String,
    label: 'Статусы',
    uniforms: {
      component: SelectField,
      label: false,
      placeholder: 'Статусы',
    },
  },
  roleId: {
    type: String,
    label: 'Роли',
    uniforms: {
      component: SelectField,
      label: false,
      placeholder: 'Роли',
    },
  },
});

export const bridge = new SimpleSchema2Bridge(schema);
