import { ApiNames, lazyInject, provide } from '../../../../../../../../utils';
import { ListingPageService } from '../../../../services/configs/pages';
import { ListingPageStore } from '../../../../stores/configs';

@provide.singleton()
class ListingPageController {
  @lazyInject(ListingPageStore)
  protected listingPageStore: ListingPageStore;

  @lazyInject(ListingPageService)
  protected listingPageService: ListingPageService;

  get isLoading() {
    return this.listingPageService.isLoading;
  }

  fetchItemList = async (config: {
    uniqueKey: string;
    apiMethodName: string;
    payload?: any;
  }): Promise<any> => {
    const { filter, setItemList } = this.listingPageStore;

    const { fetchItemList } = this.listingPageService;

    const fetchedItemList = await fetchItemList({
      ...config,
      payload: config?.payload ? { ...config.payload, ...filter } : null,
    });

    if (fetchedItemList?.content?.length) {
      setItemList(config.uniqueKey, fetchedItemList.content);

      return fetchedItemList;
    }

    if (fetchedItemList?.length) {
      setItemList(config.uniqueKey, fetchedItemList.content);

      return fetchedItemList;
    }
  };

  selectFilter = (config: { filter: { [key: string]: string }; searchKey: string }): void => {
    const { setFilter, setSearchValue } = this.listingPageStore;

    if (config.filter?.[config.searchKey] || config.filter?.[config.searchKey] === '') {
      setSearchValue(config.filter[config.searchKey]);
    }

    setFilter(config.filter);
  };

  uploadFile = async (file: File): Promise<string> => {
    const { id, uploadUrl } = await this.listingPageService.uploadFileDescription(file);

    if (!id || !uploadUrl) {
      return;
    }

    const success = await this.listingPageService.uploadFile(file, uploadUrl);

    if (success) {
      return id;
    }
  };

  removeItem = async (paramName: string, itemId: string, apiName: ApiNames) => {
    await this.listingPageService.deleteItem(paramName, itemId, apiName);
  };
}

export default ListingPageController;
