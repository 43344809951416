import { TypeApiRoute } from '../models/type.api.request';
import { TypeOrganization } from '../models/organization.model';

type TypeRequest = TypeOrganization;

type TypeResponse = {};

export const updateOrganization: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/organizations/${request.organizationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
