import styled from 'styled-components';
import { LG } from '@zendeskgarden/react-typography';
import { Button } from '@zendeskgarden/react-buttons';

import { ApplicationStatus } from '../../../../api/models/applications.model';

export const StyledStatus = styled.span<{ status?: ApplicationStatus }>`
  ${({ theme: { colors: c }, status }) => {
    if (!status) {
      return `color: ${c.generalBlack};`;
    }

    if (status === 'SENT') {
      return `color: ${c.accentOrange};`;
    }

    if (status === 'ACCEPTED') {
      return `color: ${c.accentGreen};`;
    }

    if (status === 'DECLINED') {
      return `color: ${c.accentPink};`;
    }

    if (status === 'CANCELLED') {
      return `color: ${c.secondaryDarkGray};`;
    }
  }}
`;

export const StyledRoleButton = styled(Button)`
  border: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  height: auto;
  color: inherit;
`;

export const StyledUserName = styled(LG)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
