import { isMobile } from 'react-device-detect';

import { TypeUserStatusLabels } from '../../../../../../../../api/models/user.model';
import { AppCard } from '../../../../../../components';
import EAgroidRoute from '../../../../../../routes/AgroidRoute.enum';
import { AccessRuleUserActionName, AccessRuleVisibility } from '../../../../../../types';
import { InvitesModalContainer } from '../../../../../common/containers/ListingPage/InvitesModalContainer';
import { createListingPage } from '../../../../../common/utils/helpers/configs/pages/createListingPage';

const scoutingContractorInvitesListingPage = createListingPage({
  link: {
    label: 'Приглашения',
    path: EAgroidRoute.ScoutingContractorInvites,
  },
  header: {
    title: 'Приглашения',
    filter: {
      filterBy: {
        searchQuery: {
          key: 'fullname',
          required: true,
          noSearchedDataText: 'Вы еще никого не пригласили в свою организацию',
        },
        status: {
          key: 'status',
          optionList: TypeUserStatusLabels,
        },
        role: {
          key: 'roleId',
        },
      },
    },
    actionButtons: [
      {
        type: 'button',
        caption: isMobile ? false : 'Добавить',
        componentProps: { isPrimary: true, isBasic: false },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.manageInvites,
      },
    ],
  },
  dataList: {
    noDataText: 'Вы еще никого не пригласили в свою организацию',
    item: {
      Component: AppCard,
      onClick: app => {
        if (!app.invitationId) {
          return;
        }

        sessionStorage.setItem('selectedInvitationId', app.invitationId);
      },
      isNeededCustomComponent: true,
    },
  },
  handlerList: {
    fetchItemList: {
      apiName: 'getOrganizationApplications',
      paramsAsPayload: {
        organizationId: 'contractorId',
      },
    },
  },
  customData: {
    CustomComponent: InvitesModalContainer,
  },
});

export default scoutingContractorInvitesListingPage;
