import SimpleSchema from 'simpl-schema';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

import { OrgSchema } from '../NewOrgModal/schema';
import { InputField } from '../uniforms';

export const OrgBigSchema = OrgSchema.extend({
  OKPO: {
    type: String,
    optional: true,
    min: 8,
    max: 8,
    label: 'ОКПО',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите данные',
      regexonchange: /(^$)|(^\d+$)/i,
    },
  },
  email: {
    type: String,
    optional: true,
    regEx: SimpleSchema.RegEx.Email,
    label: 'E-mail',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите E-mail',
    },
  },
  phone: {
    type: String,
    optional: true,
    label: ' Телефон',
    regEx: SimpleSchema.RegEx.Phone,
    uniforms: {
      component: InputField,
      placeholder: 'Укажите телефон',
    },
  },
  contact: {
    type: String,
    optional: true,
    max: 200,
    label: ' Представитель',
    uniforms: {
      component: InputField,
      placeholder: 'Укажите должность',
    },
  },
});

export const bridge = new SimpleSchema2Bridge(OrgBigSchema);
