import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../utils';
import { CardsList, ServiceCard } from '../../components';
import { ServicesStore } from '../../stores/services.store';
// import { baseServicesList } from './baseServicesList';
import { ReactComponent as TitleAssistant } from '../../../../assets/images/agroid/title-Da.assistant.svg';
import { ReactComponent as LogoAssistant } from '../../../../assets/images/agroid/logo-Assistance.svg';
import { ReactComponent as TitleFarmlink } from '../../../../assets/images/agroid/title-farmlink.svg';
import { ReactComponent as LogoFarmlink } from '../../../../assets/images/agroid/logo-FL.svg';
import { ReactComponent as TitleAgroscauting } from '../../../../assets/images/agroid/title-agroscauting.svg';
import { ReactComponent as LogoAgroscauting } from '../../../../assets/images/agroid/logo-Agroscouting.svg';
import { ReactComponent as TitleAgrosignal } from '../../../../assets/images/agroid/title-agrosignal.svg';
import { ReactComponent as LogoAgrosignal } from '../../../../assets/images/agroid/logoAgrosignal.svg';

export const ServicesList: FC = observer(() => {
  const { services, redirectToService } = useStore(ServicesStore);

  const { orgId } = useParams<{ orgId: string }>();

  let serList = [
    'https://assistance.stage.digitalagro.dev/',
    'https://farmlink.stage.digitalagro.dev/',
    'https://agroscouting.digitalagro.ru/?utm_source=referral&utm_medium=agroID&utm_campaign=WebSiteClick&roistat=referral_agroID_WebSite',
    'https://agrosignal.com/?utm_source=referral&utm_medium=agroID&utm_campaign=WebSiteClick&roistat=referral_agroID_WebSiteClick',
  ];

  if (window && window['_env_'] && window['_env_'].SERVICES_LIST) {
    serList = window['_env_'].SERVICES_LIST.split(' ');
  }

  const baseServicesList = [
    // todo руты надо будет перенести в конфиг
    {
      id: 'DA.ASSISTANT',
      name: 'DA.ASSISTANT',
      nameSvg: TitleAssistant,
      icon: LogoAssistant,
      text:
        'Бесплатный онлайн-сервис, который помогает в реальном времени следить за полями, выявлять проблемные зоны и рассчитать оптимальные окна для внесения удобрений',
      onClick: () => {
        redirectToService('assistant', serList[0], { organizationId: orgId || 'my' });
      },
    },
    {
      id: 'FARMLINK',
      name: 'FARMLINK',
      nameSvg: TitleFarmlink,
      icon: LogoFarmlink,
      text:
        'Точный прогноз погоды на полях, актуальные цены на культуры и последние новости агробизнеса — всё внутри единой бесплатной платформы',
      onClick: () => {
        redirectToService('farmlink', serList[1]);
      },
    },
    {
      id: 'АГРОСКАУТИНГ',
      name: 'АГРОСКАУТИНГ',
      nameSvg: TitleAgroscauting,
      icon: LogoAgroscauting,
      text:
        'Контроль качества технологических операций. Позволяет снизить потери из-за человеческого фактора и увеличить урожайность на 10-15%, не меняя технологию производства',
      pathname: serList[2],
    },
    {
      id: 'АГРОСИГНАЛ',
      name: 'АГРОСИГНАЛ',
      nameSvg: TitleAgrosignal,
      icon: LogoAgrosignal,
      text:
        'Система предназначена для повышения производительности, снижения потерь ГСМ и продукции. В режиме онлайн фиксирует и обрабатывает данные с множества датчиков',
      pathname: serList[3],
    },
  ];
  useEffect(() => {
    // fetchServices(); // TOOD must be called but now response error 500
  }, []);

  return (
    <CardsList list={[...baseServicesList, ...services]} card={ServiceCard} dataTestId="services" />
  );
});
