import styled from 'styled-components';
import { Nav, NavItem, Sidebar } from '@zendeskgarden/react-chrome';
import { Well } from '@zendeskgarden/react-notifications';

import BackArrow from '../../../../assets/images/agroid/arrow.svg';

export const SidebarStyled = styled(Sidebar)`
  border-right: none;
  position: fixed;
  z-index: 100;
  background-color: #f2f1f4;

  @media (max-width: 767px) {
    width: 100%;
    top: 64px;
    padding-bottom: 12px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% - 24px);
    top: 92px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 16px;
  }

  @media (min-width: 1024px) {
    top: 96px;
    width: 196px;
    padding-bottom: 0;
  }
`;

export const SidebarWell = styled(Well)`
  @media (max-width: 767px) {
    border-radius: 0;
    height: 52px;
    padding: 0;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    height: 64px;
    padding: 0;
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 116px);
    padding-bottom: 12px;
  }
`;

export const SidebarNav = styled(Nav).attrs({ isExpanded: true })<{ hasBack?: boolean }>`
  background: unset;
  color: unset;
  height: 100%;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;

    & {
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  ${({ hasBack }) =>
    hasBack
      ? `
    @media (max-width: 767px) {
      padding-left: 52px;
    }
  
    @media (min-width: 768px) {
      padding-left: 124px;
    }

    @media (min-width: 1024px) {
      padding-left: 0;
    }
  `
      : `
    @media (max-width: 1023px) {
      padding-top: 0;
      padding-left: 8px;
    }
  
    @media (min-width: 1024px) {
      padding-top: 16px;      
    }
  `}
`;

export const SidebarNavItem = styled(NavItem)<{ isBack?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  span {
    margin: 0;
    a {
      color: inherit;
      display: block;
      text-decoration: none;

      &.active {
        color: ${({ theme: { colors: c } }) => c.accentGreen};

        @media (min-width: 1024px) {
          background: ${({ theme: { colors: c } }) => c.secondaryGray};
        }
      }

      @media (max-width: 767px) {
        padding: 16px 12px;
      }

      @media (min-width: 768px) {
        padding: 22px;
      }

      @media (min-width: 1024px) {
        padding: 24px 24px 24px 27px;
      }
    }
  }

  ${({ isBack, theme }) =>
    isBack
      ? `
      color: ${theme.Colors.secondaryDark};
      position: fixed;
      left: 0;
      background: #fff;

      &:hover, &:focus {
        color: ${theme.Colors.secondaryDark};
      }

      span {
        a {
          display: flex;

          @media (max-width: 767px) {
            padding: 20px;
            line-height: 100%;
          }

          @media (min-width: 768px) {
            padding: 22px 28px 22px 25px;
          }
    
          @media (min-width: 1024px) {
            padding: 32px 0 16px 25px;
          }

          ::after {
            font-size: 14px;
            line-height: 14px;
            padding-top: 3px;
            padding-bottom: 3px;

            @media (max-width: 767px) {
              content: '';
            }

            @media (min-width: 768px) {
              content: 'Назад';
            }

            @media (min-width: 1024px) {
              content: 'Вернуться назад';
            }
            
          }
  
          ::before {
            content: url(${BackArrow});
            width: 12px;
            height: 12px;
            margin-right: 4px;
            margin-top: 2px;

            @media (max-width: 767px) {
              margin-right: 0;
              margin-top: 0;
            }
          }

        }
      }

      @media (max-width: 767px) {        
        border-right: none;
      }

      @media (min-width: 768px) {
        border-right: 1px solid ${theme.Colors.borderDefault};
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      @media (min-width: 1024px) {
        border-bottom: 1px solid ${theme.Colors.borderDefault};
        position: relative;
        top: 0;
        left: 0;        
        border-right: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 8px;
      }      
    `
      : ''}

  [data-garden-id='buttons.button'] {
    padding-left: ${({ theme: { space: s } }) => s.sm};
    padding-right: ${({ theme: { space: s } }) => s.sm};
  }

  &[data-garden-id='chrome.brandmark_nav_item'] {
    min-height: auto;
    opacity: 1;

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 768px) {
      margin: 0 24px 0 auto;
    }

    @media (min-width: 1024px) {
      margin: auto 0 0 0;
      border-top: 0.5px solid rgba(154, 160, 173, 0.3);
      padding-top: 16px;
    }
  }

  &:hover,
  &:focus {
    background: transparent;
    color: ${({ theme: { colors: c } }) => c.hoverGreen};
  }
`;

export const InvitesCount = styled.div`
  float: right;
  background: ${({ theme: { colors: c } }) => c.accentGreen};
  font-size: 12px;
  border-radius: 12px;
  color: ${({ theme: { colors: c } }) => c.generalWhite};
  padding: 2px 6px;
  min-width: 22px;
  text-align: center;
  margin-right: 4px;
  margin-left: 12px;
  text-overflow: clip;
`;
