import { Button } from '@farmlink/farmik-ui';
import { useEffect, useRef, useState } from 'react';

import { toBase64 } from '../../../../utils/getBase64';
import BinSvg from '../assets/images/bin.svg';

import {
  ActionsButtonsWrapper,
  AvatarImg,
  AvatarImgWrapper,
  CancelButtonWrapper,
  Content,
  DeleteButton,
  DialogTitle,
  Disclaimer,
  ErrorMessage,
  FileNameWrapper,
  FileUploader,
  MsgWrapper,
  SaveButtonWrapper,
  StyledFileNameLabel,
  StyledModalComponent,
  UploadAvatarButtonWrapper,
} from './style';
import LoadImgPlaceholder from './loadImgPlaceholder.svg';

const CommitedFileTypes = ['image/jpeg', 'image/png'];

export const ImageUploadDialog = ({
  imgUrl,
  fileName,
  onClose,
  onUpload,
  onCommitImage,
  initialAvatarPreview,
  onLogoReset,
}) => {
  const handleUploadAvatarImage = event => {
    console.log('handleUploadAvatarImage');
    event.stopPropagation();
    if (fileUploaderRef.current) {
      fileUploaderRef.current.click();
    }
  };

  const [file, setFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  const [avatarPreview, setAvatarPreview] = useState('');
  const [initialDialogAvatarPreview, setIntialDialogAvatarPreview] = useState(initialAvatarPreview);
  const [dialogImgUrl, setDialogImgUrl] = useState(imgUrl);

  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);

  const fileUploaderRef = useRef(null);
  const avatarImgRef = useRef(null);

  useEffect(() => {
    return () => {
      setErrorMsg('');
      setFile(null);
      setAvatarPreview('');
      setBtnSaveDisabled(false);
    };
  }, []);

  const onChangeFile = async event => {
    event.stopPropagation();
    setBtnSaveDisabled(true);
    console.log(event.target.files[0]);
    if (event.target.files.length === 1) {
      const fileForUpload: File = event.target.files[0];
      if (fileForUpload.size >= 1e7) {
        setErrorMsg('Файл слишком большой, замените');
      } else if (!CommitedFileTypes.includes(fileForUpload.type)) {
        setErrorMsg('Можно загружать файлы формата JPEG и PNG');
      } else {
        setFile(fileForUpload);
        setErrorMsg('');
        const link = await toBase64(fileForUpload);
        console.log(link);
        setAvatarPreview(link as string);
        console.log(event.target.files[0]);
      }
    }
  };

  const handleCommmitAvatarImage = async () => {
    if (file) {
      const link = await toBase64(file);
      onCommitImage(link);
    } else {
      onCommitImage('');
      onLogoReset();
    }
  };

  const handleImgDisplay = async () => {
    console.log('handleImgLoad');
    console.log(file);
    if (file) {
      const imgEl = avatarImgRef.current;
      if (Boolean(file) && (imgEl.naturalHeight < 225 || imgEl.naturalWidth < 225)) {
        console.log('Файл изображения должен быть размером не менее 225x225 px');
        setErrorMsg('Файл изображения должен быть размером не менее 225x225 px');
        setBtnSaveDisabled(true);
      } else {
        await onUpload(file, avatarPreview);
        setBtnSaveDisabled(false);
      }
    }
  };

  const onDeleteSelectedImage = () => {
    setFile(null);
    setAvatarPreview('');
    setIntialDialogAvatarPreview('');
    setDialogImgUrl('');
    setBtnSaveDisabled(false);
  };

  return (
    <StyledModalComponent onClose={onClose} data-test-id="image-upload-dialog">
      <DialogTitle data-test-id="image-upload-dialog-title">Ваше фото</DialogTitle>
      <Content data-test-id="image-upload-dialog-content">
        <Disclaimer data-test-id="image-upload-dialog-disclaimer">
          Рекомендуется загружать файлы форматом PNG или JPEG, размером не менее 225х225 px, и
          объемом не более 10 мб
        </Disclaimer>
        <AvatarImgWrapper data-test-id="image-upload-dialog-avatar-img-wrapper">
          <AvatarImg
            ref={avatarImgRef}
            src={avatarPreview || initialDialogAvatarPreview || dialogImgUrl || LoadImgPlaceholder}
            onLoad={handleImgDisplay}
            data-test-id="image-upload-dialog-avatar-img"
          />
        </AvatarImgWrapper>
        <UploadAvatarButtonWrapper data-test-id="image-upload-dialog-upload-avatar-button-wrapper">
          <Button
            onClick={handleUploadAvatarImage}
            type={'button'}
            color={'primary'}
            dataTestId="image-upload-dialog-upload-avatar-button"
          >
            Загрузить изображение
          </Button>
        </UploadAvatarButtonWrapper>
        <MsgWrapper data-test-id="image-upload-dialog-msg-wrapper">
          {errorMsg ? (
            <ErrorMessage data-test-id="image-upload-dialog-error-message">{errorMsg}</ErrorMessage>
          ) : file?.name || fileName ? (
            <FileNameWrapper data-test-id="image-upload-dialog-file-name-wrapper">
              <StyledFileNameLabel data-test-id="image-upload-dialog-file-name-label">
                {file?.name || fileName}
              </StyledFileNameLabel>
              <DeleteButton
                src={BinSvg}
                onClick={onDeleteSelectedImage}
                data-test-id="image-upload-dialog-delete-button"
              />
            </FileNameWrapper>
          ) : null}
        </MsgWrapper>
      </Content>

      <ActionsButtonsWrapper data-test-id="image-upload-dialog-actions-buttons-wrapper">
        <CancelButtonWrapper data-test-id="image-upload-dialog-cancel-button-wrapper">
          <Button
            onClick={onClose}
            type={'button'}
            color={'default'}
            dataTestId="image-upload-dialog-cancel-button"
          >
            Отменить
          </Button>
        </CancelButtonWrapper>
        <SaveButtonWrapper data-test-id="image-upload-dialog-save-button-wrapper">
          <Button
            onClick={handleCommmitAvatarImage}
            type={'button'}
            color={'primary'}
            disabled={btnSaveDisabled}
            dataTestId="image-upload-dialog-save-button"
          >
            Сохранить
          </Button>
        </SaveButtonWrapper>
      </ActionsButtonsWrapper>
      <FileUploader
        type="file"
        name="file"
        ref={fileUploaderRef}
        accept="image/jpeg,image/png,image/jpg"
        onChange={onChangeFile}
        onClick={event => event.stopPropagation()}
        data-test-id="image-upload-dialog-file-uploader"
      />
    </StyledModalComponent>
  );
};
