import { FC, HTMLAttributes } from 'react';

export interface OrgLogoProps extends HTMLAttributes<HTMLDivElement> {
  downloadUrl: string;
  fileName: string;
  dataTestId?: string;
}

export const OrgLogo: FC<OrgLogoProps> = ({ downloadUrl, fileName, dataTestId = '' }) => {
  return <img src={downloadUrl} alt={fileName} data-test-id={dataTestId} />;
};
