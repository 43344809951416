import { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { isMobileOnly } from 'react-device-detect';
import { ModelTransformMode } from 'uniforms';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Anchor } from '@zendeskgarden/react-buttons';
import { isEqual } from 'lodash';

import { useStore, isFormValuesErrors } from '../../../../../../utils';
import { ScoutsStore, ConfirmStore } from '../../../../stores';
import { InitialValues } from '../../../../../../api/models/contractor.model';
import { ReactComponent as Alert } from '../../../../../../assets/images/allert.svg';
import { WellHeader } from '../../../../components';
import { AvatarUploader } from '../../../../components/AvatarUploader/AvatarUploader';
import { MergedInputGroup } from '../../../../components/MergedInputGroup/MergedInputGroup';

import { bridge as schema } from './schema';
import {
  AddressFactWrapper,
  AddressJurWrapper,
  ContactWrapper,
  PositionWrapper,
  EmailWrapper,
  InnWrapper,
  KPPWrapper,
  LogoRequisitesWrapper,
  LogoWrapper,
  OGRNWrapper,
  OKPOWrapper,
  OKVEDWrapper,
  OrgNameWrapper,
  OrgTypeWrapper,
  PhoneWrapper,
  RequisitesWrapper,
  NewOwnerWrapper,
  ScoutWrapper,
  ErrorsFieldWrapper,
  ErrorValueField,
  WellStyled,
  WellBodyStyled,
  TooltipModalStyled,
  ButtonStyledSubmit,
  ButtonStyledCancel,
} from './styled';

interface Option {
  label: string;
  value: string;
}

interface Props {
  title: string;
  values: InitialValues;
  orgTypesOptions: Option[];
  scoutsOptions: Option[];
  onSubmit: (values: InitialValues) => void;
  onCancel: () => void;
  buttonSubmitTitle?: string;
  buttonCancelTitle?: string;
  type?: 'create' | 'edit';
  dataTestId: string;
}

type ModelProps = {
  [key: string]: any;
};

export const ContractorForm: FC<Props> = observer(
  ({
    title,
    values,
    orgTypesOptions,
    scoutsOptions,
    onSubmit,
    onCancel,
    buttonSubmitTitle = 'Сохранить данные',
    buttonCancelTitle = 'Отменить',
    type = 'create',
    dataTestId,
  }) => {
    const {
      uploadFile,
      onCommitLogo,
      onResetLogo,
      formValuesErrors,
      logoId,
      logoUrl,
      logoFileName,
      scoutApplications,
    } = useStore(ScoutsStore);

    const { setIsChanged } = useStore(ConfirmStore);

    const newOwnerRef = useRef<HTMLDivElement>(null);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    const handleModelTransform = (mode: ModelTransformMode, model: ModelProps) => {
      const resultModel = {
        ...model,
        logo: {
          ...model.logo,
          downloadUrl: logoUrl,
          fileName: logoFileName,
          id: logoId,
        },
      };

      setIsChanged(!isEqual(values, resultModel));

      return resultModel;
    };

    const pending = false; // Статус для заглушки модалки владельца организации

    return (
      <AutoForm
        schema={schema}
        model={values}
        onSubmit={onSubmit}
        modelTransform={handleModelTransform}
        validate="onChange"
      >
        <WellStyled>
          <WellHeader title={title} dataTestId={dataTestId} />
          <WellBodyStyled data-test-id={`${dataTestId}-form`}>
            <LogoRequisitesWrapper data-test-id={`${dataTestId}-form-logo-wrapper`}>
              <LogoWrapper className="logo-wrapper">
                <AvatarUploader
                  icon={logoUrl}
                  fileName={logoFileName}
                  onUpload={uploadFile}
                  onCommitImg={() => {
                    onCommitLogo();
                  }}
                  onLogoReset={onResetLogo}
                />
              </LogoWrapper>

              <RequisitesWrapper data-test-id={`${dataTestId}-form-wrapper`}>
                <OrgNameWrapper data-test-id={`${dataTestId}-form-org-name-wrapper`}>
                  <MergedInputGroup label="Название организации" required validateField="name">
                    <OrgTypeWrapper>
                      <AutoField
                        name="orgTypeId"
                        options={orgTypesOptions}
                        label={false}
                        isInGroupLeft={true}
                      />
                    </OrgTypeWrapper>
                    <AutoField name="name" label={false} />
                  </MergedInputGroup>
                  <ErrorsFieldWrapper data-test-id={`${dataTestId}-form-org-name-error`}>
                    <ErrorField name="orgTypeId" />
                    <ErrorField name="name" />
                  </ErrorsFieldWrapper>
                </OrgNameWrapper>

                <InnWrapper data-test-id={`${dataTestId}-form-inn`}>
                  <AutoField name="INN" />
                  <ErrorField name="INN" />
                  {isFormValuesErrors('INN', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.INN}</ErrorValueField>
                  )}
                </InnWrapper>
                <AddressJurWrapper data-test-id={`${dataTestId}-form-addressJur`}>
                  <AutoField name="addressJur" />
                  <ErrorField name="addressJur" />
                </AddressJurWrapper>
                <AddressFactWrapper data-test-id={`${dataTestId}-form-addressFact`}>
                  <AutoField name="addressFact" />
                  <ErrorField name="addressFact" />
                </AddressFactWrapper>
                <OGRNWrapper data-test-id={`${dataTestId}-form-OGRN`}>
                  <AutoField name="OGRN" />
                  <ErrorField name="OGRN" />
                  {isFormValuesErrors('OGRN', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.OGRN}</ErrorValueField>
                  )}
                </OGRNWrapper>
                <OKVEDWrapper data-test-id={`${dataTestId}-form-OKVED`}>
                  <AutoField name="OKVED" />
                  <ErrorField name="OKVED" />
                  {isFormValuesErrors('OKVED', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.OKVED}</ErrorValueField>
                  )}
                </OKVEDWrapper>
                <OKPOWrapper data-test-id={`${dataTestId}-form-OKPO`}>
                  <AutoField name="OKPO" />
                  <ErrorField name="OKPO" />
                  {isFormValuesErrors('OKPO', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.OKPO}</ErrorValueField>
                  )}
                </OKPOWrapper>
                <KPPWrapper data-test-id={`${dataTestId}-form-KPP`}>
                  <AutoField name="KPP" />
                  <ErrorField name="KPP" />
                  {isFormValuesErrors('KPP', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.KPP}</ErrorValueField>
                  )}
                </KPPWrapper>
                <ContactWrapper data-test-id={`${dataTestId}-form-contact`}>
                  <AutoField name="contact" />
                  <ErrorField name="contact" />
                  {isFormValuesErrors('contact', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.contact}</ErrorValueField>
                  )}
                </ContactWrapper>
                <PositionWrapper data-test-id={`${dataTestId}-form-representativePosition`}>
                  <AutoField name="representativePosition" />
                  <ErrorField name="representativePosition" />
                </PositionWrapper>
                <EmailWrapper data-test-id={`${dataTestId}-form-email`}>
                  <AutoField name="email" />
                  <ErrorField name="email" />
                  {isFormValuesErrors('email', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.email}</ErrorValueField>
                  )}
                </EmailWrapper>
                <PhoneWrapper data-test-id={`${dataTestId}-form-phone`}>
                  <AutoField name="phone" />
                  <ErrorField name="phone" />
                  {isFormValuesErrors('phoneNumber', formValuesErrors) && (
                    <ErrorValueField>{formValuesErrors.phoneNumber}</ErrorValueField>
                  )}
                </PhoneWrapper>

                <ScoutWrapper data-test-id={`${dataTestId}-form-scout`}>
                  <AutoField name="isScout" disabled={type === 'create'} />
                  <ErrorField name="isScout" />
                </ScoutWrapper>
              </RequisitesWrapper>
            </LogoRequisitesWrapper>
          </WellBodyStyled>
        </WellStyled>

        <WellStyled>
          <WellHeader title="Владелец организации" dataTestId={`${dataTestId}-new-owner`} />
          <WellBodyStyled data-test-id={`${dataTestId}-new-owner-body`} className="new-owner">
            {pending && (
              <TooltipModalStyled
                referenceElement={referenceElement}
                onClose={() => setReferenceElement(null)}
                placement="top-end"
              >
                <TooltipModalStyled.Body>
                  <p>
                    Владелец организации сменится после того как примет приглашение в организацию
                  </p>
                  <Anchor
                    href="#"
                    data-test-id="new-owner-tooltip-action"
                    onClick={() => console.log('Отправить еще раз')}
                  >
                    Отправить еще раз
                  </Anchor>
                </TooltipModalStyled.Body>
              </TooltipModalStyled>
            )}

            <NewOwnerWrapper
              ref={newOwnerRef}
              onClick={() => pending && setReferenceElement(newOwnerRef.current)}
              data-test-id={`${dataTestId}-form-new-owner`}
            >
              <AutoField name="newOwner" end={pending && Alert} />
              <ErrorField name="newOwner" />
              {isFormValuesErrors('newOwner', formValuesErrors) && (
                <ErrorsFieldWrapper>{formValuesErrors.newOwner}</ErrorsFieldWrapper>
              )}
            </NewOwnerWrapper>
          </WellBodyStyled>
        </WellStyled>

        <WellStyled>
          <WellHeader title="Ответственный" dataTestId={`${dataTestId}-manager`} />
          <WellBodyStyled data-test-id={`${dataTestId}-form-manager`} className="manager">
            <AutoField name="manager" options={scoutsOptions} />
            <ErrorField name="manager" />
          </WellBodyStyled>
        </WellStyled>

        <Grid columns={12} style={{ marginTop: '12px' }}>
          <Row>
            {isMobileOnly && (
              <Col xs={6}>
                <ButtonStyledCancel
                  type="button"
                  isStretched
                  isBasic
                  size="large"
                  onClick={onCancel}
                  data-test-id={`${dataTestId}-form-cancel`}
                >
                  {!isMobileOnly ? buttonCancelTitle : 'Отменить'}
                </ButtonStyledCancel>
              </Col>
            )}
            <Col xs={6}>
              <ButtonStyledSubmit
                type="submit"
                isStretched
                isPrimary
                size="large"
                data-test-id={`${dataTestId}-form-save`}
              >
                {buttonSubmitTitle}
              </ButtonStyledSubmit>
            </Col>
          </Row>
        </Grid>
      </AutoForm>
    );
  }
);
