import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemText } from '@zendeskgarden/react-chrome';

import { ISidebarMenuItem } from '../../components';
import { AgroidRoutes } from '../../routes';

export const useContractorsCreateMenu = () => {
  const menuItems: ISidebarMenuItem[] = useMemo(
    () => [
      {
        caption: (
          <NavItemText>
            <NavLink to={AgroidRoutes.Contractors} isActive={() => false}></NavLink>
          </NavItemText>
        ),
        isBack: true,
        slug: 'orgs-back',
      },
      {
        caption: 'Контрагенты',
        slug: 'contractor-create',
        link: AgroidRoutes.ContractorCreate,
      },
    ],
    []
  );

  return menuItems;
};
