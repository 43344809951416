import styled, { StyledComponentBase } from 'styled-components';
import { Link } from 'react-router-dom';
import { Anchor } from '@zendeskgarden/react-buttons';
import { LG } from '@zendeskgarden/react-typography';

interface IRoleCardStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Text?: any;
  Link?: any;
  LinkWrapper?: any;
}

export const DelAnchor = styled(Anchor)`
  border: 1px solid ${({ theme: { colors: c } }) => c.generalWhite};
  border-radius: ${({ theme: { borderRadii: b } }) => b.label};
  float: right;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme: { colors: c } }) => c.borderGray};

  svg {
    margin-right: 0;
    path {
      fill: ${({ theme: { colors: c } }) => c.accentPink};
    }
  }

  &:hover {
    color: ${({ theme: { colors: c } }) => c.borderGray} !important;

    svg path {
      fill: ${({ theme: { colors: c } }) => c.hoverPink} !important;
    }
  }
`;

export const RoleCardStyled: IRoleCardStyled = styled.div`
  @media (max-width: 767px) {
    padding: 12px 16px 20px 16px;
  }

  @media (min-width: 768px) {
    padding: 20px 24px;
  }
`;

RoleCardStyled.Title = styled(LG).attrs({ tag: 'h2' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    margin-bottom: 4px;
  }

  @media (min-width: 768px) {
    margin-bottom: 8px;
  }
`;

RoleCardStyled.Text = styled.p`
  color: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
`;

RoleCardStyled.Link = styled(Link)`
  text-decoration: underline;
`;

RoleCardStyled.LinkWrapper = styled.div`
  height: 16px;
`;
