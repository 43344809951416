import React, { FC, HTMLAttributes } from 'react';
import { trim, toString } from 'lodash';

import { ReactComponent as NoLogo } from '../../../../assets/images/agroid/logo-template.svg';
import { AgroidRoutes } from '../../routes';
import { OrgLogo, TypeUserRoleEnumString } from '../';

import { OrgLogoWrapper, OrgCardStyled, LogoOrgNameWrapper } from './styled';

export interface OrgCardProps extends HTMLAttributes<HTMLDivElement> {
  organizationId: string;
  name: string;
  employeeRole: string;
  logo?: any;
  role: any;
  type?: {
    orgTypeId?: number;
    OrgType?: string;
  };
}

const trimName = str => {
  if (str.length <= 50) {
    return str;
  }
  return `${str.slice(0, str.indexOf(' ', 30))}...`;
};

export const OrgCard: FC<OrgCardProps> = props => {
  const { organizationId, name, employeeRole, logo, role, type: { OrgType } = {} } = props;

  return (
    <OrgCardStyled.Link to={`${AgroidRoutes.Organizations}/${organizationId}`}>
      <OrgCardStyled key={organizationId}>
        <LogoOrgNameWrapper>
          {logo ? (
            <OrgLogoWrapper>
              <OrgLogo {...logo} />
            </OrgLogoWrapper>
          ) : (
            <NoLogo className="logo" style={{ margin: '0' }} />
          )}
          <OrgCardStyled.Title>
            {trimName(trim(`${toString(OrgType)} ${name}`))}
          </OrgCardStyled.Title>
        </LogoOrgNameWrapper>
        <OrgCardStyled.Text>
          {
            // todo Когда старые роли исчезнуть оставить role?.name !!!!!!!
            role?.name || TypeUserRoleEnumString[employeeRole]
          }
        </OrgCardStyled.Text>
      </OrgCardStyled>
    </OrgCardStyled.Link>
  );
};
