import { isEmpty } from 'lodash';

import { TypeContractor, InitialValues } from '../../../../../api/models/contractor.model';

function getValuesEdit(contractor: TypeContractor, isScout: boolean): InitialValues {
  return {
    orgTypeId: !isEmpty(contractor.type) ? contractor.type.orgTypeId : 1,
    logo: {
      downloadUrl: contractor.logo ? contractor.logo?.downloadUrl : '',
      fileName: contractor.logo ? contractor.logo.fileName : '',
      id: contractor.logo ? contractor.logo.id : '',
    },
    name: contractor.name || '',
    INN: contractor.INN || '',
    addressJur: !isEmpty(contractor.addressJur) ? contractor.addressJur.addressfull : '',
    addressFact: !isEmpty(contractor.addressFact) ? contractor.addressFact.addressfull : '',
    OGRN: contractor.OGRN || '',
    OKVED: contractor.OKVED || '',
    OKPO: contractor.OKPO || '',
    KPP: contractor.KPP || '',
    contact: contractor.contact || '',
    manager: !isEmpty(contractor.manager) ? contractor.manager.id : '',
    email: contractor.email || '',
    phone: contractor.phone || '',
    newOwner: contractor.newOwner || '',
    representativePosition: contractor.representativePosition || '',
    isScout: isScout || contractor.isScout,
  };
}

export { getValuesEdit };
