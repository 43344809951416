import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Dots } from '@zendeskgarden/react-loaders';
import { Input, Select } from '@farmlink/farmik-ui';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Anchor } from '@zendeskgarden/react-buttons';

import { UserInfoType } from '../../../../api/models/user.model';
import { getPurePhone, TypeApiRequest, useStore } from '../../../../utils';
import { ProfileStore, NEED_ADDITIONAL_INFO } from '../../../auth/stores/profile.store';
import { Calendar } from '../uniforms/Calendar/Calendar';
import { AvatarUploader } from '../AvatarUploader/AvatarUploader';
import { ProfileModal } from '../ProfileModal/ProfileModal';
import { DialogModal } from '../DialogModal/DialogModal';

import {
  GridStyled,
  AvatarBlock,
  BlockedEmailInput,
  BlockedPhoneInput,
  DateOfBirthWrapper,
  EmailWrapper,
  FieldsBlock,
  FirstNameWrapper,
  GenderSelectWrapper,
  LastNameWrapper,
  PhoneInputWrapper,
  ProfileFormWrapper,
  ResetPasswordWrapper,
  SecondNameWrapper,
  StyledResetPasswordButton768,
} from './style';
import { ChangePhoneNumberWizard } from './components/ChangePhoneNumberWizard/ChangePhoneNumberWizard';
import { ChangeEmailWizard } from './components/ChangeEmailWizard/ChangeEmailWizard';

type CountryCode = '+7' | '+380';

export interface ProfileFormProps extends HTMLAttributes<HTMLDivElement> {
  onShowAction?: any;
}

export const genderOptions = [
  {
    label: 'Мужской',
    value: 'MALE',
  },
  {
    label: 'Женский',
    value: 'FEMALE',
  },
];

const ErrorMessage: FC<{ email: string; message: string }> = observer(({ email, message }) => {
  const [sendStatus, setSentStatus] = useState('');
  const { confirmEmail } = useStore(ProfileStore);

  const handleClick = event => {
    event.preventDefault();
    confirmEmail(email);
    setSentStatus(' Отправлено');
  };

  return (
    <div>
      <div>{message}</div>
      <p>&nbsp;</p>
      <p>
        <Anchor href="#" className="tooltip-action" onClick={handleClick}>
          Отправить еще раз
        </Anchor>
        {sendStatus}
      </p>
    </div>
  );
});

const message =
  'Необходимо подтвердить свой электронный адрес. На указанный адрес отправлено письмо.';

export const ProfileForm: FC<ProfileFormProps> = observer(({ onShowAction }) => {
  const {
    fetchProfile,
    user,
    userForProfileEdit,
    onAvatarReset,
    setUserForProfileEdit,
    getPendingContracts,
  } = useStore(ProfileStore);
  const profileStore = useStore(ProfileStore);

  // const [userProfile, setUserProfile] = useState<UserInfoType | null>(null);

  const [showPhoneEditForm, setShowPhoneEditForm] = useState(false);
  // const [changePhoneNumberModal, setChangePhoneNumberModal] = useState(false);

  const [showEmailEditForm, setShowEmailEditForm] = useState(false);

  const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false);
  const [showResetPasswordError, setShowResetPasswordError] = useState(false);
  const [pendings, setPendings] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const contracts = await getPendingContracts();
        if (contracts && contracts.emails && contracts.emails.length > 0) {
          setPendings(contracts.emails[0]);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    fetchProfile()
      .then(() => {
        if (user) {
          setUserForProfileEdit(user);
        }
      })
      .catch(e => {
        if (e.message === NEED_ADDITIONAL_INFO) {
          document.dispatchEvent(new Event('logout'));
        }
      });
  }, []);

  useEffect(() => {
    if (user) {
      setUserForProfileEdit(user);
    }
  }, [user]);

  const handleFieldChange = fieldName => {
    return value => {
      onShowAction();
      setUserForProfileEdit({
        ...userForProfileEdit,
        [fieldName]: value,
      });
    };
  };

  const handleFirstNameChange = handleFieldChange('firstName');
  const handleLastNameChange = handleFieldChange('lastName');
  const handleSecondNameChange = handleFieldChange('secondName');
  const handleGenderChange = handleFieldChange('gender');
  const handlePhoneChange = () => {
    console.log();
  };

  const handleDateOfBirthChange = (value: moment.Moment) => {
    onShowAction();
    setUserForProfileEdit({
      ...userForProfileEdit,
      dateOfBirth: value.format('DD.MM.YYYY'),
    });
  };

  const handleResetPasswordClick = () => {
    profileStore
      .resetUserPassword()
      .then(() => {
        setShowResetPasswordSuccess(true);
      })
      .catch(err => {
        console.error(err);
        setShowResetPasswordError(true);
      });
  };

  useEffect(() => {
    console.log(profileStore.user);
  }, [profileStore.user]);

  const onCommitPhone = (countryCode: string, phoneNumber: string) => {
    onShowAction();
    setUserForProfileEdit({
      ...userForProfileEdit,
      phone: {
        ...userForProfileEdit.phone,
        phoneNumberCode: countryCode,
        phoneNumber: getPurePhone(phoneNumber, countryCode),
      },
    });
  };

  const checkSmsCode = (data: TypeApiRequest<'verifySmsCode'>) =>
    profileStore.checkSMSCode(data).then(res => {
      onShowAction();
      setUserForProfileEdit({
        ...userForProfileEdit,
        phone: {
          phoneNumber: data.phoneNumber,
          phoneNumberCode: data.countryCode,
          phoneNumberStatus: {
            statusId: 'CONFIRMED',
            statusName: 'Подтвержден',
          },
        },
      } as UserInfoType);
      return res;
    });

  return (
    <GridStyled>
      <Row>
        <Col textAlign="center">
          {profileStore.isLoading ? (
            <Dots size={32} color="#259A42" />
          ) : (
            <ProfileFormWrapper data-test-id="profile-form-wrapper">
              <AvatarBlock data-test-id="profile-form-avatar-block">
                <AvatarUploader
                  icon={userForProfileEdit?.avatar?.downloadUrl}
                  fileName={userForProfileEdit?.avatar?.fileName}
                  onUpload={profileStore.uploadFile}
                  onCommitImg={() => {
                    onShowAction();
                    profileStore.onCommitAvatar();
                  }}
                  onLogoReset={() => {
                    onShowAction();
                    onAvatarReset();
                  }}
                />
              </AvatarBlock>
              <FieldsBlock className="fields-block-1024" data-test-id="profile-form-fields-block">
                <LastNameWrapper data-test-id="profile-form-last-name-wrapper">
                  <Input
                    label={'Фамилия'}
                    value={userForProfileEdit?.lastName ?? ''}
                    type="text"
                    onChange={handleLastNameChange}
                    placeholder="Не указана"
                    error={!Boolean(userForProfileEdit?.lastName) ? 'Введите фамилию' : ''}
                    maxLength={32}
                    required={true}
                    dataTestId="profile-form-last-name-input"
                  />
                </LastNameWrapper>
                <FirstNameWrapper data-test-id="profile-form-first-name-wrapper">
                  <Input
                    label={'Имя'}
                    value={userForProfileEdit?.firstName ?? ''}
                    type="text"
                    onChange={handleFirstNameChange}
                    placeholder="Не указано"
                    error={!Boolean(userForProfileEdit?.firstName) ? 'Введите имя' : ''}
                    maxLength={32}
                    required={true}
                    dataTestId="profile-form-first-name-input"
                  />
                </FirstNameWrapper>
                <SecondNameWrapper data-test-id="profile-form-second-name-wrapper">
                  <Input
                    label={'Отчество'}
                    value={userForProfileEdit?.secondName ?? ''}
                    type="text"
                    onChange={handleSecondNameChange}
                    placeholder="Не указано"
                    dataTestId="profile-form-second-name-input"
                  />
                </SecondNameWrapper>
                <GenderSelectWrapper data-test-id="profile-form-gender-select-wrapper">
                  <Select
                    label={'Пол'}
                    options={genderOptions}
                    placeholder={'Не указан'}
                    defaultValue={
                      genderOptions.find(
                        genderOption =>
                          genderOption.value ===
                          (typeof userForProfileEdit?.gender === 'object'
                            ? userForProfileEdit?.gender?.genderEnum
                            : userForProfileEdit?.gender)
                      ) ?? { value: null, label: null }
                    }
                    onChange={handleGenderChange}
                    dataTestId="profile-form-gender-select"
                    error={!Boolean(userForProfileEdit?.gender) ? 'Укажите пол' : ''}
                  />
                </GenderSelectWrapper>
                <DateOfBirthWrapper data-test-id="profile-form-date-of-birth-wrapper">
                  <Calendar
                    placeholder={'Не указана'}
                    label={'Дата рождения'}
                    onChange={handleDateOfBirthChange}
                    value={
                      userForProfileEdit?.dateOfBirth
                        ? moment(userForProfileEdit?.dateOfBirth, 'DD.MM.YYYY')
                        : undefined
                    }
                    maxDate={moment(new Date())}
                    error={userForProfileEdit?.dateOfBirth ? '' : 'Укажите дату'}
                    dataTestId="profile-form-date-of-birth-calendar"
                  />
                </DateOfBirthWrapper>
                <PhoneInputWrapper data-test-id="profile-form-phone-input-wrapper">
                  <BlockedPhoneInput
                    onChange={handlePhoneChange}
                    label="Номер телефона"
                    mask="999 999 99 99"
                    placeholder="Не указан"
                    defaultValue={userForProfileEdit?.phone?.phoneNumber ?? ''}
                    defaultCountryCode={userForProfileEdit?.phone?.phoneNumberCode as CountryCode}
                    disabled={true}
                    actionButton={{
                      label: 'Изменить',
                      onClick: () => setShowPhoneEditForm(true),
                    }}
                    dataTestId="profile-form-blocked-phone-input"
                  />
                </PhoneInputWrapper>
                <EmailWrapper data-test-id="profile-form-email-wrapper">
                  {!pendings ? (
                    <Tooltip
                      content={<ErrorMessage message={message} email={pendings} />}
                      placement={isMobile ? 'bottom' : 'end'}
                      size="large"
                      type="light"
                    >
                      <div>
                        <BlockedEmailInput
                          label={'Email'}
                          value={
                            pendings
                              ? pendings
                              : String(
                                  userForProfileEdit?.email?.email ||
                                    userForProfileEdit?.email ||
                                    ''
                                )
                          }
                          type="text"
                          disabled={true}
                          placeholder="Не указан"
                          actionButton={{
                            label: 'Изменить',
                            onClick: () => setShowEmailEditForm(true),
                          }}
                          error={pendings ? 'E-mail не подтвержден' : undefined}
                          endIcon={pendings ? 'warning_blob' : undefined}
                          dataTestId="profile-form-tooltip-blocked-email-input"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <BlockedEmailInput
                      label={'Email'}
                      value={String(
                        userForProfileEdit?.email?.email || userForProfileEdit?.email || ''
                      )}
                      type="text"
                      disabled={true}
                      placeholder="Не указан"
                      actionButton={{
                        label: 'Изменить',
                        onClick: () => setShowEmailEditForm(true),
                      }}
                      dataTestId="profile-form-blocked-email-input"
                    />
                  )}
                </EmailWrapper>
                <ResetPasswordWrapper data-test-id="profile-form-reset-password-wrapper">
                  <StyledResetPasswordButton768
                    color="secondary"
                    type="button"
                    onClick={handleResetPasswordClick}
                    disabled={!Boolean(userForProfileEdit?.email?.email)}
                    dataTestId="profile-form-reset-password-button"
                  >
                    Сбросить пароль
                  </StyledResetPasswordButton768>
                </ResetPasswordWrapper>
              </FieldsBlock>
              <ProfileModal
                shown={showEmailEditForm}
                hideModal={() => {
                  setShowEmailEditForm(false);
                }}
              >
                <ChangeEmailWizard
                  email={
                    pendings
                      ? pendings
                      : userForProfileEdit?.email
                      ? userForProfileEdit?.email.email
                      : ''
                  }
                  setChangeEmailModal={setShowEmailEditForm}
                  confirmEmail={profileStore.confirmEmail}
                  updateEmailInProfile={email => {
                    onShowAction();
                    setUserForProfileEdit({
                      ...userForProfileEdit,
                      email: {
                        ...userForProfileEdit.email,
                        email,
                      },
                    });
                  }}
                />
              </ProfileModal>
              <ProfileModal
                shown={showPhoneEditForm}
                hideModal={() => {
                  setShowPhoneEditForm(false);
                }}
              >
                <ChangePhoneNumberWizard
                  phoneNumberCode={
                    userForProfileEdit?.phone ? userForProfileEdit?.phone?.phoneNumberCode : '+7'
                  }
                  phoneNumber={userForProfileEdit?.phone?.phoneNumber}
                  setChangePhoneNumberModal={setShowPhoneEditForm}
                  confirmPhoneNumber={profileStore.confirmPhoneNumber}
                  checkSMSCode={checkSmsCode}
                  onCommitPhone={onCommitPhone}
                />
              </ProfileModal>
              {showResetPasswordSuccess ? (
                <DialogModal
                  title={'На ваш E-mail была отправлена ссылка на создание нового пароля'}
                  status={'success'}
                  accept={{
                    handler: () => setShowResetPasswordSuccess(false),
                    name: 'Хорошо',
                    color: 'primary',
                  }}
                />
              ) : null}
              {showResetPasswordError ? (
                <DialogModal
                  title={'Ошибка выполнения запроса на сброс пароля'}
                  status={'warning'}
                  accept={{
                    handler: () => setShowResetPasswordError(false),
                    name: 'Закрыть',
                    color: 'primary',
                  }}
                />
              ) : null}
            </ProfileFormWrapper>
          )}
        </Col>
      </Row>
    </GridStyled>
  );
});
