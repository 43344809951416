import { FC } from 'react';
// eslint-disable-next-line import/order
import Datetime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import moment, { Moment } from 'moment';

import { DateInput, Icon, Label, Wrapper, Placeholder, Error } from './style';
import { ReactComponent as CalendarSvg } from './icon.svg';

type CalendarProps = {
  label?: string;
  placeholder?: string;
  value: Moment;
  onChange: (v: Moment | string) => void;
  minDate?: Moment;
  maxDate?: Moment;
  dataTestId?: string;
  onOpen?: () => void;
  dateFormat?: string;
  error?: string;
};

export const Calendar: FC<CalendarProps> = ({
  label,
  placeholder,
  value,
  onChange,
  maxDate,
  minDate,
  dataTestId,
  dateFormat,
  error,
}) => {
  const renderInput = (updatedValue, props, openCalendar, closeCalendar) => {
    const handleClick = () => {
      openCalendar();
    };
    return (
      <DateInput onClick={handleClick} data-test-id={dataTestId} className={'date-picker'}>
        {updatedValue ? (
          updatedValue.format(dateFormat || 'DD.MM.YYYY')
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <Icon>
          <CalendarSvg />{' '}
        </Icon>
      </DateInput>
    );
  };

  return (
    <Wrapper hasError={Boolean(error)}>
      {label && <Label>{label}</Label>}
      <Datetime
        className={''}
        onChange={onChange}
        value={value ? moment(value) : undefined}
        closeOnSelect={true}
        dateFormat={dateFormat || 'DD/MM/YYYY'}
        timeFormat={''}
        renderInput={renderInput.bind(this, value)}
        isValidDate={currentDate => {
          const momentDate = moment(currentDate);
          if (
            (minDate && momentDate.isBefore(minDate)) ||
            (maxDate && momentDate.isAfter(maxDate))
          ) {
            return false;
          }

          return true;
        }}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
