import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItemText } from '@zendeskgarden/react-chrome';

import { ISidebarMenuItem } from '../../components';
import { AgroidRoutes } from '../../routes';
import { AccessRuleUserActionName, EAccessRuleScoutingName } from '../../types';

export const useContractorMenu = (contractorId: string) => {
  const contractorPath = `${AgroidRoutes.Contractors}/${contractorId}`;

  const menuItems: ISidebarMenuItem[] = [
    {
      caption: (
        <NavItemText>
          <NavLink to={AgroidRoutes.Contractors} isActive={() => false}></NavLink>
        </NavItemText>
      ),
      isBack: true,
      slug: 'contractors-back',
    },
    {
      caption: 'Профиль компании',
      link: `${contractorPath}`,
      slug: 'profile',
    },
    {
      caption: 'Сотрудники',
      link: `${contractorPath}/employees`,
      slug: 'employes',
    },
    {
      caption: 'Приглашения',
      link: `${contractorPath}/invites`,
      slug: 'invites',
      accessActions: [AccessRuleUserActionName.manageInvites],
      findAllAccesses: true,
    },
    {
      caption: 'Роли',
      link: `${contractorPath}/roles`,
      slug: 'roles',
      accessActions: [AccessRuleUserActionName.editEmployee, AccessRuleUserActionName.editRole],
      findAllAccesses: true,
    },
    {
      caption: 'Реквизиты',
      link: `${contractorPath}/requisites`,
      slug: 'requisites',
    },
    {
      caption: 'Договоры',
      link: `${contractorPath}/contracts`,
      slug: 'requisites',
      accessActions: [EAccessRuleScoutingName.viewContract, EAccessRuleScoutingName.manageScouting],
      findAllAccesses: true,
    },
  ];
  return menuItems;
};
