import { FC, useState } from 'react';
import { Button, Input } from '@farmlink/farmik-ui';

import { checkIsEmail } from '../../../../../../utils';
import MailIco from '../../assets/mail.svg';

import {
  ModalAction,
  ModalButtonWrapper,
  ModalDescription,
  ModalTitle,
  InputWrapper,
  Image,
  CancelButton,
} from './style';

type Props = {
  email: string;
  setChangeEmailModal: (val: boolean) => void;
  confirmEmail: (val: string) => Promise<any>;
  updateEmailInProfile: (val: string) => void;
};

export const ChangeEmailWizard: FC<Props> = ({
  setChangeEmailModal,
  email,
  confirmEmail,
  updateEmailInProfile,
}) => {
  const [emailInput, setEmailInput] = useState(email);
  const [emailErrors, setEmailError] = useState('');
  const [checkSuccess, setCheckSuccess] = useState(false);

  const handleConfirmEmail = () => {
    if (checkIsEmail(emailInput)) {
      confirmEmail(emailInput)
        .then(() => {
          setCheckSuccess(true);
        })
        .catch(() => {
          setEmailError('Произошла ошибка');
        });
    } else {
      setEmailError('Указан некорректный email');
    }
  };

  const handleChangeEmailInput = (value: string) => {
    setEmailError('');
    setEmailInput(value);
  };

  if (checkSuccess) {
    return (
      <>
        <Image src={MailIco} alt="" />
        <ModalDescription
          className="success"
          data-test-id="change-email-wizard-success-description"
        >
          На ваш E-mail {emailInput} была отправлена ссылка на создание нового пароля
        </ModalDescription>
        <ModalButtonWrapper data-test-id="change-email-wizard-button-success-wrapper">
          <Button
            dataTestId="profile-edit-email-approve"
            type="button"
            color="primary"
            onClick={() => {
              updateEmailInProfile(emailInput);
              setChangeEmailModal(false);
            }}
          >
            Хорошо
          </Button>
        </ModalButtonWrapper>
      </>
    );
  }

  return (
    <>
      <ModalTitle data-test-id="change-email-wizard-title">Изменение email</ModalTitle>
      <ModalDescription data-test-id="change-email-wizard-description">
        На указанный Вами email придет ссылка для подтверждения почты
      </ModalDescription>
      <InputWrapper data-test-id="change-email-wizard-input-wrapper">
        <Input
          dataTestId="profile-edit-email-input-modal"
          label="Email"
          error={emailErrors}
          value={emailInput}
          onChange={handleChangeEmailInput}
        />
      </InputWrapper>
      <ModalAction data-test-id="change-email-wizard-action">
        <ModalButtonWrapper data-test-id="change-email-button-continue-wrapper">
          <Button
            dataTestId="profile-edit-email-continue"
            disabled={!emailInput.length}
            type="button"
            color="primary"
            onClick={handleConfirmEmail}
          >
            Продолжить
          </Button>
        </ModalButtonWrapper>
        <ModalButtonWrapper data-test-id="change-email-button-cancel-wrapper">
          <CancelButton
            data-test-id="profile-edit-email-cancel"
            onClick={() => {
              setChangeEmailModal(false);
            }}
          >
            Отмена
          </CancelButton>
        </ModalButtonWrapper>
      </ModalAction>
    </>
  );
};
