import { Button, EModalType, useModal } from "@farmlink/farmik-ui";
import { observer } from "mobx-react";
import { FC } from "react";
import { useHistory } from "react-router";

import { UserInviteModalType } from "../..";
import { AuthorizationRoutes } from "../../../../../auth/routes";
import { EModalSize } from "../../../../modules/common/containers/configs/modals/Modal/styles";

import { ActionsBlock, GoToSignIn, GotoSignInAction, GoToSignInBlock, GoToSignInText, GoToSignupButtonWrapper, InviteNotAuthStatusSentWrapper, OrgLogo, OrgName, RightArrow, SeparatorLine, Title1, Title2, TopBlock } from "./style";

export const InviteNotAuthStatusSent: FC = observer(() => {
    const history = useHistory();
    const { getModalPayload } = useModal();
    const { orgName, orgLogo } = getModalPayload();
    
    return (
        <InviteNotAuthStatusSentWrapper>
            <TopBlock>
                <OrgLogo imgUrl={orgLogo}/>
                <OrgName>{orgName}</OrgName>
            </TopBlock>
            <Title1>
                {`Вас приглашают присоединиться к организации ${orgName}`}
            </Title1>
            <Title2>
                Создайте учетную запись AGRO-ID всего за 2 минуты!
            </Title2>
            <ActionsBlock>
                <GoToSignupButtonWrapper>
                    <Button 
                        color='primary'
                        type={'button'}
                        onClick={() => {
                            history.push(AuthorizationRoutes.signUp);
                        }}
                    >
                        Регистрация
                    </Button>
                </GoToSignupButtonWrapper>
                <SeparatorLine/>
                <GoToSignInBlock>
                    <GoToSignInText>Уже есть учетная запись?</GoToSignInText>
                    <GotoSignInAction
                        onClick={() => {
                            history.push(AuthorizationRoutes.signIn);
                        }}
                    >
                        <GoToSignIn>
                            Войти
                        </GoToSignIn>
                        <RightArrow>
                            {'>'}
                        </RightArrow>
                    </GotoSignInAction>
                </GoToSignInBlock>
            </ActionsBlock>
        </InviteNotAuthStatusSentWrapper>
    );
});
