import styled, { StyledComponentBase } from 'styled-components';
import { Link } from 'react-router-dom';
import { Radio } from '@zendeskgarden/react-forms';

interface IContractorRowWrapper extends StyledComponentBase<any, {}> {
  Details?: any;
  RadioWrapper?: any;
  Radio?: any;
  Status?: any;
}
export const ContractorRowWrapper: IContractorRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 16px;
  margin-bottom: 16px;

  &:hover > div {
    color: #000;
  }
`;

ContractorRowWrapper.Details = styled.div`
  width: calc(100% - 100px);
`;

ContractorRowWrapper.RadioWrapper = styled.div<{ isScout?: boolean }>`
  width: 100px;
  height: 20px;
  text-align: right;

  ${({ isScout }) =>
    isScout
      ? `
      align-self: flex-start;   
    `
      : ''}

  label[data-garden-id='forms.radio_label'] svg {
    top: 3px;
    left: 1px;
    width: 14px;
    height: 14px;

    circle {
      cx: 6;
      cy: 6;
      r: 4;
    }
  }
`;

ContractorRowWrapper.Radio = styled(Radio)``;

ContractorRowWrapper.Status = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const ContractorLink = styled(Link)`
  color: ${({ theme: { colors: c } }) => c.green};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${({ theme: { colors: c } }) => c.hoverGreen};
    text-decoration: none;
  }
`;

export const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const NoDataPlaceholderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledNoDataIcon = styled.img`
  width: 40px;
`;

export const NoDataTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 36px;
  letter-spacing: -0.1px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
