import { FC, HTMLAttributes, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { isMobileOnly, isMobile } from 'react-device-detect';
import Toggle from 'react-toggle';
import { Label, Input } from '@zendeskgarden/react-forms';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { baseSchema as RoleSchema } from '../NewRoleModal/schema';
import { RolesStore } from '../../stores/roles.store';
import { useStore } from '../../../../utils';
import { PromptModal } from '../';
import { CheckAccessStore } from '../../stores';
import { EAccessRuleScoutingName } from '../../types';
import { ProfileStore } from '../../../auth/stores/profile.store';

import { ReactComponent as ArrowSvg } from './assets/arrow.svg';
import { ReactComponent as CancelSvg } from './assets/cancel.svg';
import {
  ColsWrapper,
  ModuleRow,
  ActionRow,
  RoleFormWrapper,
  FieldStyled,
  LoadderWrapper,
} from './styled';

export interface RoleFormProps extends HTMLAttributes<HTMLDivElement> {
  onChange?: any;
  modules?: any;
  roleSettings?: any;
  roleId: string;
  disableAll?: boolean;
  isDisabledAction?: (action: any) => boolean;
}

export const RoleForm: FC<RoleFormProps> = observer(
  ({ roleId, onChange, modules, roleSettings, disableAll, isDisabledAction }) => {
    const colsRef = useRef<HTMLDivElement>();
    const [calculatedHeight, setCalculatedHeight] = useState('initial');
    const [settings, setNewSetings] = useState<any>(resetSettings);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState('');
    const { isLoading, updateRole } = useStore(RolesStore);
    const { getUserHasAccessRule } = useStore(CheckAccessStore);
    const { user } = useStore(ProfileStore);
    const inputRef = useRef<HTMLInputElement>();

    const callUpdateRole = e => {
      if (e) {
        e.preventDefault();
      }
      setError('');

      const validationContext = RoleSchema.newContext();
      validationContext.validate({
        name: settings.name,
      });

      if (!validationContext.isValid()) {
        const errors = validationContext.validationErrors();
        const keyErrorMessage = validationContext.keyErrorMessage(errors[0].name);
        setError(keyErrorMessage);
      } else {
        updateRole(settings);
        setChanged(false);
        onChange([]);
      }
    };

    useEffect(() => {
      setChanged(false);
      onChange([]);
    }, [roleId]);

    useEffect(() => setNewSetings(resetSettings), [JSON.stringify(roleSettings)]);

    const roleActions = [
      {
        caption: 'Отменить',
        component: Anchor,
        icon: !isMobileOnly && CancelSvg,
        positionIcon: 'right',
        componentProps: {
          isBasic: true,
          onClick(e) {
            e.preventDefault();
            setError('');
            setNewSetings(resetSettings());
            setChanged(false);
            onChange([]);
          },
        },
      },
      {
        caption: 'Сохранить',
        icon: ArrowSvg,
        component: Anchor,
        positionIcon: 'right',
        componentProps: {
          isPrimary: true,
          onClick: callUpdateRole,
        },
      },
    ];

    function resetSettings() {
      const newSettings: any = {};
      newSettings.name = roleSettings.name;
      newSettings.id = roleSettings.id;
      newSettings.order = 0;
      newSettings.actionCodes = roleSettings.actions
        ? roleSettings.actions.map(item => item.code)
        : [];

      return newSettings;
    }

    useEffect(() => {
      if (changed) {
        onChange(Object.values(roleActions));
      }
    }, [changed, JSON.stringify(settings)]);

    useLayoutEffect(() => {
      const curRef = inputRef.current;
      if (curRef && inputRef.current) {
        curRef.setAttribute('maxlength', String(20));
      }

      if (!isMobile) {
        if (colsRef && colsRef.current) {
          const { y: offset = 277 } = colsRef?.current.getBoundingClientRect();
          setCalculatedHeight(`calc(100vh - ${offset}px - 16px)`);
        }
      }
    }, [colsRef.current, inputRef]);

    const checkedRole = code =>
      (settings.actionCodes || []).findIndex(action => action === code) > -1;

    const handleChangeText = event => {
      setNewSetings(prev => ({ ...prev, name: event.target.value }));
      setChanged(true);
    };

    const toggleAction = rCode => {
      let newCheckedList = [...settings.actionCodes];
      const checkedIndex = newCheckedList.findIndex(i => i === rCode);
      if (checkedIndex > -1) {
        newCheckedList = newCheckedList.filter(i => i !== rCode);
      } else {
        newCheckedList.push(rCode);
      }
      setNewSetings(prev => ({ ...prev, actionCodes: newCheckedList }));
      setChanged(true);
    };

    const isDisabled = () => {
      if (disableAll === undefined) {
        return roleSettings.isOwner;
      }
      return disableAll;
    };

    if (isLoading) {
      return (
        <LoadderWrapper>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </LoadderWrapper>
      );
    }

    return (
      <RoleFormWrapper className="role-form-wrapper">
        <PromptModal
          changed={changed}
          message="Есть несохраненные настройки"
          callUpdateRole={callUpdateRole}
        />

        <FieldStyled>
          <Label>Название роли</Label>
          <Input
            value={settings.name}
            onChange={handleChangeText}
            readOnly={isDisabled()}
            ref={inputRef}
          />
        </FieldStyled>
        {Boolean(error) && <div id="uniforms-name">{error}</div>}
        <ColsWrapper ref={colsRef} className={'cols-wrapper'}>
          {modules.map(({ name, id, code, actions = [] }) => (
            <ModuleRow data-code={code} key={id}>
              <ModuleRow.Name>{name}</ModuleRow.Name>
              {actions.map(action => (
                <ActionRow key={action.id}>
                  <ActionRow.Label disabled={checkedRole(action.code)}>
                    {action.name}
                  </ActionRow.Label>
                  <ActionRow.CheckboxWrapper>
                    <Toggle
                      checked={checkedRole(action.code)}
                      icons={false}
                      disabled={isDisabledAction ? isDisabledAction(action) : isDisabled()}
                      onChange={() => toggleAction(action.code)}
                    />
                  </ActionRow.CheckboxWrapper>
                </ActionRow>
              ))}
            </ModuleRow>
          ))}
        </ColsWrapper>
      </RoleFormWrapper>
    );
  }
);
