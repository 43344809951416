import React, { FC, HTMLAttributes } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ReactComponent as DelSvg } from '../../../../assets/images/agroid/del.svg';
import { num2str } from '../../../../utils';

import { RoleCardStyled, DelAnchor } from './styled';

export interface RoleCard extends HTMLAttributes<HTMLDivElement> {
  id: string;
  name: string;
  employeeCount: number;
  isOwner: boolean;
  isDefault?: boolean;
  dataTestId?: string;
  onDelClick?: (id: string) => void;
  isReadonly?: boolean;
  isOrganizationOwner?: boolean;
}

export const RoleCard: FC<RoleCard> = ({
  id,
  name,
  employeeCount = 0,
  isOwner,
  isDefault,
  dataTestId,
  onDelClick,
  isReadonly,
  isOrganizationOwner,
}) => {
  const { url } = useRouteMatch();

  const handleDelete = event => {
    event.preventDefault();
    onDelClick(id);
  };

  const hasOrganizationOwner = typeof isOrganizationOwner !== undefined;

  return (
    <RoleCardStyled key={id} data-test-id={dataTestId && `${dataTestId}-card-item`}>
      {!isOwner && !isDefault && onDelClick && (
        <DelAnchor
          href={`${url}/${id}`}
          onClick={handleDelete}
          data-test-id={dataTestId && `${dataTestId}-card-delete-action`}
        >
          <DelSvg data-test-id={dataTestId && `${dataTestId}-card-delete-ico`} />
        </DelAnchor>
      )}

      <RoleCardStyled.Title tag="h2" data-test-id={dataTestId && `${dataTestId}-card-title`}>
        {name}
      </RoleCardStyled.Title>

      <RoleCardStyled.Text data-test-id={dataTestId && `${dataTestId}-card-text`}>
        {employeeCount} {num2str(employeeCount, ['сотрудник', 'сотрудника', 'сотрудников'])}
      </RoleCardStyled.Text>

      <RoleCardStyled.LinkWrapper
        data-test-id={dataTestId && `${dataTestId}-card-action-container`}
      >
        {(hasOrganizationOwner || !isOwner) && (
          <RoleCardStyled.Link
            to={`${url}/${id}`}
            data-test-id={dataTestId && `${dataTestId}-card-action`}
          >
            {isReadonly || isOwner ? 'Посмотреть' : 'Настроить'}
          </RoleCardStyled.Link>
        )}
      </RoleCardStyled.LinkWrapper>
    </RoleCardStyled>
  );
};
