import React, { Ref, useRef, useLayoutEffect } from 'react';
import { HTMLFieldProps, connectField, filterDOMProps } from 'uniforms';
import { Field, Label, Textarea } from '@zendeskgarden/react-forms';

import { RequireSign } from '../styled';

export type LongTextFieldProps = HTMLFieldProps<
  string,
  HTMLDivElement,
  { inputRef?: Ref<HTMLTextAreaElement> }
>;

function LongText({
  disabled,
  id,
  // inputRef,
  label,
  name,
  onChange,
  placeholder,
  readOnly,
  value,
  max = 200,
  required,
}: LongTextFieldProps) {
  const ref = useRef<HTMLTextAreaElement>();

  useLayoutEffect(() => {
    const curRef = ref.current;
    curRef.setAttribute('maxlength', String(max));
  });

  return (
    <Field>
      {label && (
        <Label>
          {label} {Boolean(required) && <RequireSign />}
        </Label>
      )}
      <Textarea
        minRows={2}
        maxRows={8}
        disabled={disabled}
        id={id}
        name={name}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        value={value ?? ''}
      />
    </Field>
  );
}

export const LongTextField = connectField<LongTextFieldProps>(LongText, { kind: 'leaf' });
