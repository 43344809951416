import React, { FC, useMemo, SVGProps, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import NoDataIcon from '../icons/noDataIcon.svg';
import { useStore } from '../../../../../utils';
import { WellHeader, CardsList, RoleCard, NewRoleModal, DelRoleModal } from '../../../components';
import { RolesStore } from '../../../stores/roles.store';
import { WellRolesStyled, NoDataPlaceholder, NoDataTitle, StyledNoDataIcon } from '../styled';

export interface IAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
}

export const OrgRoles: FC = observer(() => {
  const [visible, setVisible] = useState(false);
  const [visibleDelRole, setVisibleDelRole] = useState<string | boolean>(false);
  const { orgId } = useParams<{ orgId: string }>();
  const { isLoading, roles, setOrganization, fetchRoles } = useStore(RolesStore);

  useEffect(() => {
    setOrganization(orgId);
    fetchRoles();
  }, [orgId]);

  const actions = useMemo(
    () => [
      {
        caption: isMobile ? false : 'Создать роль',
        component: isMobile ? IconButton : Button,
        icon: isMobile && PlusIcon,
        dataTestIdAction: 'organization-roles-role-create-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
      },
    ],
    []
  );

  const handleRoleDel = (id: string) => {
    setVisibleDelRole(id);
  };

  const getAnotherRoleName = () => {
    return (
      roles.find(({ id, isDefault, isOwner }) => id !== visibleDelRole && isDefault && !isOwner) ||
      {}
    ).name;
  };

  return (
    <>
      <WellRolesStyled>
        <WellHeader
          title="Роли организации"
          actions={actions}
          isAlone
          dataTestId="organization-roles"
        />
      </WellRolesStyled>
      {isLoading ? (
        <Well>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </Well>
      ) : roles.length ? (
        <CardsList
          className={'role-list'}
          list={roles.map(role => ({
            ...role,
            onDelClick: () => handleRoleDel(role.id),
          }))}
          card={RoleCard}
          dataTestId="roles"
        />
      ) : (
        <NoDataPlaceholder className="with-alone">
          <StyledNoDataIcon src={NoDataIcon} />
          <NoDataTitle>Нет ролей</NoDataTitle>
        </NoDataPlaceholder>
      )}
      {visible && <NewRoleModal orgId={orgId} onClose={() => setVisible(false)} />}
      {visibleDelRole && (
        <DelRoleModal
          roleId={visibleDelRole as string}
          anotherRoleName={getAnotherRoleName()}
          onClose={() => setVisibleDelRole(false)}
        />
      )}
    </>
  );
});
