import React, { FC, SVGProps, useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Well } from '@zendeskgarden/react-notifications';
import { Anchor, Button, IconButton } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';
import { isEmpty } from 'lodash';

import { ApplicationStatusLabels } from '../../../../../api/models/applications.model';
import { useStore } from '../../../../../utils';
import { OrganizationStore } from '../../../stores/organization.store';
import {
  WellStyled,
  WellFilterStyled,
  NoDataPlaceholder,
  StyledNoDataIcon,
  NoDataTitle,
} from '../styled';
import { AccessRuleVisibility, AccessRuleUserActionName } from '../../../types';
import {
  WellHeader,
  CardsList,
  AppCard,
  UsersFilter,
  NewUserModal,
  NewUserModallSuccess,
  AppDetailsModal,
  IModalHeaderAction,
  CancelConfirmModal,
} from '../../../components';
import SearchIcon from '../icons/search.svg';

export interface IOrgApplicationsParams {
  orgId?: string;
}

export interface IOrgApplicationsAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
}

export const orgActions: IOrgApplicationsAction[] = [];

export const OrgApplications: FC = observer(() => {
  const {
    getOrganizationApplications,
    setOrganizationId,
    isLoading,
    usersFilter,
    setFilter,
    applicationsList,
  } = useStore(OrganizationStore);
  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleUserId, setVisibleUserDetails] = useState<boolean | string>(false);
  const [visibleCancelConfirm, setVisibleCancelConfirm] = useState<boolean | string>(false);
  const { orgId } = useParams<IOrgApplicationsParams>();

  const actions = useMemo(
    () => [
      {
        caption: isMobile ? false : 'Добавить',
        component: isMobile ? IconButton : Button,
        icon: isMobile && PlusIcon,
        dataTestIdAction: 'organization-applications-organization-add-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.manageInvites,
      },
    ],
    []
  );

  const handleChange = filter => {
    setFilter({
      organizationID: orgId,
      ...filter,
    });
  };

  const handleAppDetails = (event, invitationId) => {
    event.preventDefault();
    setVisibleUserDetails(invitationId);
  };

  useEffect(() => {
    setOrganizationId(orgId);
  }, [orgId]);

  useEffect(() => {
    getOrganizationApplications();
  }, [JSON.stringify(usersFilter)]);

  const handleReapplyUser = event => {
    event.preventDefault();
    setVisibleUserDetails(false);
    setVisibleCancelConfirm(visibleUserId);
  };

  const modalActions: IModalHeaderAction[] = [
    {
      caption: 'Отозвать ✕',
      component: Anchor,
      dataTestIdAction: 'organization-applications-user-reapply-action',
      componentProps: {
        onClick: handleReapplyUser,
        isDanger: true,
      },
    },
  ];

  const statusOptions = [{ value: '', label: 'Все статусы' }].concat(
    Object.keys(ApplicationStatusLabels)
      .map(key => ({
        value: key,
        label: ApplicationStatusLabels[key],
      }))
      .filter(item => item.label !== 'Владелец')
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Well>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </Well>
      );
    }

    if (!isEmpty(applicationsList)) {
      return (
        <CardsList
          list={applicationsList.map(app => ({
            ...app,
            onClick: event => handleAppDetails(event, app.invitationId),
          }))}
          card={AppCard}
          cardProps={{
            padding: 'ssm',
          }}
          dataTestId="applications"
        />
      );
    }

    return (
      <NoDataPlaceholder className="with-filter">
        <StyledNoDataIcon src={SearchIcon} />
        <NoDataTitle>Вы еще никого не пригласили в свою организацию</NoDataTitle>
      </NoDataPlaceholder>
    );
  };

  return (
    <>
      <WellStyled>
        <WellHeader title="Приглашения" actions={actions} dataTestId="organization-applications" />
        <WellFilterStyled>
          <UsersFilter onChange={handleChange} statusOptions={statusOptions} />
        </WellFilterStyled>
      </WellStyled>
      {renderContent()}
      {visible && (
        <NewUserModal
          orgId={orgId}
          onClose={result => {
            setVisible(false);
            if (result === true) {
              setVisibleSuccess(true);
            }
          }}
        />
      )}
      {visibleSuccess && (
        <NewUserModallSuccess
          onClose={() => {
            setVisibleSuccess(false);
            getOrganizationApplications();
          }}
        />
      )}
      {visibleUserId && (
        <AppDetailsModal
          title="Приглашение"
          actions={modalActions}
          invitationId={visibleUserId as string}
          onClose={result => {
            if (result === 'cancelConfirm') {
              setVisibleCancelConfirm(visibleUserId);
            }
            setVisibleUserDetails(false);
          }}
        />
      )}
      {visibleCancelConfirm && (
        <CancelConfirmModal
          invitationId={visibleCancelConfirm as string}
          onClose={() => {
            setVisibleCancelConfirm(false);
            getOrganizationApplications();
          }}
        />
      )}
    </>
  );
});
