import styled from 'styled-components';
import { Well } from '@zendeskgarden/react-notifications';

export type CardWrapperProps = {
  padding?: string;
  isAdd?: boolean;
};

export const CardWrapper = styled(Well)<CardWrapperProps>`
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0), 0px 2px 4px rgba(0, 0, 0, 0), 0px 1px 4px rgba(0, 0, 0, 0);
  transition: 0.2s box-shadow linear;
  overflow: hidden;
  background-color: ${({ isAdd }) => isAdd && '#faf9fd'};
  border: ${({ isAdd }) => isAdd && '2px dashed #eae8f2'};
  padding: 0;

  &:active,
  &:hover {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 6px 8px rgba(0, 0, 0, 0.05),
      0px 2px 24px rgba(0, 0, 0, 0.1);
  }
`;

export const CardsGridWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(1, auto);

  @media (max-width: 767px) {
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 768px) {
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
`;
