import styled, { keyframes } from 'styled-components';

export const InvitesListWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(1, auto);

  @media (max-width: 767px) {
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const NoApplicationsPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #ffffff;
`;

const spinner = keyframes`
  to {transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: ${spinner} 0.6s linear infinite;
  // margin: 24px 0;
  align-self: center;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
