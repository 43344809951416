import styled from 'styled-components';
import { LG } from '@zendeskgarden/react-typography';
import { Button } from '@zendeskgarden/react-buttons';
import { Field } from '@zendeskgarden/react-forms';

import { TypeUserStatus } from '../../../../api/models/user.model';

export const StyledStatus = styled.span<{ status?: TypeUserStatus }>`
  ${({ theme: { colors: c }, status }) => {
    if (!status) {
      return `color: ${c.generalBlack};`;
    }

    if (status === 'ACTIVE') {
      return `color: ${c.accentGreen};`;
    }

    if (status === 'FIRED') {
      return `color: ${c.secondaryDarkGray};`;
    }
  }}
`;

export const StyledRoleButton = styled(Button)`
  border: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  height: auto;
  color: inherit;
`;

export const StyledUserName = styled(LG)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const StyledUserNameField = styled(Field)`
  margin-bottom: 12px;
`;
