import React, { FC, SVGProps, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import get from 'lodash/get';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { isMobileOnly } from 'react-device-detect';
import { Anchor } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';

import { ReactComponent as NoLogo } from '../../../../../assets/images/agroid/logo-template.svg';
import { ReactComponent as EditSvg } from '../../../../../assets/images/agroid/edit.svg';
import {
  WellHeader,
  ServicesList,
  TypeUserRoleEnumString,
  OrgLogo,
  NoData,
} from '../../../components';
import { useStore, num2str, formatPhoneFromStr } from '../../../../../utils';
import { OrganizationStore } from '../../../stores/organization.store';
import { ProfileStore } from '../../../../auth/stores/profile.store';
import { WellStyled, WellBodyStyled, OrgLogoWrapper, DetailsWrapper, UserRole } from '../styled';
import { AgroidRoutes } from '../../../routes';

export interface IParams {
  orgId?: string;
}

export interface IAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
  dataTestIdAction?: string;
  onClick?: () => void;
}

export const orgActions: IAction[] = [
  {
    icon: EditSvg,
    caption: isMobileOnly ? '' : 'Редактировать',
    component: Anchor,
    dataTestIdAction: 'organization-profile-edit-action',
    componentProps: {
      isBasic: true,
    },
  },
];

export const OrgProfile: FC = observer(() => {
  const {
    organization,
    setFilter,
    fetchOrganization,
    getOrganizationUsers,
    usersList,
    isLoading,
  } = useStore(OrganizationStore);
  const { user, fetchProfile } = useStore(ProfileStore);
  const { orgId } = useParams<IParams>();
  const [role, setRole] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchProfile();
    fetchOrganization(orgId);
    setFilter({
      organizationID: orgId,
    });
    getOrganizationUsers();
  }, [orgId]);

  // todo Несовсем понял что что за код
  useEffect(() => {
    orgActions[0].componentProps.onClick = () => {
      history.push(generatePath(AgroidRoutes.OrganizationRequisites, { orgId }));
      return false;
    };
  }, [orgId]);

  useEffect(() => {
    if (user) {
      const userInList = usersList.find(({ userId }) => userId === user.id);
      if (userInList) {
        // todo Когда старые роли исчезнуть оставить userInList.roleInfo?.name !!!!!!!
        setRole(userInList.roleInfo?.name || TypeUserRoleEnumString[userInList.role]);
      }
    }
  }, [user, usersList]);

  return (
    <>
      <WellStyled className="profile">
        {isLoading ? (
          <>
            <Skeleton height="24px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
            <Skeleton height="12px" />
          </>
        ) : organization ? (
          <>
            <WellHeader
              title={'Данные организации'}
              actions={orgActions}
              dataTestId="organization-profile"
            />
            <WellBodyStyled>
              <Grid>
                <Row>
                  <OrgLogoWrapper>
                    {organization.logo ? <OrgLogo {...organization.logo} /> : <NoLogo />}
                  </OrgLogoWrapper>
                  <Col>
                    <Grid>
                      <Row>
                        <Col md={5}>
                          <DetailsWrapper>
                            <div className="name">
                              {`${get(organization, 'type.OrgType', '')} ${organization.name}`}
                            </div>
                            <div className="employeersCount">
                              {`${usersList.length} `}
                              {num2str(usersList.length, [
                                'сотрудник',
                                'сотрудника',
                                'сотрудников',
                              ])}
                            </div>
                            <div className="contacts">
                              {organization?.phone && (
                                <div className="phone">
                                  {formatPhoneFromStr(organization?.phone)}
                                </div>
                              )}
                              {organization?.email && (
                                <div className="email">{organization.email}</div>
                              )}
                            </div>
                          </DetailsWrapper>
                        </Col>
                        <Col>
                          <DetailsWrapper>
                            {role && (
                              <UserRole>
                                <div className="label">Ваша роль</div>
                                {role}
                              </UserRole>
                            )}
                          </DetailsWrapper>
                        </Col>
                      </Row>
                    </Grid>
                  </Col>
                </Row>
              </Grid>
            </WellBodyStyled>
          </>
        ) : (
          <NoData message="Данные организации недоступны" />
        )}
      </WellStyled>
      <ServicesList />
    </>
  );
});
