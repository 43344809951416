import { FC } from 'react';
import { Label } from '@zendeskgarden/react-forms';

import { OrgLogo } from '../OrgLogo';

import { UserToRoleRowWrapper } from './styled';

export interface UserToRoleRowProps {
  userName: string;
  id: string;
  onClick: (userId: string) => void;
  avatar?: any;
  checkedList: string[];
  roleInfo: any;
  isScouting?: boolean;
  fullName?: string;
}

const getInitials = userName => {
  return userName
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .substr(0, 2);
};

export const UserToRoleRow: FC<UserToRoleRowProps> = ({
  checkedList,
  userName,
  id,
  onClick,
  avatar,
  roleInfo,
  isScouting,
  fullName,
}) => {
  const checkOwnerAndScouting = () => {
    if (isScouting) {
      return true;
    }
    if (!roleInfo.isOwner) {
      return true;
    }

    return false;
  };
  return checkOwnerAndScouting() ? (
    <UserToRoleRowWrapper key={id} onClick={() => onClick(id)}>
      <UserToRoleRowWrapper.Avatar>
        {avatar && avatar.downloadUrl ? (
          <OrgLogo {...avatar} />
        ) : (
          getInitials(isScouting ? fullName : userName)
        )}
      </UserToRoleRowWrapper.Avatar>
      <UserToRoleRowWrapper.Details>
        {isScouting ? fullName : userName}
      </UserToRoleRowWrapper.Details>
      <UserToRoleRowWrapper.CheckboxWrapper>
        <UserToRoleRowWrapper.Checkbox
          checked={checkedList.includes(id)}
          onChange={() => onClick(id)}
        >
          <Label hidden>{isScouting ? fullName : userName}</Label>
        </UserToRoleRowWrapper.Checkbox>
      </UserToRoleRowWrapper.CheckboxWrapper>
    </UserToRoleRowWrapper>
  ) : null;
};
