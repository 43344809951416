import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    padding: 64px 8px 0 8px;
  }

  @media (min-width: 768px) {
    padding: 80px 0 0 0;
  }

  @media (min-width: 1024px) {
    padding: 0 0 0 216px;
  }
`;
