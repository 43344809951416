enum EAgroidRoute {
  Organizations = '/agroid/organizations',
  OrganizationEmployees = '/agroid/organizations/:orgId/employees',
  OrganizationsOrganizationContracts = '/agroid/organizations/:orgId/contracts',
  Scouting = '/agroid/scouting',
  ScoutingContractor = '/agroid/scouting/contractors/:contractorId',
  ScoutingContractorCreate = '/agroid/scouting/contractors/create',
  ScoutingContractorEmployees = '/agroid/scouting/contractors/:contractorId/employees',
  ScoutingContractorInvites = '/agroid/scouting/contractors/:contractorId/invites',
  ScoutingContractorRequisites = '/agroid/scouting/contractors/:contractorId/requisites',
  ScoutingContractorRoles = '/agroid/scouting/contractors/:contractorId/roles',
  ScoutingContractorRole = '/agroid/scouting/contractors/:contractorId/roles/:roleId',
  ScoutingContractorContracts = '/agroid/scouting/contractors/:contractorId/contracts',
}

export default EAgroidRoute;
