import styled, { StyledComponentBase } from 'styled-components';
import { Header } from '@zendeskgarden/react-modals';

interface IModalHeaderStyled extends StyledComponentBase<any, {}> {
  Title?: any;
  Actions?: any;
  Container?: any;
  Search?: any;
}

export const ModalHeaderContainer: IModalHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ModalHeaderStyled: IModalHeaderStyled = styled(Header)<{ scroll?: boolean }>`
  padding: 40px 60px 16px 60px;

  ${({ scroll, theme }) =>
    scroll
      ? `
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid ${theme.colors.borderGray};
        `
      : `
          border-bottom: 0;
    `}

  [data-garden-id='forms.field'] {
    margin-bottom: 0;
  }
`;

ModalHeaderStyled.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

ModalHeaderStyled.Title = styled.div`
  flex-grow: 1;
  font-size: 24px;
  line-height: 28px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

ModalHeaderStyled.Actions = styled.div`
  a {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 36px;
    text-decoration: none;
  }
`;

ModalHeaderStyled.Search = styled.div`
  margin-top: 16px;
`;
