import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { AutoField, AutoForm, ErrorField } from 'uniforms-unstyled';
import SimpleSchema from 'simpl-schema';
import SimpleSchema2Bridge from 'uniforms-bridge-simple-schema-2';
import { ReactComponent as StartIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

import { useStore } from '../../../../../../../../utils';
import { RolesStore } from '../../../../../../stores';
import { MediaInputField, SelectField } from '../../../../../../components/uniforms';

import { TListingPageFilterContainerProps } from './ListingPageFilterContainer.types';

const createFilterSchema = (
  config: TListingPageFilterContainerProps['filter']['filterBy']
): any => {
  const schema: any = {
    [config.searchQuery.key]: {
      type: String,
      label: 'Поиск',
      required: config.searchQuery.required,
      uniforms: {
        component: MediaInputField,
        start: StartIcon,
        label: false,
        placeholder: 'Поиск',
      },
    },
  };

  if (config?.status) {
    schema[config.status.key] = {
      type: String,
      label: 'Статусы',
      uniforms: {
        label: false,
        placeholder: 'Статусы',
      },
    };
  }

  if (config?.role) {
    schema[config.role.key] = {
      type: String,
      label: 'Роли',
      uniforms: {
        label: false,
        placeholder: 'Роли',
      },
    };
  }

  return schema;
};

const getSearchQuerySize = (
  config: TListingPageFilterContainerProps['filter']['filterBy']
): number => {
  const filterByList = Object.keys(config);

  if (filterByList.length === 1) return 12;

  if (filterByList.length === 2) return 9;

  return 6;
};

const ListingPageFilterContainer: FC<TListingPageFilterContainerProps> = observer(
  ({ onChange, filter }) => {
    const { roles } = useStore(RolesStore);

    const roleOptionList = [
      { value: '', label: 'Все роли' },
      ...roles.map(role => ({
        value: role.id,
        label: role.name,
      })),
    ];

    const statusOptionList = useMemo(() => {
      const _statusOptionList = [{ value: '', label: 'Все статусы' }];

      if (filter.filterBy?.status?.optionList) {
        _statusOptionList.push(
          ...Object.keys(filter.filterBy?.status?.optionList).map(key => ({
            value: key,
            label: filter.filterBy?.status?.optionList[key],
          }))
        );
      }

      return _statusOptionList;
    }, [filter?.filterBy]);

    const schema = new SimpleSchema2Bridge(new SimpleSchema(createFilterSchema(filter.filterBy)));

    return (
      <AutoForm schema={schema} onChangeModel={onChange}>
        <Grid>
          <Row>
            <Col sm={getSearchQuerySize(filter.filterBy)} xs={12} textAlign="start">
              <AutoField name={filter.filterBy.searchQuery.key} component={MediaInputField} />
              <ErrorField name={filter.filterBy.searchQuery.key} />
            </Col>
            {filter.filterBy?.status ? (
              <Col sm={3}>
                <AutoField
                  name={filter.filterBy?.status?.key}
                  component={SelectField}
                  options={statusOptionList}
                />
                <ErrorField name={filter.filterBy?.status?.key} />
              </Col>
            ) : null}
            {filter.filterBy?.role ? (
              <Col sm={3}>
                <AutoField
                  name={filter.filterBy?.role?.key}
                  component={SelectField}
                  options={roleOptionList}
                />
                <ErrorField name={filter.filterBy?.role?.key} />
              </Col>
            ) : null}
          </Row>
        </Grid>
      </AutoForm>
    );
  }
);

export default ListingPageFilterContainer;
