import { keys, trim, isEqual, isString, isEmpty } from 'lodash';

import { InitialValues } from '../../../../../api/models/contractor.model';

function trimValue(value: string) {
  if (!isString(value)) {
    return value;
  }

  return trim(value);
}

function emptyValue(value: string) {
  if (isEmpty(value)) {
    return null;
  }

  return value;
}

function getPayload(
  initialValues: InitialValues,
  values: InitialValues,
  isScoutAutoChanged: boolean
) {
  const payload = keys(initialValues)
    .filter(key => !isEqual(initialValues[key], values[key]))
    .reduce((acc, key) => {
      if (key === 'addressJur' || key === 'addressFact') {
        const addressfull = emptyValue(trimValue(values[key]));
        return {
          ...acc,
          [key]: !isEmpty(addressfull) ? { addressfull } : null,
        };
      }

      if (key === 'logo') {
        return { ...acc, logo: values.logo.id };
      }

      return { ...acc, [key]: trimValue(values[key]) ?? null };
    }, {});

  return { ...payload, isScout: isScoutAutoChanged ? true : undefined };
}

export { getPayload };
