import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios } from '../../../../../utils/axios2';

@provide.singleton()
export class InviteStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  organizationName = '';
  isLoading = true;
  isAccept = true;
  inviteEmail = '';
  status;
  userId;
  logoUrl;
  invitationId;

  fetchOrganizationInfo = (token: string) => {
    this.isLoading = true;
    this.axios.api
      .getOrganizationExternal({ token }, { omit: ['token'] })
      .then(response => {
        runInAction(() => {
          this.isAccept = true;
          this.isLoading = false;
          this.organizationName = response.name;
          this.inviteEmail = response.email;
          this.status = response.status;
          this.userId = response.userId;
          this.logoUrl = response.logo?.downloadUrl;
          this.invitationId = response.invitationId;
          // console.log(this.status);
          // console.log(this.userId);

          localStorage.setItem('externalKey', response.externalKey);
          localStorage.setItem('externalEmail', response.email);
        });
      })
      .catch(error => {
        console.error(error);

        this.isAccept = false;
        this.isLoading = false;
      });
  };

  resetInviteStore = () => {
    this.organizationName = '';
  };
}
