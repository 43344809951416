import React, { FC, HTMLAttributes, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Anchor, IconButton } from '@zendeskgarden/react-buttons';
import { Button } from '@farmlink/farmik-ui';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import { useStore } from '../../../../../../../utils';
import { CheckAccessStore, RolesStore, ScoutsStore } from '../../../../../stores';
import {
  AccessRuleUserActionName,
  AccessRuleVisibility,
  EAccessRuleScoutingName,
} from '../../../../../types';
import {
  DeleteButtonBlock,
  DeleteButtonWrapper,
  DeleteRoleDisclaimer,
  RoleBlock,
  RoleUserBlock,
  UsersBlock,
} from '../../../../RolePage/styled';
import {
  DelRoleModal,
  RoleForm,
  RoleUsers,
  UserToRoleModal,
  WellHeader,
} from '../../../../../components';
import { ReactComponent as ArrowSvg } from '../../../../../../../assets/images/agroid/arrow.svg';
import { ProfileStore } from '../../../../../../auth/stores/profile.store';
import { TypeRoleSettings } from '../../../../../../../api/models/role.model';

export type RolePageProps = HTMLAttributes<HTMLDivElement>;

export const ContractorRole: FC<RolePageProps> = observer(() => {
  const { contractorId: orgId, roleId } = useParams<{ contractorId: string; roleId: string }>();
  const [visible, setVisible] = useState(false);
  const { roleSettings, fetchRoleSettings, modules, defaultRole, fetchModulesAll } = useStore(
    RolesStore
  );
  const { user } = useStore(ProfileStore);
  const { scout } = useStore(ScoutsStore);
  const { getUserHasAccessRule, isOrganizationOwner } = useStore(CheckAccessStore);
  const [roleActions, setRoleActions] = useState([]);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [isUsersBlockOpen, setIsUsersBlockOpen] = useState(!isMobile);

  useEffect(() => {
    fetchModulesAll(orgId);
    if (roleId) {
      fetchRoleSettings(roleId);
    }
  }, [orgId, roleId]);

  const isReadonly = useMemo(() => {
    if (!user && !scout && !getUserHasAccessRule(EAccessRuleScoutingName.manageScouting, true))
      return true;
    if (user?.scout && roleSettings.isScoutForUser) {
      return false;
    }
    if (scout?.isScout && roleSettings.isScoutForOrganization) {
      return false;
    }
    return true;
  }, [user, scout, roleSettings]);

  const userActions = useMemo(
    () => [
      {
        caption: isMobileOnly ? false : 'Добавить',
        component: isMobileOnly ? IconButton : Anchor,
        icon: isMobileOnly ? PlusIcon : ArrowSvg,
        positionIcon: 'right',
        dataTestIdAction: 'role-page-user-add-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
        mode: isReadonly ? AccessRuleVisibility.Hide : AccessRuleVisibility.View,
        actionName: isOrganizationOwner ? false : EAccessRuleScoutingName.manageScouting,
      },
    ],
    [isReadonly, isOrganizationOwner]
  );

  const isDisabledAction = (action: any) => {
    return false;
  };

  return (
    <>
      <RoleUserBlock>
        <RoleBlock className={'role-block'}>
          <WellHeader
            title="Настройки роли"
            actions={roleActions}
            className="role-block-well-header"
            dataTestId="role-page-role-block"
          />
          {Boolean(roleSettings.id) && (
            <>
              <RoleForm
                roleId={roleId}
                modules={modules}
                roleSettings={roleSettings}
                onChange={setRoleActions}
                disableAll={roleSettings.isOwner}
              />
              {!roleSettings?.isOwner && !roleSettings?.isDefault && (
                <DeleteButtonBlock>
                  <DeleteButtonWrapper>
                    <Button
                      alignment="center"
                      color={'secondary'}
                      type="button"
                      onClick={() => setShowDeleteRoleModal(true)}
                      dataTestId="delete-role"
                    >
                      Удалить роль
                    </Button>
                  </DeleteButtonWrapper>
                  <DeleteRoleDisclaimer>
                    При удалении, всем сотрудникам этой роли будет назначена роль «
                    {defaultRole?.name || 'Нет данных'}»
                  </DeleteRoleDisclaimer>
                </DeleteButtonBlock>
              )}
            </>
          )}
        </RoleBlock>
        <UsersBlock className="users-block">
          <WellHeader
            title="Сотрудники"
            actions={roleSettings.isOwner || isOrganizationOwner ? [] : userActions}
            hasAccordeon={true}
            onToggleAccordeon={() => {
              setIsUsersBlockOpen(!isUsersBlockOpen);
            }}
            dataTestId="role-page-users-block"
          />
          {isUsersBlockOpen && <RoleUsers orgId={orgId} roleId={roleId} />}
        </UsersBlock>
      </RoleUserBlock>

      {visible && (
        <UserToRoleModal
          isScouting={true}
          orgId={orgId}
          roleId={roleId}
          onClose={() => setVisible(false)}
        />
      )}
      {showDeleteRoleModal && (
        <DelRoleModal
          roleId={roleId as string}
          anotherRoleName={defaultRole?.name || 'Нет данных'}
          onClose={() => setShowDeleteRoleModal(false)}
        />
      )}
    </>
  );
});
