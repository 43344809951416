import React, { FC, useMemo, useEffect, useState } from 'react';
import { Switch, Route, useParams, useRouteMatch, generatePath, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { DelOrgModal, SidebarLayout, SidebarMenu, Layout } from '../../components';
import { AgroidRoutes } from '../../routes';
import { RolePage } from '../../pages';
import { RolesStore } from '../../stores/roles.store';
import { CheckAccessStore } from '../../stores/checkAccess.store';
import { useStore } from '../../../../utils';
import organizationsOrganizationContractsListingPage from '../../modules/organizations/configs/pages/listingPage/contracts/organizations.organization.contracts.listingPage.config';
import EAgroidRoute from '../../routes/AgroidRoute.enum';
import { ProtectedRoute } from '../../modules/common/components/routing/ProtectedRoute';
import { EAccessLoadingStatus } from '../../stores';
import { AccessRuleUserActionName } from '../../types';
import { FullscreenPreloaderService } from '../../services/fullscreenPreloader.service';

import { OrgEmployes } from './pages/OrgEmployes';
import { OrgApplications } from './pages/OrgApplications';
import { OrgRoles } from './pages/OrgRoles';
import { OrgRequisites } from './pages/OrgRequisites';
import { OrgProfile } from './pages/OrgProfile';
import { useOrgMenu } from './useOrgMenu';
import { useRolesMenu } from './useRolesMenu';

export interface IParams {
  orgId?: string;
}

export const OrgRouter: FC<any> = observer(() => {
  const { roles, setOrganization, fetchRoles } = useStore(RolesStore);
  const { getAccessRulesByModule, accessRules } = useStore(CheckAccessStore);
  const { displayPreloader, hidePreloader, isPreloaderOpen } = useStore(FullscreenPreloaderService);
  const [visible, setVisible] = useState(false);
  const { orgId } = useParams<IParams>();
  const match = useRouteMatch(AgroidRoutes.OrganizationRole);
  const matchProfile = useRouteMatch(AgroidRoutes.Organization);
  const orgItems = useOrgMenu(orgId);
  const roleItems = useRolesMenu(orgId, roles);
  const menuItems = match && match.isExact ? roleItems : orgItems;

  useEffect(() => {
    setOrganization(orgId);
    fetchRoles();
    displayPreloader();
    getAccessRulesByModule(orgId, 'user').finally(() => hidePreloader());
  }, [orgId]);

  const submenu = useMemo(() => {
    return matchProfile.isExact
      ? [
          // {
          //   caption: (
          //     <Grid>
          //       <Row justifyContent="center">
          //         <Col size={10}>
          //           <Button isDanger isStretched onClick={() => setVisible(true)}>
          //             Удалить
          //           </Button>
          //         </Col>
          //       </Row>
          //     </Grid>
          //   ),
          //   hasBrandmark: true,
          // },
        ]
      : [];
  }, [matchProfile]);

  const {
    link: contractsLink,
    Component: ContractsComponent,
  } = organizationsOrganizationContractsListingPage;

  if (isPreloaderOpen) return null;

  return (
    <>
      <SidebarLayout>
        <SidebarMenu items={menuItems.concat(submenu)} actionList={accessRules} />
        <Layout>
          <Switch>
            <Route path={AgroidRoutes.OrganizationEmployes} component={OrgEmployes} />
            <Route path={contractsLink.path} component={ContractsComponent} />
            <ProtectedRoute
              exact
              path={AgroidRoutes.OrganizationInvites}
              component={OrgApplications}
              accessRule={[AccessRuleUserActionName.manageInvites]}
              redirect={generatePath(AgroidRoutes.Organization, { orgId })}
            />
            <Route path={AgroidRoutes.OrganizationRequisites} component={OrgRequisites} />
            <ProtectedRoute
              accessRule={[AccessRuleUserActionName.editRole]}
              exact
              path={AgroidRoutes.OrganizationRoles}
              component={OrgRoles}
              redirect={generatePath(AgroidRoutes.Organization, { orgId })}
            />
            {/* <Route exact path={AgroidRoutes.OrganizationRoles} component={OrgRoles} /> */}
            <Route exact path={AgroidRoutes.OrganizationRole} component={RolePage} />
            <Route path={AgroidRoutes.Organization} component={OrgProfile} />
            <Redirect to={generatePath(AgroidRoutes.Organization, { orgId })} />
          </Switch>
        </Layout>
      </SidebarLayout>
      {visible && <DelOrgModal orgId={orgId} onClose={() => setVisible(false)} />}
    </>
  );
});
