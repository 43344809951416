import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeService, TypePromo, TypeCategory } from '../../../api/models/service.model';

import { OrganizationStore } from './organization.store';

interface IRedirectTokens {
  [key: string]: string;
}
@provide.singleton()
export class ServicesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationStore)
  protected organizationStore: OrganizationStore;

  constructor() {
    makeAutoObservable(this);
  }

  protected idToPromo: Map<string, TypePromo> = new Map();

  get promos() {
    return Array.from(this.idToPromo.values());
  }

  fetchPromo = () => {
    this.axios.api
      .getServicePromo({})
      .then(response => {
        runInAction(() => {
          response.forEach(promo => {
            this.idToPromo.set(promo.id, promo);
          });
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  protected idToServices: Map<string, TypeService> = new Map();

  get services() {
    return Array.from(this.idToServices.values());
  }

  currentPage = 0;
  totalPages = 0;

  fetchServices = () => {
    this.axios.api
      .getServices(
        {
          size: 16,
          page: this.currentPage,
          search: this.searchQuery,
          categoryId: this.categoryId,
        },
        { omit: ['size', 'page'] }
      )
      .then(response => {
        runInAction(() => {
          this.currentPage = response.number;
          this.totalPages = response.totalPages;
          this.isPaginationLoading = false;
          if (this.lastOperation === 'load') {
            this.idToServices.clear();
          }
          response.content.forEach(service => this.idToServices.set(service.id, service));
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  get isPaginationalAvailable() {
    return this.currentPage < this.totalPages;
  }

  lastOperation: 'load' | 'additional_load';

  isPaginationLoading = false;

  additionalLoad = () => {
    this.isPaginationLoading = true;
    this.lastOperation = 'additional_load';
    this.currentPage += 1;
    this.fetchServices();
  };

  load = () => {
    this.lastOperation = 'load';
    this.currentPage = 0;
    this.fetchServices();
  };

  protected idToCategory: Map<string, TypeCategory> = new Map();

  get categories() {
    return [
      { categoryId: '', categoryName: 'Не выбрано' },
      ...Array.from(this.idToCategory.values()),
    ];
  }

  fetchCategories = () => {
    this.axios.api
      .getDictServiceCategories({})
      .then(response => {
        runInAction(() => {
          response.content.forEach(category =>
            this.idToCategory.set(category.categoryId, category)
          );
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  reset = () => {
    this.idToCategory.clear();
    this.idToServices.clear();
    this.idToPromo.clear();
    this.currentPage = 0;
  };

  searchQuery = '';

  setSearchQuery = (v: string) => {
    this.searchQuery = v;

    this.idToServices.clear();
    this.currentPage = 0;

    this.fetchServices();
  };

  categoryId = '';

  setCategoryId = (v: string) => {
    this.categoryId = v;

    this.idToServices.clear();
    this.currentPage = 0;
    this.fetchServices();
  };

  redirectToService = async (
    systemId: string,
    redirectUrl: string,
    redirectAdditionalToken?: IRedirectTokens
  ) => {
    try {
      const response = await this.axios.api.erqRedirectData({
        systemId,
      });
      if (response.authKey) {
        let referenceURL = `${response.redirectUrl || redirectUrl}?authKey=${response.authKey}`;

        if (redirectAdditionalToken) {
          referenceURL = referenceURL.concat(
            `&${Object.keys(redirectAdditionalToken)
              .map(key => `${key}=${redirectAdditionalToken[key]}`)
              .join('&')}`
          );
        }
        window.location.href = referenceURL;
      }
    } catch (e) {
      throw e;
    }
  };
}
