import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios } from '../../../utils/axios2';
import { TypeRole, TypeRoleSettings } from '../../../api/models/role.model';

@provide.singleton()
export class RolesStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  _isLoading = false;
  organizationId = '';
  roles = [];
  roleSettings: Partial<TypeRoleSettings> = {};
  modules = [];
  defaultRole: TypeRoleSettings | null = null;

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  setOrganization = organizationId => {
    this.organizationId = organizationId;
  };

  fetchRoles = () => {
    this.isLoading = true;
    return this.axios.api
      .getRolesList({
        organizationId: this.organizationId,
      })
      .then(response => {
        runInAction(() => {
          if (response && response.length) {
            this.roles = response.reduce((acc, item: TypeRole) => {
              const data = { ...item.role, employeeCount: item.employeeCount };

              if (item.role.isOwner) {
                return [data, ...acc];
              }

              return [...acc, data];
            }, []);

            this.defaultRole = response.find(role => role.role.isDefault)?.role;
          }
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  assignUsers = async (roleId: string, employeeIds: string[]) => {
    this.isLoading = true;
    await this.axios.api
      .assignUsersToRole({ id: roleId, employeeIds }, { omit: ['id'] })
      .finally(() => {
        this.isLoading = false;
      });
  };

  assignScouts = async (roleId: string, employeeIds: string[]) => {
    this.isLoading = true;
    // toDo ждем переделку ручки от бэка
    await this.axios.api.assignRoleToScout({ roleId, userIds: employeeIds }).finally(() => {
      this.isLoading = false;
    });
  };

  fetchRoleSettings = roleId => {
    this.isLoading = true;
    return this.axios.api
      .getRole({ id: roleId }, { omit: ['id'] })
      .then(response => {
        runInAction(() => {
          this.roleSettings = response;
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  fetchModulesAll = organizationId => {
    this.isLoading = true;
    return this.axios.api
      .getModuleAll({ organizationId }, { omit: ['organizationId'] })
      .then(response => {
        runInAction(() => {
          this.modules = response;
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  createRole = async (model: any) => {
    try {
      this.isLoading = true;
      await this.axios.api.createRole(model, { omit: ['organizationId'] });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  removeRole = async (roleId: string) => {
    try {
      await this.axios.api.removeRole({
        roleId,
      });
    } catch (e) {
      throw e;
    }
  };

  updateRole = async (model: any) => {
    try {
      await this.axios.api.updateRole(model);
    } catch (e) {
      throw e;
    }
  };
}
