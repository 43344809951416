import styled from 'styled-components';

import { Colors } from '../../../../../constants/colors';

export const Wrapper = styled.div<{ hasError?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .rdtDays {
    background: ${Colors.white};
  }
  .rdtPicker {
    position: absolute;
    z-index: 999;
    top: 40px;
  }

  .date-picker {
    border-width: 1px;
    ${({ hasError }) => hasError && `border-color:${Colors.red}`}
  }
`;

type StyledProps = {
  verticalGap?: boolean;
};

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.darkGray};
`;

export const Icon = styled.div<StyledProps>`
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 16px;
  top: 10px;
  svg {
    fill: ${Colors.black};
  }
`;

export const Placeholder = styled.p<StyledProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${Colors.darkGray};
  line-height: 16px;
`;

export const DateInput = styled.div`
  border: 0.5px solid ${Colors.grayBorder};
  box-sizing: border-box;
  border-radius: 12px;
  background: ${Colors.gray};
  width: 100%;
  height: 40px;
  outline: none;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: relative;
`;

export const Error = styled.div`
  font-size: 12px;
  padding: 4px 0;
  color: ${Colors.red};
`;
