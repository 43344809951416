import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ModalContainer } from '@farmlink/farmik-ui';

import { Axios, useStore } from '../../utils';
import { SessionStore } from '../auth/stores/session.store';
import { AuthorizationRoutes } from '../auth/routes';

import { AgroidWrapper } from './AgroidWrapper';
import { AgroidRoutes } from './routes';
import {
  IndexPage,
  OrgsRouter,
  UsersPage,
  SupportPage,
  ProfilePage,
  InterestsPage,
  CompetenciesPage,
} from './pages';
import { TPageListData } from './configs/types/pages/page.config';

type TAgroidRouterProps = {
  pageListData: TPageListData;
};

export const AgroidRouter: FC<TAgroidRouterProps> = observer(({ pageListData }) => {
  const { checkIsLoggedIn, isLoggedIn } = useStore(SessionStore);
  const [checked, setChecked] = useState(false);
  const { needAdditionalInfo } = useStore(Axios);

  useEffect(() => {
    checkIsLoggedIn().then(() => {
      setChecked(true);
    });
  }, []);

  if (checked && !isLoggedIn) {
    return <Redirect to={AuthorizationRoutes.signIn} />;
  }

  if (needAdditionalInfo) {
    return <Redirect to="/sign_up/?step=2" />;
  }
  // 123
  return (
    <AgroidWrapper linkList={pageListData.pageLinkList}>
      <Switch>
        <Route path={AgroidRoutes.Organizations} component={OrgsRouter} />
        {pageListData.pageList.map(({ uniqueKey, link, Component }) => (
          <Route key={uniqueKey} path={link.path} component={Component} />
        ))}
        <Route path={AgroidRoutes.Users} component={UsersPage} />
        <Route path={AgroidRoutes.Support} component={SupportPage} />
        <Route path={AgroidRoutes.Profile} component={ProfilePage} />
        <Route path={AgroidRoutes.EditCompetitionsData} component={CompetenciesPage} />
        <Route path={AgroidRoutes.EditInterestsData} component={InterestsPage} />
        <Route path={AgroidRoutes.Index} component={IndexPage} />
      </Switch>
      <ModalContainer />
    </AgroidWrapper>
  );
});
