import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { Axios, TypeApiResponse } from '../../../../../utils/axios2';
import { checkIsEmail } from '../../../../../utils/checkIsEmail';
import { checkIsName } from '../../../../../utils/checkIsName';
import { Field } from '../../../../../constants/field';
import { emptyObject } from '../../../../../constants/emptyField';
import {
  DATE_INCORRECT_VALUE,
  NAME_USING_RESTRICTED_CHARS,
  SMS_CODE_INCORRECT,
} from '../../../../../constants/error';

export enum SignUpStepEnum {
  FIRST = 'firstStep',
  SECOND = 'secondStep',
}

export type SignUpStep = keyof SignUpStepEnum;

type DateTimeValue = string | Date | number;
const dateBefore = (val: DateTimeValue, beforeDate = new Date()) =>
  new Date(val).getTime() <= new Date(beforeDate).getTime();

const RESEND_CODE_SECONDS = 60;

@provide.singleton()
export class SingUpStore {
  @lazyInject(Axios)
  protected axios: Axios;

  needAdditionalInfo = true;

  constructor() {
    makeAutoObservable(this);
  }

  signUpStep: SignUpStepEnum = SignUpStepEnum.FIRST;
  isRightsAccepted = false;

  timer: any;
  seconds = RESEND_CODE_SECONDS;

  countryCode = '+7';

  phone: Field = {
    error: '',
    value: '',
  };

  code: Field = {
    error: '',
    value: '',
  };
  isCodeFieldOpened = false;

  operationId = '';

  isVerifyCodeLoading = false;

  setSignUpStep = (v: SignUpStepEnum) => {
    this.signUpStep = v;
  };

  setRightAccepted = (v: boolean) => {
    this.isRightsAccepted = v;
  };

  setTimer = () => {
    this.seconds = RESEND_CODE_SECONDS;
    this.timer = setInterval(() => {
      this.seconds -= 1;

      if (this.seconds === 0) clearInterval(this.timer);
    }, 1000);
  };

  get purePhoneNumber() {
    let pureString = this.phone.value.substr(this.countryCode.length);
    pureString = pureString.replace(/\s/g, '');
    pureString = pureString.replace(/[(_)]/g, '');
    return pureString;
  }

  get isFirstStepDisabled() {
    return this.purePhoneNumber.length !== 10;
  }

  get isSecondStepEnabled() {
    if (this.isInvitedRegistration) {
      return Boolean(
        this.lastName.value &&
          this.firstName.value &&
          this.birthDate.value &&
          dateBefore(this.birthDate.value) &&
          this.gender.value &&
          this.isRightsAccepted
      );
    }

    return Boolean(
      this.lastName.value &&
        this.firstName.value &&
        this.birthDate.value &&
        (checkIsEmail(this.email.value) || localStorage.getItem('externalKey')) &&
        this.gender.value &&
        this.isRightsAccepted
    );
  }

  setCountryCode = (v: string) => {
    this.countryCode = v;
  };

  setIsCodeFieldOpened = (v: boolean) => {
    this.isCodeFieldOpened = v;
  };

  setFieldValue = (fieldName: 'phone' | 'code', externalRedirect?: boolean) => (value: string) => {
    this[fieldName].value = value;

    this[fieldName].error = '';
    if (fieldName === 'code') {
      if (this.code.value.length === 6) {
        this.verifyCode(externalRedirect);
      }
    }
  };

  sendCodeToPhone = () => {
    return this.axios.api
      .loginByPhone({
        phoneNumber: this.purePhoneNumber,
        phoneCode: this.countryCode,
      })
      .then(response => {
        runInAction(() => {
          this.setTimer();
          this.operationId = response.operId;
          this.setIsCodeFieldOpened(true);
        });
      })
      .catch((response: { errors?: Array<{ source: string; title: string }> }) => {
        if (!response.errors) {
          return;
        }

        response.errors.forEach(field => {
          if (field.source === 'phoneNumber') {
            this.phone.error = field.title;
          }
        });
      });
  };

  isCodeWasValid = false;

  verifyCode = async (externalSystemAuth?: boolean) => {
    if (externalSystemAuth) {
      console.error('verifyCode no user var', externalSystemAuth);
    }
    this.isVerifyCodeLoading = true;

    try {
      const response: TypeApiResponse<'verifySmsCodeV2'> | null = await this.axios.api.verifySmsCodeV2(
        { code: this.code.value, operId: this.operationId }
      );
      if (!response['access-token']) {
        return;
      }

      this.needAdditionalInfo = response.needAdditionalInfo;

      localStorage.setItem('refreshToken', response['refresh-token']);
      localStorage.setItem('accessToken', response['access-token']);

      if (this.needAdditionalInfo) {
        this.setSignUpStep(SignUpStepEnum.SECOND);
      }
      this.isCodeWasValid = true;
    } catch (e: any) {
      this.isCodeWasValid = false;

      this.code = {
        value: this.code.value,
        error: SMS_CODE_INCORRECT,
      };
    } finally {
      this.isVerifyCodeLoading = false;
    }
  };

  isInvitedRegistration = false;

  setIsInvitedRegistration = (isInvitedRegistration: boolean) => {
    this.isInvitedRegistration = isInvitedRegistration;
  };

  updateProfileWithEmail = () =>
    this.axios.api
      .updateProfileWithEmail({
        gender: this.gender.value,
        lastName: this.lastName.value,
        firstName: this.firstName.value,
        secondName: this.secondName.value,
        dateOfBirth: this.birthDate.value,
        email: this.email.value,
        agreement: this.isRightsAccepted,
      })
      .then(() => {
        localStorage.removeItem('externalKey');
        localStorage.removeItem('externalEmail');
      });

  updateProfile = () =>
    this.axios.api
      .updateProfile({
        gender: this.gender.value,
        lastName: this.lastName.value,
        firstName: this.firstName.value,
        secondName: this.secondName.value,
        dateOfBirth: this.birthDate.value,
        agreement: this.isRightsAccepted,
      })
      .then(() => {
        localStorage.removeItem('externalKey');
        localStorage.removeItem('externalEmail');
      });

  firstName: Field = {
    error: '',
    value: '',
  };
  lastName: Field = {
    error: '',
    value: '',
  };
  secondName: Field = {
    error: '',
    value: '',
  };
  gender: Field = {
    error: '',
    value: '',
  };
  email: Field = {
    error: '',
    value: '',
  };
  birthDate: Field = {
    error: '',
    value: '01.01.1995',
  };

  setBirthDate = (v: Date) => {
    if (v.getTime() > new Date().getTime()) {
      this.birthDate.error = DATE_INCORRECT_VALUE;
    } else {
      this.birthDate.error = '';
    }

    const date = new Date(v);
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();

    this.birthDate.value = `${day.length === 2 ? day : `0${day}`}.${
      month.length === 2 ? month : `0${month}`
    }.${date.getFullYear()}`;
  };

  setEmail = (v: string) => {
    this.email.value = v;
  };

  setFirstName = (v: string) => {
    if (!checkIsName(v) && v) {
      this.firstName.error = NAME_USING_RESTRICTED_CHARS;
    } else {
      this.firstName.error = '';
    }

    this.firstName.value = v;
  };

  setSecondName = (v: string) => {
    if (!checkIsName(v) && v) {
      this.secondName.error = NAME_USING_RESTRICTED_CHARS;
    } else {
      this.secondName.error = '';
    }

    this.secondName.value = v;
  };

  setLastName = (v: string) => {
    if (!checkIsName(v) && v) {
      this.lastName.error = NAME_USING_RESTRICTED_CHARS;
    } else {
      this.lastName.error = '';
    }
    this.lastName.value = v;
  };

  setGender = (v: string) => {
    this.gender.value = v;
  };

  sendVerifyEmail = () =>
    this.axios.api.sendEmailVerification({ email: this.email.value }, { omit: ['email'] });

  submitSecondStep = (transferEmail?: string) => {
    if (this.isInvitedRegistration && this.email.value) {
      return new Promise((resolve, reject) => {
        Promise.all([this.updateProfileWithEmail()]).then(resolve).catch(reject);
      });
    }

    if (transferEmail) {
      return new Promise((resolve, reject) => {
        Promise.all([this.updateProfile()]).then(resolve).catch(reject);
      });
    }

    if (this.email.value) {
      return new Promise((resolve, reject) => {
        Promise.all([this.updateProfileWithEmail()]).then(resolve).catch(reject);
      });
    }

    return new Promise((resolve, reject) => {
      Promise.all([this.updateProfile()]).then(resolve).catch(reject);
    });
  };

  get isAvailableToVerifyCode() {
    return this.code.value.length === 6 && !this.isVerifyCodeLoading && this.isRightsAccepted;
  }

  resetSession = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
  };

  reset = () => {
    this.resetFirstStep();
    this.resetSecondStep();
  };

  resetPhone = () => {
    this.phone = emptyObject;
  };

  resetFirstStep = () => {
    this.isCodeFieldOpened = false;
    this.operationId = '';
    this.isRightsAccepted = false;
    this.code = {
      value: '',
      error: '',
    };
  };

  resetSecondStep = () => {
    this.firstName = emptyObject;
    this.lastName = emptyObject;
    this.secondName = emptyObject;
    this.email = emptyObject;
    this.birthDate = emptyObject;
    this.gender = emptyObject;
  };
}
