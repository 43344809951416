import styled from "styled-components";

import { Colors } from "../../../../../../constants/colors";

export const InviteNotAuthStatusSentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    alignt-items: center;
    width: 100%;
    padding: 30px 50px;
    margin: 20px;
    background-color: ${Colors.white};
    border-radius: 16px;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
        width: 440px;
    }
`;

export const TopBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 30px 0;
    border-bottom: 1px solid ${Colors.grayBorder};
`;

export const OrgLogo = styled.div<{imgUrl?: string}>`
    background-image: url(${({ imgUrl }) => imgUrl});
    background-size: cover;
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
`;

export const OrgName = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
`;

export const Title1 = styled.div`
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    padding: 30px 0;
`;

export const Title2 = styled.div`
    font-size: 14px;
    font-weight: 400;
    text-align: center;
`;

export const ActionsBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    alignt-items: center;
    width: 100%;
`;

export const GoToSignupButtonWrapper = styled.div`
    width: 100%;
    padding: 30px 0;
    & > button {
        display: inline-block;
        cursor: pointer;
    }
`;

export const SeparatorLine = styled.div`
    width: 100%;
    heigth: 1px;
    border-bottom: 1px solid ${Colors.grayBorder};
`;

export const GoToSignInBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 30px;
`;

export const GoToSignInText = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${Colors.darkGray};
`;

export const GotoSignInAction = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: ${Colors.green};
`;

export const GoToSignIn = styled.div`
    margin-right: 5px;
`;

export const RightArrow = styled.div``;