import React, { FC, SVGProps, useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Well } from '@zendeskgarden/react-notifications';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';
import { isEmpty } from 'lodash';

import { useStore } from '../../../../../utils';
import { TypeUserStatusLabels } from '../../../../../api/models/user.model';
import { OrganizationStore } from '../../../stores/organization.store';
import {
  WellStyled,
  WellFilterStyled,
  NoDataPlaceholder,
  StyledNoDataIcon,
  NoDataTitle,
} from '../styled';
import { AccessRuleVisibility, AccessRuleUserActionName } from '../../../types';
import {
  WellHeader,
  CardsList,
  UserCard,
  UsersFilter,
  NewUserModal,
  NewUserModallSuccess,
  UserDetailsModal,
  BlockConfirmModal,
  UnBlockConfirmModal,
} from '../../../components';
import SearchIcon from '../icons/search.svg';

export interface IOrgEmployesParams {
  orgId?: string;
}

export interface IOrgEmployesAction {
  icon?: FC<SVGProps<SVGSVGElement>> | FC;
  caption: string;
  component: FC;
  componentProps: { [key: string]: any };
}

export const orgActions: IOrgEmployesAction[] = [];

export const OrgEmployes: FC = observer(() => {
  const {
    isLoading,
    usersFilter,
    setOrganizationId,
    setFilter,
    getOrganizationUsers,
    usersList,
    // resinstateUser,
    // removeUserFromOrg,
  } = useStore(OrganizationStore);
  const [visible, setVisible] = useState(false);
  const [visibleUserId, setVisibleUserDetails] = useState<boolean | string>(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleBlockConfirm, setVisibleBlockConfirm] = useState<boolean | string>(false);
  const [visibleUnBlockConfirm, setVisibleUnBlockConfirm] = useState<boolean | string>(false);
  const { orgId } = useParams<IOrgEmployesParams>();

  const actions = useMemo(
    () => [
      {
        caption: isMobile ? false : 'Добавить',
        component: isMobile ? IconButton : Button,
        icon: isMobile && PlusIcon,
        dataTestIdAction: 'organization-employes-add-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.manageInvites,
      },
    ],
    []
  );

  const handleChange = filter => {
    setFilter({
      organizationID: orgId,
      ...filter,
    });
  };

  const handleUserDetails = (event, userId) => {
    event.preventDefault();
    setVisibleUserDetails(userId);
  };

  useEffect(() => {
    setFilter({ organizationID: orgId });
    setOrganizationId(orgId);
  }, [orgId]);

  useEffect(() => {
    getOrganizationUsers();
  }, [JSON.stringify(usersFilter)]);

  const statusOptions = [{ value: '', label: 'Все статусы' }].concat(
    Object.keys(TypeUserStatusLabels).map(key => ({
      value: key,
      label: TypeUserStatusLabels[key],
    }))
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Well>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </Well>
      );
    }

    if (!isEmpty(usersList)) {
      return (
        <CardsList
          list={usersList.map(user => ({
            ...user,
            onClick: event => handleUserDetails(event, user.id),
          }))}
          card={UserCard}
          cardProps={{
            padding: 'ssm',
          }}
          dataTestId="employes"
        />
      );
    }

    return (
      <NoDataPlaceholder className="with-filter">
        <StyledNoDataIcon src={SearchIcon} />
        <NoDataTitle>Пользователь не найден. Попробуйте изменить условия поиска</NoDataTitle>
      </NoDataPlaceholder>
    );
  };

  return (
    <>
      <WellStyled>
        <WellHeader
          title="Сотрудники организации"
          actions={actions}
          dataTestId="organization-employes"
        />
        <WellFilterStyled>
          <UsersFilter onChange={handleChange} statusOptions={statusOptions} />
        </WellFilterStyled>
      </WellStyled>

      {renderContent()}

      {visible && (
        <NewUserModal
          orgId={orgId}
          onClose={result => {
            setVisible(false);
            if (result === true) {
              setVisibleSuccess(true);
            }
          }}
        />
      )}

      {visibleUserId && (
        <UserDetailsModal
          title="Сотрудник"
          userId={visibleUserId as string}
          orgId={orgId}
          onClose={result => {
            if (result === 'blockConfirm') {
              setVisibleBlockConfirm(visibleUserId);
            }
            if (result === 'unblockConfirm') {
              setVisibleUnBlockConfirm(visibleUserId);
            }
            setVisibleUserDetails(false);
          }}
        />
      )}

      {visibleSuccess && (
        <NewUserModallSuccess
          onClose={() => {
            setVisibleSuccess(false);
            getOrganizationUsers();
          }}
        />
      )}

      {visibleBlockConfirm && (
        <BlockConfirmModal
          userId={visibleBlockConfirm as string}
          orgId={orgId}
          onClose={() => {
            setVisibleBlockConfirm(false);
            getOrganizationUsers();
          }}
        />
      )}

      {visibleUnBlockConfirm && (
        <UnBlockConfirmModal
          userId={visibleUnBlockConfirm as string}
          onClose={() => {
            setVisibleUnBlockConfirm(false);
            getOrganizationUsers();
          }}
        />
      )}
    </>
  );
});
