import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';

import { lazyInject, provide } from '../../../utils/IoC';
import { Axios } from '../../../utils/axios2';
import { AccessRule } from '../../../api/models/user.model';

export enum EAccessLoadingStatus {
  pending,
  fulfilled,
  error,
}

export enum EAccessStore {
  local,
  global,
}

@provide.singleton()
export class CheckAccessStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  accessRules: Array<AccessRule> = [];
  globalAccessRules: Array<AccessRule> = [];
  isLoading = false;
  _isOrganizationOwner = false;
  _globalAccessRulesLoadingStatus: EAccessLoadingStatus;
  _accessRulesLoadingStatus: EAccessLoadingStatus;

  getIsLoading() {
    return this.isLoading;
  }

  setIsLoading = (flag: boolean) => {
    this.isLoading = flag;
  };

  get isOrganizationOwner() {
    return this._isOrganizationOwner;
  }

  getIsOrganizationOwner() {
    return this._isOrganizationOwner;
  }

  setIsOrganizationOwner = (flag: boolean) => {
    this._isOrganizationOwner = flag;
  };

  getGlobalAccessRules = () => {
    return this.setGlobalAccessRules;
  };

  getUserHasGlobalAccessRule = (accessRule: string) => {
    return Boolean(this.globalAccessRules.find(rule => rule.code === accessRule));
  };

  /**
   * Поиск экшена по не глобальному хранилищу экшенов
   *
   * @param {string} accessRule
   * @param {boolean} [findAll] искать по всем хранилищам экшенов
   * @memberof CheckAccessStore
   */
  getUserHasAccessRule = (accessRule: string, findAll?: boolean) => {
    if (findAll) {
      return Boolean(
        this.accessRules.find(rule => rule.code === accessRule) ||
          this.globalAccessRules.find(rule => rule.code === accessRule)
      );
    }
    return Boolean(this.accessRules.find(rule => rule.code === accessRule));
  };

  getUserHasAccessRulesArray = (accessRules: string[], findAll?: boolean) => {
    const availableAccessRules = this.accessRules.reduce((acc, { available, code }) => {
      if (available) {
        acc.push(code);
      }
      return acc;
    }, []);

    const accessRulesToCompare = findAll
      ? [...availableAccessRules, ...this.globalAccessRules.map(({ code }) => code)]
      : [...availableAccessRules];

    const userRules = _.intersection(accessRulesToCompare, accessRules);

    return Boolean(userRules.length);
  };

  setGlobalAccessRules = (accessRules: AccessRule[]) => {
    this.globalAccessRules = accessRules;
  };

  get accessRulesLoadingStatus() {
    return this._accessRulesLoadingStatus;
  }

  get globalAccessRulesLoadingStatus() {
    return this._globalAccessRulesLoadingStatus;
  }

  setLoadingStatus = (target: EAccessStore, loadingStatus: EAccessLoadingStatus) => {
    if (target === EAccessStore.global) {
      this._globalAccessRulesLoadingStatus = loadingStatus;
    }
    if (target === EAccessStore.local) {
      this._accessRulesLoadingStatus = loadingStatus;
    }
  };

  getAccessRulesByModule = (organizationId: string, moduleCode: string) => {
    this.isLoading = true;
    this.setLoadingStatus(EAccessStore.local, EAccessLoadingStatus.pending);
    return this.axios.api.checkAccessByModule({ organizationId, moduleCode }).then(response => {
      runInAction(() => {
        this.setLoadingStatus(EAccessStore.local, EAccessLoadingStatus.fulfilled);
        this.accessRules = response;
        this.isLoading = false;
      });
    });
  };

  getAccessRulesByCodePrefix = (organizationId: string, codePrefix?: string) => {
    this.isLoading = true;
    this.setLoadingStatus(EAccessStore.local, EAccessLoadingStatus.pending);
    this.axios.api.checkAccessByCodePrefix({ organizationId, codePrefix }).then(response => {
      runInAction(() => {
        this.setLoadingStatus(EAccessStore.local, EAccessLoadingStatus.fulfilled);
        this.accessRules = response;
        this.isLoading = false;
      });
    });
  };

  clearGlobalAccessRules = () => {
    this.globalAccessRules = [];
  };
}
