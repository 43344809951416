import styled from 'styled-components';

export const AlreadyMemberError = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #f29993;
  margin: -25px 0 20px;
`;

export const InputErrorColorizer = styled.div`
  & + div > input[name='email'] {
    border: 1px solid #f29993;
  }
`;
