import { isEmpty } from 'lodash';

import { InitialValues } from '../../../../../api/models/contractor.model';

function getValuesCreate(orgTypesOptions, scoutsOptions, user): InitialValues {
  return {
    orgTypeId: !isEmpty(orgTypesOptions) ? orgTypesOptions[0].value : 1,
    logo: {
      downloadUrl: '',
      fileName: '',
      id: '',
    },
    name: '',
    INN: '',
    addressJur: '',
    addressFact: '',
    OGRN: '',
    OKVED: '',
    OKPO: '',
    KPP: '',
    contact: '',
    manager:
      !isEmpty(scoutsOptions) && !isEmpty(user) ? '35291d2e-79fe-4876-ba47-14c528688f9f' : '',
    email: '',
    phone: '',
    newOwner: '',
    representativePosition: '',
    isScout: true,
  };
}

export { getValuesCreate };
