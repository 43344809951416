import { FC } from 'react';
import { observer } from 'mobx-react';
import { AutoForm, AutoField, ErrorField } from 'uniforms-unstyled';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { ReactComponent as EndIcon } from '@zendeskgarden/svg-icons/src/16/x-circle-stroke.svg';

import { bridge as schema } from './schema';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  dataTestId: string;
}

export const ModalSearch: FC<Props> = observer(({ value, onChange, onClear, dataTestId }) => {
  return (
    <AutoForm schema={schema} data-test-id={`${dataTestId}-form`}>
      <Grid>
        <Row>
          <Col xs={12} textAlign="start">
            <AutoField
              name="search"
              value={value}
              onChange={onChange}
              end={EndIcon}
              onClickEndIcon={onClear}
              hideEndIconEmptyValue
            />
            <ErrorField name="search" />
          </Col>
        </Row>
      </Grid>
    </AutoForm>
  );
});
