import { FC, useState, useEffect, HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { isEmpty } from 'lodash';

import { useStore } from '../../../../utils';
import { ReactComponent as ContactSvg } from '../../../../assets/images/agroid/contact.svg';
import { OrganizationStore } from '../../stores/organization.store';
import { UserDetailsModal } from '../';

import { UsersBlockWrapper, UserBodyStyled, NoDataStyled, LoadderWrapper } from './styled';
import { UserRow } from './UserRow';

export interface RoleUsersProps extends HTMLAttributes<HTMLDivElement> {
  orgId: string;
  roleId: string;
}

export const RoleUsers: FC<RoleUsersProps> = observer(({ orgId, roleId }) => {
  const { isLoading, setFilter, getOrganizationUsers, usersList = [] } = useStore(
    OrganizationStore
  );
  const [userId, setVisible] = useState<boolean | string>(false);

  const handleUserDetails = id => {
    // todo скрыл для демо
    setVisible(id);
  };

  useEffect(() => {
    setFilter({
      organizationID: orgId,
      roleId,
    });
    getOrganizationUsers();
  }, [orgId, roleId]);

  if (isLoading) {
    return (
      <LoadderWrapper>
        <Skeleton height="12px" />
        <Skeleton height="12px" />
        <Skeleton height="12px" />
      </LoadderWrapper>
    );
  }

  return (
    <UsersBlockWrapper className="role-user-wrapper">
      <UserBodyStyled>
        {!isEmpty(usersList) ? (
          usersList.map(user => <UserRow key={user.id} {...user} onClick={handleUserDetails} />)
        ) : (
          <NoDataStyled
            height="50vh"
            icon={ContactSvg}
            message="Вы ещё не добавили сотрудников на эту роль"
          />
        )}

        {userId && (
          <UserDetailsModal
            title="Сотрудник"
            userId={userId as string}
            orgId={orgId}
            onClose={() => setVisible(false)}
          />
        )}
      </UserBodyStyled>
    </UsersBlockWrapper>
  );
});
