type PhoneNumberType = {
  phoneNumberCode?: string;
  phoneNumber?: string;
};

const NO_PHONE_MESSAGE = 'Телефон не указан';

export const formatPhone = (
  phone: PhoneNumberType | string | undefined | false,
  noPhoneMessage = NO_PHONE_MESSAGE
) => {
  if (!phone) {
    return noPhoneMessage;
  }
  const { phoneNumberCode, phoneNumber } = phone as PhoneNumberType;
  if (!phoneNumber) {
    return noPhoneMessage;
  }
  const phoneNumberFormatted = phoneNumber.replace(/(\d*)(\d{3})(\d{2})(\d{2})$/, '($1) $2 $3 $4');
  return `${phoneNumberCode} ${phoneNumberFormatted}`;
};

export const formatPhoneFromStr = (phone: string | undefined, noPhoneMessage = NO_PHONE_MESSAGE) => {
  if (!phone) {
    return noPhoneMessage;
  }
  const phoneNumberFormatted = phone.replace(/(\d*)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3 $4 $5');
  return phoneNumberFormatted;
};
