import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../../utils';
import { Axios, TypeApiResponse } from '../../../utils/axios2';
import { TypeOrganization, OrgType } from '../../../api/models/organization.model';
import { TypeRequest as UserTypeRequest } from '../../../api/endpoints/getOrganizationUsers';
import { ApplicationsTypeRequest } from '../../../api/endpoints/getOrganizationApplications';
import { formatOKVEDtoSent } from '../../../utils/formatOKVEDtoSent';

@provide.singleton()
export class OrganizationStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  organizationId: string;
  organization: (TypeOrganization & { phone?: string; email?: string }) | undefined = undefined;
  _isLoading = false;
  usersList = [];
  applicationsList = [];
  usersFilter: Partial<UserTypeRequest> = {
    status: 'ACTIVE',
  };

  logoHash = '';
  logoImgId = '';
  logoFile: File;

  set isLoading(flag: boolean) {
    this._isLoading = flag;
  }

  get isLoading() {
    return this._isLoading;
  }

  setOrganizationId = (organizationId: string) => {
    runInAction(() => {
      this.organizationId = organizationId;
    });
    this.usersFilter.organizationID = this.organizationId;
  };

  setLoading = (flag: boolean) => {
    this.isLoading = flag;
  };

  setFilter = (filterProps: Partial<UserTypeRequest>, replace = true) => {
    runInAction(() => {
      if (replace) {
        this.usersFilter.status = 'ACTIVE';
        this.usersFilter = filterProps;
      } else {
        Object.assign(this.usersFilter, filterProps);
      }
    });
  };

  fetchOrganization = async (organizationId: string) => {
    this.isLoading = true;
    try {
      const org = await this.axios.api.getOrganization(
        { organizationId },
        { omit: ['organizationId'] }
      );
      runInAction(() => {
        this.organization = org;
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  getOrganizationUsers = async () => {
    try {
      this.isLoading = true;
      const response = await this.axios.api.getOrganizationUsers(
        this.usersFilter as UserTypeRequest
      );
      runInAction(() => {
        this.usersList = response.content;
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  getOrganizationApplications = async () => {
    const appsFilter: ApplicationsTypeRequest = {
      organizationId: this.usersFilter.organizationID,
    };
    if (this.usersFilter.status) {
      appsFilter.status = this.usersFilter.status;
      if (!this.usersFilter.status || this.usersFilter.status === 'ACTIVE') {
        appsFilter.status = 'SENT';
      }
    }
    if (this.usersFilter.fullname) {
      appsFilter.fullname = this.usersFilter.fullname;
    }
    if (this.usersFilter.roleId) {
      appsFilter.roleId = this.usersFilter.roleId;
    }
    try {
      this.isLoading = true;
      const response = await this.axios.api.getOrganizationApplications(appsFilter);
      runInAction(() => {
        this.applicationsList = response.content;
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  // TODO its temporaty working decision - after will be changed
  getOrganizationFilteredUsers = async filter => {
    let list = [];
    try {
      const response = await this.axios.api.getOrganizationUsers(filter);
      list = response.content;
    } catch (e) {
      throw e;
    }
    return list;
  };

  createOrganization = async (model: TypeOrganization) => {
    try {
      this.isLoading = true;
      await this.axios.api.createOrganization({
        ...model,
        OKVED: model.OKVED ? formatOKVEDtoSent(model.OKVED) : undefined,
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  updateOrganization = async (model: any) => {
    try {
      this.isLoading = true;

      if (model.type) {
        model.orgTypeId = model.type.orgTypeId;
      }
      model.logo = this.logoHash || this.logoImgId || model?.logo?.id || '';
      delete model.type;
      delete model.canEdit;
      delete model.canViewInvites;
      delete model.sameAggresses;
      delete model.serviceProvider;

      await this.axios.api.updateOrganization({
        ...model,
        OKVED: model.OKVED ? formatOKVEDtoSent(model.OKVED) : undefined,
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  removeOrg = async (orgId: string) => {
    try {
      await this.axios.api.removeOrg({
        organizationId: orgId,
      });
    } catch (e) {
      throw e;
    }
  };

  inviteByEmail = async inviteDetails => {
    try {
      await this.axios.api.sendEmailInvite(inviteDetails);
    } catch (e) {
      throw e;
    }
  };

  getOrgTypes = async () => {
    let list: OrgType[] = [];
    try {
      list = await this.axios.api.getDictOrgTypes({});
    } catch (e) {
      throw e;
    }
    return list;
  };

  onCommitLogo = () => {
    this.logoHash = this.logoImgId;
  };

  onResetLogo = () => {
    this.logoHash = '';
    this.logoImgId = '';
    this.organization = {
      ...this.organization,
      logo: null,
    };
  };

  uploadFile = (file: File) => {
    this.logoFile = file;
    this.axios.api
      .uploadFile({
        ownerId: this.organizationId,
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      })
      .then(this.onUploadFileSuccess)
      .catch(this.onUploadFileError);
  };

  onUploadFileSuccess = (response: TypeApiResponse<'uploadFile'>) => {
    this.logoImgId = response.id;
    this.axios.api
      .uploadFileFinish({ data: this.logoFile, url: response.uploadUrl })
      .then(this.onUploadFileFinishSuccess)
      .catch(this.onUploadFileFinishError);
  };

  onUploadFileError = () => {
    console.log('onUploadFileError');
  };

  onUploadFileFinishSuccess = () => {
    console.log('File uploaded');
    // this.fetchProfile();
  };

  onUploadFileFinishError = () => {
    console.log('onUploadFileFinishError');
  };
}
