import { lazyInject, provide } from '../../../utils';
import { CheckAccessService } from '../services/checkAccess.service';
import { CheckAccessStore, EAccessLoadingStatus, EAccessStore } from '../stores';

@provide.singleton()
export class CheckAccessController {
  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(CheckAccessService)
  protected checkAccessService: CheckAccessService;

  getAllActiveGlobalActionsForCurrentUser = () => {
    this.checkAccessStore.setIsLoading(true);
    this.checkAccessStore.setLoadingStatus(EAccessStore.global, EAccessLoadingStatus.pending);
    return this.checkAccessService
      .getAllActiveGlobalActionsForCurrentUser()
      .then(() => {
        this.checkAccessStore.setLoadingStatus(EAccessStore.global, EAccessLoadingStatus.fulfilled);
      })
      .catch(() => {
        this.checkAccessStore.setLoadingStatus(EAccessStore.global, EAccessLoadingStatus.error);
      })
      .finally(() => {
        this.checkAccessStore.setIsLoading(false);
      });
  };
}
