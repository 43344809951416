import styled, { StyledComponentBase } from 'styled-components';
import { Checkbox } from '@zendeskgarden/react-forms';
import { Modal, Body } from '@zendeskgarden/react-modals';

import { ReactComponent as UserIcon } from '../../../../assets/images/agroid/user.svg';
import { AvatarWrapper } from '../UserCard/styled';

interface IUserToRoleRowWrapper extends StyledComponentBase<any, {}> {
  Details?: any;
  CheckboxWrapper?: any;
  Checkbox?: any;
  Icon?: any;
  Avatar?: any;
}

export const ModalStyled = styled(Modal)`
  backdrop-filter: blur(10px);
  height: 70vh;
  left: auto;

  @media (max-width: 767px) {
    height: calc(100vh - 24px);
  }
`;

export const UserToRoleContainer = styled.div`
  cursor: pointer;
`;

export const UserToRoleWrapper = styled.div`
  flex-grow: 1;
`;

export const UserToRoleRowWrapper: IUserToRoleRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 16px;
  margin-bottom: 16px;

  &:hover > div {
    color: #000;
  }
`;

UserToRoleRowWrapper.Avatar = styled(AvatarWrapper)`
  display: inline-flex;
  flex-grow: 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
`;

UserToRoleRowWrapper.Details = styled.div`
  flex-grow: 1;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme: { colors: c } }) => c.generalBlack};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 16px;
`;

UserToRoleRowWrapper.CheckboxWrapper = styled.div`
  width: 16px;
  height: 20px;
`;

UserToRoleRowWrapper.Checkbox = styled(Checkbox)`
  /* outline: 2px solid red; */
`;

UserToRoleRowWrapper.Icon = styled(UserIcon)`
  width: 12px;
  height: 12px;
  path {
    fill: ${({ theme: { colors: c } }) => c.secondaryDarkGray};
  }
`;

export const ModalBodyStyled = styled(Body)`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
`;
