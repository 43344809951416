import React, { HTMLProps, Ref } from 'react';
import { Button, IButtonProps } from '@zendeskgarden/react-buttons';
import { Override, useForm } from 'uniforms';

export type SubmitFieldProps = Override<
  HTMLProps<HTMLInputElement>,
  {
    inputRef?: Ref<HTMLInputElement>;
    value?: string;
    size?: string;
    // isLarge?: boolean;
  }
>;

export default function SubmitField({
  disabled,
  inputRef,
  value,
  children,
  isBasic,
  isDanger,
  isLink,
  isNeutral,
  isPill,
  isPrimary,
  isStretched,
  size,
}: SubmitFieldProps & IButtonProps) {
  const { error, state } = useForm();

  // return (
  //   <input
  //     disabled={disabled === undefined ? Boolean(error || state.disabled) : disabled}
  //     readOnly={readOnly}
  //     ref={inputRef}
  //     type="submit"
  //     {...(value ? { value } : {})}
  //     {...filterDOMProps(props)}
  //   />
  return (
    <Button
      disabled={disabled === undefined ? Boolean(error || state.disabled) : disabled}
      ref={inputRef}
      type="submit"
      isBasic={isBasic}
      isDanger={isDanger}
      isLink={isLink}
      isNeutral={isNeutral}
      isPill={isPill}
      isPrimary={isPrimary}
      isStretched={isStretched}
      size={size}
    >
      {children || value}
    </Button>
  );
}
