import SimpleSchema from 'simpl-schema';
import { has } from 'lodash';

const getRegExpMessage = (name: string, label: string): string => {
  const regExpMessageMapping = {
    orgName: `${label} может содержать только цифры, дефисы, пробелы, точки, запятые, символы русского и английского алфавитов`,
    orgPhone: `${label} может содержать только цифры, дефисы, круглые скобки, пробелы, точки, плюс, символы русского и английского алфавитов`,
    OKVED: `${label} может содержать только цифры и точки`,
    OGRN: `${label} может содержать только цифры`,
    OKPO: `${label} может содержать только цифры`,
    KPP: `${label} может содержать только цифры`,
    INN: `${label} может содержать только цифры`,
    email: `${label} введен неверно`,
    newOwner: `${label} введен неверно`,
    phone: `${label} введен неверно`,
  };

  return has(regExpMessageMapping, name)
    ? regExpMessageMapping[name]
    : `${label} может содержать только цифры, символы русского и английского алфавитов`;
};

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      required: '{{{label || name}}} обязательно для заполнения',
      minString: '{{{label}}} не может быть меньше {{min}} символов',
      maxString: '{{{label}}} не может быть больше {{max}} символов',
      onlyNumbers: '{{{label}}} может содержать только цифры',
      lengthRequired: '{{{label}}} не верной длины',
      lengthINN: '{{{label}}} должен содержать 10 символов',
      lengthINN_IP: '{{{label}}} ИП должен содержать 12 символов',
      lengthOGRN: '{{{label}}} должен содержать 13 символов',
      lengthOGRN_IP: '{{{label}}} ИП должен содержать 15 символов',
      lengthOKPO: '{{{label}}} должен содержать 8 символов',
      lengthOKPO_IP: '{{{label}}} ИП должен содержать 10 символов',
      regEx({ name, label }) {
        return getRegExpMessage(name, label);
      },
    },
  },
});
