import { TypeApiRoute } from '../models/type.api.request';

type TypeRequest = {
  token: string;
};

type TypeResponse = {
  name: string;
  addressFact: { index: string; addressfull: string };
  addressJur: { index: string; addressfull: string };
  organizationId: string;
  employeeRole: string;
  externalKey: string;
  email: string;
  INN: string;
  type: { orgTypeId: number; OrgType: string };
  status: string;
  userId: string;
  logo?: { downloadUrl: string };
  invitationId?: string;
};

export const getOrganizationExternal: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/organizations/external/${request.token}`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
