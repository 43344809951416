import { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import { isEmpty, trim } from 'lodash';
import { Row, Col } from '@zendeskgarden/react-grid';

import { getLink } from '../../../../../../utils';
import {
  ContractorType,
  ContractorLogo,
  ContractorManager,
} from '../../../../../../api/models/contractor.model';
import EAgroidRoute from '../../../../routes/AgroidRoute.enum';
import { OrgLogo } from '../../../../components/OrgLogo';

import { AvatarWrapper, CardStyled } from './styled';

export interface ContractorCardProps extends HTMLAttributes<HTMLDivElement> {
  organizationId: string;
  name: string;
  contact: string;
  manager: ContractorManager;
  representativeFullName: string;
  region: string;
  logo: ContractorLogo;
  type?: ContractorType;
  dataTestId?: string;
  onClick: () => void;
}

const getInitials = name => {
  if (!name) return '';
  return name
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .substr(0, 1);
};

const getOrgType = type => {
  if (!type || isEmpty(type.OrgType)) {
    return '';
  }

  return type.OrgType;
};

export const ContractorCard: FC<ContractorCardProps> = props => {
  const { organizationId, name, manager, contact, type, logo, dataTestId, onClick } = props;

  const orgType = getOrgType(type);
  const fullname = trim(`${orgType} ${name}`);

  return (
    <CardStyled.Link
      to={getLink(EAgroidRoute.ScoutingContractorRequisites, {
        [organizationId]: ':contractorId',
      })}
      onClick={onClick}
      data-test-id={dataTestId && `${dataTestId}-card-item`}
    >
      <CardStyled key={organizationId}>
        <Row>
          <Col size={isMobile ? 2 : 2}>
            <AvatarWrapper data-test-id={dataTestId && `${dataTestId}-card-avatar-container`}>
              {logo && logo.downloadUrl ? (
                <OrgLogo {...logo} dataTestId={dataTestId && `${dataTestId}-card-avatar`} />
              ) : (
                getInitials(name)
              )}
            </AvatarWrapper>
          </Col>
          <Col size={isMobile ? 10 : 10}>
            <CardStyled.Title tag="h4" data-test-id={dataTestId && `${dataTestId}-card-title`}>
              {fullname}
            </CardStyled.Title>

            <CardStyled.Text data-test-id={dataTestId && `${dataTestId}-card-contact`}>
              <span>Менеджер: {contact || 'Нет данных'}</span>
            </CardStyled.Text>

            <CardStyled.Text data-test-id={dataTestId && `${dataTestId}-card-manager`}>
              <span>Ответственный: {!isEmpty(manager) ? manager.fullName : 'Нет данных'}</span>
            </CardStyled.Text>
          </Col>
        </Row>
      </CardStyled>
    </CardStyled.Link>
  );
};
