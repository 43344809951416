import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { isMobile } from 'react-device-detect';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { throttle } from 'lodash';

import { useStore } from '../../../../../../../../utils';
import { ListingPageStore } from '../../../../stores/configs';
import { ListingPageController } from '../../../../controllers/configs/pages';
import { CardsList, WellHeader } from '../../../../../../components';
import {
  NoDataPlaceholder,
  NoDataTitle,
  StyledNoDataIcon,
  WellFilterStyled,
} from '../../../../../../pages/ScoutingPage/pages/styled';
import { WellStyled } from '../../../../../../pages/ScoutingPage/components/ContractorForm/styled';
import { ListingPageFilterContainer } from '../ListingPageFilterContainer';
import searchIconSvg from '../../../../../../../../assets/images/agroid/search.svg';
import { createParamsAsPayload } from '../../../../utils/helpers/configs/pages/createParamsAsPayload';
import { createNoSearchedDataDynamicText } from '../../../../utils/helpers/configs/pages/createNoSearchedDataDynamicText';
import { useCommonState } from '../../../../hooks/useCommonState';
import { CheckAccessStore, OrganizationStore } from '../../../../../../stores';
import { ProfileStore } from '../../../../../../../auth/stores/profile.store';
import { CheckAccessService } from '../../../../../../services/checkAccess.service';

import {
  TListingPageContainerProps,
  TListingPageContainerState,
  TListingPageParams,
} from './ListingPageContainer.types';

const ListingPageContainer: FC<TListingPageContainerProps> = observer(
  ({ listingPage: { uniqueKey, handlerList, header, dataList, customData } }) => {
    const { getItemList, filter, searchValue, clearStore } = useStore(ListingPageStore);

    const { isLoading, fetchItemList, selectFilter, removeItem } = useStore(ListingPageController);

    const { isOrganizationOwner, setIsOrganizationOwner } = useStore(CheckAccessStore);
    const { getUserIsOwner } = useStore(CheckAccessService);
    const { user } = useStore(ProfileStore);

    const params = useParams<TListingPageParams>();

    const [
      { isClickedOnActionButton, isShowedCustomComponent, isNeededToUpdateData, isOwner },
      setState,
    ] = useCommonState<TListingPageContainerState>({
      isClickedOnActionButton: false,
      isShowedCustomComponent: false,
      isNeededToUpdateData: false,
      isOwner: false,
    });

    const throttledFilterRequest = useRef(throttle(() => fetchItemListWithPayload(), 700));

    useEffect(() => {
      throttledFilterRequest.current();
    }, [filter]);

    // Следит за обновлением данных
    useEffect(() => {
      if (isNeededToUpdateData) {
        throttledFilterRequest.current();

        setState({ isNeededToUpdateData: false });
      }
    }, [isNeededToUpdateData]);

    useEffect(() => {
      if (user) {
        const userIsOwner = async () => {
          const response = await getUserIsOwner({
            organizationId: params.contractorId || params.orgId,
            userId: user.id,
          });

          setIsOrganizationOwner(response);
          setState({ isOwner: response });
        };
        userIsOwner();
      }
    }, [user, params]);

    useEffect(() => {
      return () => {
        clearStore();
      };
    }, []);

    // Текст, что появляется, когда не было ничего найдено
    const noDataText: string = useMemo(() => {
      if (!searchValue) {
        return dataList.noDataText;
      }

      const noSearchedDataText = header?.filter?.filterBy?.searchQuery?.noSearchedDataText;

      const searchQueryValue = filter?.[header?.filter?.filterBy?.searchQuery?.key];

      return createNoSearchedDataDynamicText({
        noSearchedDataText,
        searchQueryValue,
      });
    }, [header, filter, searchValue]);

    // Массив кнопок-действий
    const actionList = useMemo(() => {
      if (!header?.actionButtons?.length) {
        return [];
      }

      return header.actionButtons.map(actionItem => {
        if (actionItem.type === 'button') {
          return {
            ...actionItem,
            actionName: isOwner || isOrganizationOwner ? null : actionItem.actionName,
            component: isMobile ? IconButton : Button,
            componentProps: {
              ...actionItem.componentProps,
              onClick: (event: MouseEvent): void => {
                handleActionButtonClick(event);
              },
            },
          };
        }

        return actionItem;
      });
    }, [header?.actionButtons, isOwner, user]);

    // Список элементов с обработчиком клика
    const itemListWithOnClick = useMemo(() => {
      if (dataList.item?.onClick) {
        return getItemList(uniqueKey).map(item => ({
          ...item,
          onClick: (event: MouseEvent): void => {
            event.preventDefault();

            if (dataList.item?.isNeededCustomComponent) {
              setState({ isShowedCustomComponent: true });
            }

            dataList.item?.onClick?.(item);
          },
        }));
      }

      return getItemList(uniqueKey);
    }, [getItemList(uniqueKey), dataList.item?.onClick, dataList.item?.isNeededCustomComponent]);

    const fetchItemListWithPayload = useCallback(async (): Promise<void> => {
      await fetchItemList({
        uniqueKey,
        apiMethodName: handlerList.fetchItemList.apiName,
        payload: {
          ...handlerList.fetchItemList?.rawParams,
          ...createParamsAsPayload({
            apiParams: handlerList.fetchItemList?.paramsAsPayload,
            params,
          }),
        },
      });
    }, [params]);

    const handleFilterChange = useCallback(
      (filterData): void => {
        selectFilter({ filter: filterData, searchKey: header?.filter?.filterBy?.searchQuery?.key });
      },
      [header?.filter?.filterBy?.searchQuery?.key]
    );

    const handleActionButtonClick = useCallback((event: MouseEvent): void => {
      event.preventDefault();

      console.log('CLICKED!!!');

      setState({ isClickedOnActionButton: true, isShowedCustomComponent: true });
    }, []);

    const handleHidingCustomComponent = useCallback((): void => {
      setState({ isClickedOnActionButton: false, isShowedCustomComponent: false });
    }, []);

    const handleUpdateData = useCallback((): void => {
      setState({ isNeededToUpdateData: true });
    }, []);

    const handleRemoveItem = useCallback(async (itemId: string) => {
      if (!dataList.itemsWrapper?.removeItemHandler) return false;

      try {
        await removeItem(
          dataList.itemsWrapper?.removeItemHandler.paramAsPayload,
          itemId,
          dataList.itemsWrapper?.removeItemHandler.apiName
        );
        setState({ isNeededToUpdateData: true });
      } catch (e) {
        console.error(e);
      }
    }, []);

    const renderContent = () => {
      if (isLoading) {
        return (
          <Well>
            <Skeleton height="12px" />
            <Skeleton height="12px" />
          </Well>
        );
      }

      if (getItemList(uniqueKey).length) {
        return (
          <CardsList
            list={itemListWithOnClick}
            card={dataList.item.Component}
            cardProps={{
              padding: 'ssm',
            }}
            itemProps={{
              handleRemoveItem: dataList.itemsWrapper?.removeItemHandler
                ? handleRemoveItem
                : undefined,
            }}
            dataTestId={uniqueKey}
          />
        );
      }

      return (
        <NoDataPlaceholder className="with-filter">
          <StyledNoDataIcon
            src={dataList?.noDataIconUrl || searchIconSvg}
            $size={dataList?.noDataIconUrl ? 'big' : 'normal'}
          />
          <NoDataTitle>{noDataText}</NoDataTitle>
        </NoDataPlaceholder>
      );
    };

    return (
      <>
        <WellStyled>
          <WellHeader title={header.title} actions={actionList} dataTestId={uniqueKey} />
          {header?.filter ? (
            <WellFilterStyled className="listing-filter">
              <ListingPageFilterContainer onChange={handleFilterChange} filter={header?.filter} />
            </WellFilterStyled>
          ) : null}
        </WellStyled>

        {renderContent()}

        {isShowedCustomComponent && customData?.CustomComponent ? (
          <customData.CustomComponent
            isClickedOnActionButton={isClickedOnActionButton}
            handleUpdateData={handleUpdateData}
            handleHidingCustomComponent={handleHidingCustomComponent}
          />
        ) : null}
      </>
    );
  }
);

ListingPageContainer.displayName = 'ListingPageContainer';

export default ListingPageContainer;
